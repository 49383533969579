import { Preset } from '@nike/nike-one-video-player';
import { CARD_TYPES, DEFAULT_TEXT_LOCATION } from '@nike/ciclp-redux-app';
import constants from '../../../constants';

/**
 * Merges actionButtons array with play button
 * @param {array} actionButtons - An array of action buttons
 * @param {object} playButton - play button props
 * @return {array}
 */
export const mergeButtons = (actionButtons = [], playButton = {}) =>
  playButton?.actionType ? [...actionButtons, playButton] : actionButtons;

/**
 * Check if video contains audioTrack
 * @param {object} videoElement - A video element instance
 * @return {boolean}
 */
export const isVideoWithSound = videoElement =>
  videoElement.mozHasAudio ||
  !!videoElement.webkitAudioDecodedByteCount ||
  videoElement?.audioTracks?.length > 0;

/**
 * Returns the video preset based on it params
 * @param {object} player - A video player instance
 * @return {string}
 */
export const getVideoPreset = player => {
  const hasSound = isVideoWithSound(player.tech().el());
  const playerOptions = player.options();

  switch (true) {
    // autoplay video with sound, without control options and without overlay
    case hasSound &&
      player.autoplay() &&
      !playerOptions.customProperties.isCardOverlayExist &&
      !playerOptions.customProperties.controlOptionsFromCMS:
      return Preset.audioOnlyControls;
    // autoplay video without sound or without control options from cms
    case player.autoplay() &&
      (!hasSound || !playerOptions.customProperties.controlOptionsFromCMS):
      return Preset.noControls;

    default:
      return Preset.fullControls;
  }
};

/**
 * Adds leading zero if num < 10
 * @param {object} num - seconds/minutes count
 * @return {string}
 */
export const addLeadingZero = num => (num < 10 ? `0${num}` : `${num}`);

/**
 * Transforms time from seconds to minutes:seconds format
 * @param {number} duration - duration in seconds
 * @return {string}
 */
export const formatTime = (duration = 0) => {
  if (!Number.isFinite(duration)) {
    return '';
  }
  const totalSec = +duration.toFixed();
  const sec = totalSec % 60;
  const min = (totalSec - sec) / 60;

  return [min, addLeadingZero(sec)].join(':');
};

/**
 * Checks if we need to show overlay for video
 * @param {object} videoState - current state of the video
 * @param {boolean} autoPlay - is video is autoplay
 * @param {boolean} isCardOverlayExist - do we have card overlay
 * @return {boolean}
 */
export const shouldShowOverlay = ({ videoState, isCardOverlayExist }) => {
  const isFullControlsPreset = videoState.preset === Preset.fullControls;
  const isNotStartedByUser = !videoState.isInitiatedByUser;
  const hasSomeContentOverVideoComponent =
    isFullControlsPreset || isCardOverlayExist;

  const isNotStartedVideo =
    hasSomeContentOverVideoComponent && isNotStartedByUser;

  return videoState.hasEnded || videoState.previewStarted || isNotStartedVideo;
};
/**
 * Detects if we need to show play button
 * @param {object} videoState - videoState object
 * @param {object} videoParams - initial parameters object
 * @return {boolean}
 */
export const shouldShowPlayButton = (videoState, videoParams) => {
  const noAssignedPreset = !videoState.preset && !videoParams.autoplay;

  return (
    noAssignedPreset ||
    (videoState.preset === Preset.fullControls &&
      !videoState.isInitiatedByUser) ||
    !videoParams.autoplay
  );
};

/**
 * Helps us to extract the player from event
 * @param {function} successCb - a callback to run if we have player
 * @param {function} failCb -  a callback to run if we don't have player
 * @return {function}
 */
export const runWithPlayer = (successCb, failCb) => e => {
  const { target: { player } = {} } = e;

  if (player) {
    successCb(player);
  } else {
    failCb(e);
  }
};

export const getPlayerInstance = target => {
  return (
    target
      ?.closest(constants.ANALYTICS_PLACEMENT_SELECTOR)
      ?.querySelector('[data-vjs-player]')?.player ?? null
  );
};

export const isElementVisible = el => {
  let nextEl = el;
  while (nextEl) {
    if (window.getComputedStyle(nextEl).display === 'none') {
      return false;
    }
    nextEl = nextEl.parentElement;
  }
  return true;
};

export const getTextLocation = props => {
  if (props?.containerType !== CARD_TYPES.VIDEO) {
    return props?.textLocation;
  }
  const hasWatchButton =
    props.controlOptions === constants.FULL_CONTROL_OPTIONS || !props.autoPlay;
  const shouldNotChangeLocation =
    hasWatchButton && props?.textLocation?.vertical !== 'after';
  return shouldNotChangeLocation ? DEFAULT_TEXT_LOCATION : props?.textLocation;
};

export const canStartVideo = target => {
  return (
    target?.dataset.buttonType === 'play-button' ||
    !['button', 'a'].includes(target?.tagName.toLowerCase()) ||
    target?.role === 'button'
  );
};
/**
 * Sets some defaults for video in filmstrip
 * @param {object} slide - filmstrip slide object
 * @return {object}
 */
export const getFilmstripVideoSlideProps = ({
  videoId,
  portraitVideoId,
  controlOptions,
  locale,
  imageHeight,
  assetsAspectRatios,
  portraitPosterUrl,
  landscapePosterUrl,
  watchCtaButtonText,
}) => ({
  assetsAspectRatios,
  autoPlay: false,
  controlOptions,
  forceFullscreenOnStart: true,
  imageHeight,
  landscapePosterUrl,
  locale,
  loop: false,
  portraitPosterUrl,
  portraitVideoId,
  previewOnHover: false,
  videoId,
  watchCtaButtonText,
});
