import React from 'react';
import cx from 'clsx';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Skeleton } from '@nike/nike-design-system-components';
import styles from './productContainerPlaceholder.styl';

const ProductContainerPlaceholder = ({ className }) => (
  <div className={cx(styles.blankFeedContainer, className)}>
    <Skeleton width="70%" height="28px" />
    <div className={styles.blankFeedList}>
      <Skeleton className={styles.rectShape} />
      <Skeleton className={styles.rectShape} />
      <Skeleton className={styles.rectShape} />
      <Skeleton className={styles.rectShape} />
    </div>
  </div>
);

ProductContainerPlaceholder.propTypes = {
  className: PropTypes.string,
};

export const PlaceholderWithAspectRatio = styled(ProductContainerPlaceholder)(
  ({ slideAspectRatio }) => `
  .${styles.rectShape} {
    height: auto !important;
    width: calc(((100% - 5px) / 3)) !important;
    &::before {
      content: '';
      display: block;
      padding-top: ${slideAspectRatio ** -1 * 100}%;
    }
  }
`,
);

export default ProductContainerPlaceholder;
