import React from 'react';
import PropTypes from 'prop-types';

const NavigationLink = ({ href, label, target, children, ...props }) => (
  <a
    href={href}
    target={target}
    {...(target === '_blank' && { rel: 'noopener' })}
    {...props}
  >
    {children || label}
  </a>
);

NavigationLink.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
};

export default NavigationLink;
