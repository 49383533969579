import { Preset } from '@nike/nike-one-video-player';
import { VIDEO_EVENTS, VIDEO_JS_UTILITY_CLASSES } from './constants';
import { runWithPlayer, getVideoPreset } from './helpers';

export const resetPlayer = player => {
  player.currentTime(0);
  player.controls(false);
  player.setState({
    hasEnded: false,
    isInitiatedByUser: false,
  });
};

export const PRESET_ACTION_MAPPING = {
  [Preset.noControls]: player => {
    player.controlBar.dispose();
  },
  [Preset.audioOnlyControls]: player => {
    // disable some controls
    player.controlBar.fullscreenToggle.hide();
    player.controlBar.remainingTimeDisplay.hide();
    player.controlBar.progressControl.hide();
    player.controlBar.playToggle.hide();
  },
};

const setPreset = player => {
  const activePreset = getVideoPreset(player);

  player.removeClass(Preset.fullControls);
  player.addClass(activePreset);
  player.setState({ preset: activePreset });
};

export const REACT_ADAPTER_CALLBACKS = {
  // sets a correct preset according to video parameters during loadedmetadata event because Video loadeddata event does not work in IOS Safari
  [VIDEO_EVENTS.LOADEDMETADATA]: runWithPlayer(player => {
    setPreset(player);
  }),
  // sets a correct preset according to video parameters
  [VIDEO_EVENTS.LOADEDDATA]: runWithPlayer(player => {
    setPreset(player);
    PRESET_ACTION_MAPPING[player.state.preset]?.(player);
  }),
  // sets a class if video has been started by user
  [VIDEO_EVENTS.STATECHANGED]: runWithPlayer(player => {
    const classAction = player.state.isInitiatedByUser
      ? player.addClass
      : player.removeClass;

    classAction.call(player, VIDEO_JS_UTILITY_CLASSES.INITIATED_BY_USER);
  }),
  // remove inactive preview class
  [VIDEO_EVENTS.PREVIEW_STOPPED]: runWithPlayer(player => {
    if (player.paused()) {
      player.removeClass(VIDEO_JS_UTILITY_CLASSES.POSTER_INACTIVE);
    }
  }),

  // reset video if forceFullscreenOnStart === true
  [VIDEO_EVENTS.FULLSCREENCHANGE]: runWithPlayer(player => {
    if (
      !player.isFullscreen() &&
      player.options().customProperties.forceFullscreenOnStart
    ) {
      player.pause();
      resetPlayer(player);
      player.removeClass(VIDEO_JS_UTILITY_CLASSES.POSTER_INACTIVE);
    }
  }),
  // handles video end
  [VIDEO_EVENTS.ENDED]: runWithPlayer(player => {
    if (player.isFullscreen()) {
      player.exitFullscreen();
    }
    if (player.state.isInitiatedByUser) {
      resetPlayer(player);
    }
    player.removeClass(VIDEO_JS_UTILITY_CLASSES.POSTER_INACTIVE);
    if (!player.autoplay() && player.preview()) {
      player.preview().enable();
    }
    player.setState({
      hasEnded: true,
    });
  }),
  [VIDEO_EVENTS.PLAY]: runWithPlayer(player => {
    player.addClass(VIDEO_JS_UTILITY_CLASSES.POSTER_INACTIVE);
    player.setState({
      hasEnded: false,
    });
  }),
};
