import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { selectors, CARD_TYPES } from '@nike/ciclp-redux-app';
import { getHowToData } from './seoData/getHowToData';
import { getScriptContent } from './helpers/structuredDataHelpers';
import { getSiteData } from './seoData/getSiteData';
import { organizationData, webSiteData } from './seoData/getSeoUS';

export const DynamicStructuredSeoData = () => {
  const isHowToPage = useSelector(selectors.isHowToPage);
  const isHomepage = useSelector(selectors.isHomepageSelector);
  const coverCard = useSelector(selectors.coverCardSelector);
  const listicleCard = useSelector(
    selectors.listicleCardSelector,
    shallowEqual,
  );
  const basename = useSelector(selectors.basenameSelector);
  const countryCode = useSelector(selectors.countryCodeSelector);
  const countryName = useSelector(selectors.countryNameSelector);
  const toolsAndSupplyCard = useSelector(
    state =>
      selectors.containerTypeCardSelector(state, {
        containerType: CARD_TYPES.TOOLS_AND_SUPPLIES,
      }),
    shallowEqual,
  );
  const metaBoxCard = useSelector(
    state =>
      selectors.containerTypeCardSelector(state, {
        containerType: CARD_TYPES.META_BOX,
      }),
    shallowEqual,
  );
  const isUS = countryCode === 'us';

  const structuredData = [
    {
      data:
        isHowToPage &&
        getHowToData(coverCard, metaBoxCard, toolsAndSupplyCard, listicleCard),
      type: 'HowTo',
    },
    {
      data: isHomepage && getSiteData({ basename, countryCode, countryName }),
      type: 'WebPage',
    },
    ...(isUS && isHomepage
      ? [
          {
            data: organizationData,
            type: 'Organization',
          },
          { data: webSiteData, type: 'WebSite' },
        ]
      : []),
  ];

  const seoScripts = structuredData
    .map(getScriptContent)
    .filter(Boolean)
    .join('');

  return <div dangerouslySetInnerHTML={{ __html: seoScripts }} />;
};
