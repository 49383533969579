import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { LocalNavDropdown } from './LocalNavDropdown';
import { hexToCssRgba } from './utils/color';

const arrowImprovementAnimation = `
  {
    11% {
      max-width: 10px;
    }
    11.5% {
      max-width: 9px;
    }
    61.5% {
      max-width: 9px;
    }
    62% {
      max-width: 10px;
    }
  }`;

const baseStyles = props => {
  const {
    theme: {
      colorBackgroundAlwaysLight = '#fff',
      colorBackgroundAlwaysDark = '#111',
    },
    alignment,
    items,
    itemsHeight = 48,
  } = props;

  return css`
    outline: none;

    a {
      text-decoration: none;
    }

    .local-nav-dropdown-icon {
      width: 16px;
      height: 14px;
      position: relative;

      &:after,
      &:before {
        content: '';
        width: 10px;
        height: 2px;
        background: rgb(0, 0, 0);
        display: block;
        position: absolute;
        top: 50%;
        transition: transform 300ms cubic-bezier(0.25, 0.1, 0.25, 1);
        animation: none;
      }

      &:before {
        border-top-left-radius: 1px;
        border-bottom-left-radius: 1px;
        left: 0px;
        transform: rotate(45deg);
      }

      &:after {
        border-top-right-radius: 1px;
        border-bottom-right-radius: 1px;
        right: 0px;
        transform: rotate(-45deg);
      }
    }

    @keyframes arrow-width-down ${arrowImprovementAnimation}

    @keyframes arrow-width-up ${arrowImprovementAnimation}

    &.local-nav-dropdown-opened .local-nav-dropdown-icon {
      &:before {
        transform: rotate(-45deg);
        animation: arrow-width-down 300ms cubic-bezier(0.25, 0.1, 0.25, 1);
      }

      &:after {
        transform: rotate(45deg);
        animation: arrow-width-down 300ms cubic-bezier(0.25, 0.1, 0.25, 1);
      }
    }

    &.local-nav-dropdown-hidden .local-nav-dropdown-icon {
      &:before {
        animation: arrow-width-up 300ms cubic-bezier(0.25, 0.1, 0.25, 1);
      }

      &:after {
        animation: arrow-width-up 300ms cubic-bezier(0.25, 0.1, 0.25, 1);
      }
    }

    .local-nav-dropdown-title {
      padding: 18px 20px;
      position: absolute;
      width: 100%;
      background: ${colorBackgroundAlwaysLight};
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      z-index: 2;
      outline: none;

      .local-nav-title {
        flex: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .local-nav-dropdown-nav {
      padding-top: 60px;
    }

    // ToDo:CICLP-3096
    .local-nav-title {
      ${alignment === 'center' ? 'padding-left: 24px;' : ''}
      text-align: ${alignment};
    }

    .local-nav-dropdown-backdrop {
      opacity: 1;
      transition: opacity 300ms 150ms;
      z-index: 1;
    }

    &:not(.local-nav-dropdown-opened) .local-nav-dropdown-backdrop,
    &.local-nav-opened-static .local-nav-dropdown-backdrop {
      background: ${hexToCssRgba(colorBackgroundAlwaysDark, 0.4)};
      height: 0;
      opacity: 0;
      transition: opacity 300ms 100ms, height 0ms 400ms;
      backdrop-filter: saturate(180%) blur(4px);
    }

    .local-nav-dropdown-item a {
      width: 100%;
      display: inline-block;
      padding: 12px 20px;
      text-align: ${alignment};
    }

    .local-nav-dropdown-list {
      max-height: ${itemsHeight * items.length + 114}px;
      height: 100vh;
      background: ${colorBackgroundAlwaysLight};
      position: absolute;
      width: 100%;
      top: 0;
      z-index: 1;
      transition: max-height 300ms cubic-bezier(0.25, 0.1, 0.25, 1);
      overflow: auto;
    }

    &.local-nav-opened-static .local-nav-dropdown-list,
    &.local-nav-opened-static.is-sticky {
      position: static;
      max-height: ${itemsHeight * items.length + 50}px;
      height: auto;
    }

    &.local-nav-opened-static.is-sticky {
      max-height: ${itemsHeight * items.length + 80}px;
    }

    &.local-nav-opened-static.is-sticky .local-nav-dropdown-title {
      position: fixed;
      width: 100%;
      top: 0;
      background: ${colorBackgroundAlwaysLight};
    }

    &:not(.local-nav-dropdown-opened) .local-nav-dropdown-list {
      transition-delay: 100ms;
      max-height: 0;
    }

    .local-nav-dropdown-item {
      opacity: 1;
      position: relative;
      z-index: 1;

      &:first-of-type {
        padding-top: 78px;
      }

      &:last-of-type {
        padding-bottom: 36px;
      }
    }

    &.local-nav-opened-static .local-nav-dropdown-item:first-of-type {
      padding-top: 32px;
    }

    &:not(.local-nav-dropdown-opened) .local-nav-dropdown-item {
      opacity: 0;
    }
  `;
};

const itemClass = ({ index = 0, length = 0 }) => css`
  transition: opacity 200ms ${50 * index + 150}ms
    cubic-bezier(0.25, 0.1, 0.25, 1);

  .sticky-container:not(.local-nav-dropdown-opened) & {
    transition-duration: 100ms;
    transition-delay: ${20 * (length - index - 1)}ms;
  }
`;

const DropdownItem = styled('li')`
  ${itemClass}
`;

const LocalNavDropdownStyledComp = props => {
  return <LocalNavDropdown {...props} DropdownItem={DropdownItem} />;
};

LocalNavDropdownStyledComp.defaultProps = {
  ...LocalNavDropdown.defaultProps,
};

// eslint-disable-next-line no-template-curly-in-string
export const LocalNavDropdownStyled = styled(LocalNavDropdownStyledComp)`
  ${baseStyles}
`;
