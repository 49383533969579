import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectors } from '@nike/ciclp-redux-app';
import Grid from '../grid';

export const GridLayout = ({ layoutData }) => (
  <React.Fragment>
    {layoutData.items.map(strItemId => (
      <Grid itemId={strItemId} key={strItemId} />
    ))}
  </React.Fragment>
);

GridLayout.propTypes = {
  layoutData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  layoutData: selectors.currentLayoutSelector(state, ownProps),
});

export default connect(mapStateToProps)(GridLayout);
