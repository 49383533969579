import styled from '@emotion/styled';

const DEFAULT_ASPECT_RATIO = 'auto';

const getAspectRatio = ({ aspectRatios, orientation }) =>
  aspectRatios ? aspectRatios[orientation] : DEFAULT_ASPECT_RATIO;

const getTopPadding = props => {
  const aspectRatio = getAspectRatio(props);

  if (aspectRatio === DEFAULT_ASPECT_RATIO) {
    return '0';
  }
  // Needs to transform from w/h format to h/w
  return `${100 / aspectRatio}%`;
};

const AspectRatioWrapper = styled.div`
  aspect-ratio: ${getAspectRatio};
  @supports not (aspect-ratio: auto) {
    &::before {
      content: '';
      width: 1px;
      margin-left: -1px;
      float: left;
      padding-top: ${getTopPadding};
    }
    &::after {
      content: '';
      display: table;
      clear: both;
    }
  }
`;

AspectRatioWrapper.displayName = 'AspectRatioWrapper';

export default AspectRatioWrapper;
