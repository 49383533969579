import { BRAND_NAMES } from '@nike/ciclp-config';

const getCountryName = ({ countryCode, countryName }) =>
  countryCode !== 'us' ? ` (${countryName})` : '';

export const getSiteData = ({ basename, countryCode, countryName }) => ({
  '@id': `https://${process.env.NEXT_PUBLIC_HOST_NAME}${basename}#webpage`,
  name: `${BRAND_NAMES.NIKE}${getCountryName({ countryCode, countryName })}`,
  url: `https://${process.env.NEXT_PUBLIC_HOST_NAME}${basename}`,
});
