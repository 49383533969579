/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef } from 'react';
import { path, equals } from 'ramda';
import styles from './styles.styl';

const getChangedProps = (prev, curr) =>
  Object.entries(curr).reduce((acc, [prop, value]) => {
    if (!equals(prev[prop], value)) {
      acc[prop] = [prev[prop], value];
    }
    return acc;
  }, {});

// Needed to avoid content changes before fade out animation is done
const ANIMATION_TIMEOUT = 100;

/*
  The idea of this HOC is to postpone properties update.
  So if we want to run animation on some property change the flow is the following:
    1. We check if properties have been changed
    2. If our observed property inside of that diff we hide our component, set new properties and show it up after some delay
    3. If we dont have our observed property updated - we just set new properties
*/

const withAnimation =
  Component =>
  ({
    isActive,
    animationDelay,
    observePropertyPath,
    animateOnce,
    ...props
  }) => {
    const prev = useRef(props);
    const timer = useRef(null);
    const [memoizedProps, setMemoizedProps] = useState(props);
    const [visibilityClass, setVisibilityClass] = useState('');

    useEffect(() => {
      const changedProps = getChangedProps(prev.current, props);
      const isSomethingChanged = Object.values(changedProps).length > 0;
      prev.current = props;

      if (isSomethingChanged) {
        const isObservedPropertyChanged = path(
          observePropertyPath,
          changedProps,
        );

        // if our hoc is active and observed property is changed - do the magic!
        if (isActive && isObservedPropertyChanged) {
          clearTimeout(timer.current);
          // if the component has styles.fadeIn class we assume that it has been animated once
          if (animateOnce && visibilityClass === styles.fadeIn) {
            setMemoizedProps(props);
            return;
          }
          // hide the component
          setVisibilityClass(styles.fadeOut);
          // set new props onse hide animation is done
          setTimeout(() => {
            setMemoizedProps(props);
          }, ANIMATION_TIMEOUT);
          // show component
          timer.current = setTimeout(() => {
            setVisibilityClass(styles.fadeIn);
          }, animationDelay);
        } else {
          setMemoizedProps(props);
        }
      }
      // disabling because we need to watch only these properties
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props, isActive]);

    return (
      <div className={visibilityClass}>
        <Component {...memoizedProps} />
      </div>
    );
  };

export default withAnimation;
