import React from 'react';
import cx from 'clsx';
import { CARD_TYPES } from '@nike/ciclp-redux-app';
import { smartTextCut } from '@nike/ciclp-redux-app/services/transforms/helpers';
import { TextStyled } from '@nike/nike-design-system-components';
import ImageMedia from '../imageMedia';
import { CardLink } from '../cardLink';
import styles from './relatedFilmstripSlide.styl';
import { gallerySlideShape } from '../../shapes';
import { DimensionTypeContext } from '../../context';
import { VideoMedia } from '../videoMedia';
import { createMarkup } from '../../helpers/text';

const MAX_BODY_LENGTH_MOBILE = 100;
const MAX_BODY_LENGTH = 160;

const getComponent = slide =>
  ({
    [CARD_TYPES.IMAGE]: (
      <ImageMedia
        {...slide}
        loadedImageClassNames={[styles.imageIsLoaded]}
        customClass={cx([styles.imageMedia, styles.imageWrapper])}
      />
    ),
    [CARD_TYPES.VIDEO]: (
      <VideoMedia
        {...slide}
        loop={false}
        autoPlay={false}
        showPlayButton
        alwaysMobile
        watchButtonText={slide.watchCtaButtonText}
        customClass={styles.imageMedia}
        timestampWithPlay
      />
    ),
  }[slide.containerType] ?? null);

export const RelatedFilmstripSlide = ({
  analyticsItemId,
  cardLinkMemberOnly,
  ...slide
}) => {
  return (
    <figure
      data-analytics-placement-id={analyticsItemId}
      data-analytics-has-landmark="true"
      data-qa="related-item-card"
      className={cx([styles.slide, styles.filmstripCard])}
    >
      {getComponent(slide)}
      <CardLink
        actionId={slide.cardLinkId}
        memberOnly={cardLinkMemberOnly}
        destinationUrl={slide.cardLinkUrl}
        openInNewTab={slide.openInNewTab}
        renderAs="a"
        classNames={[styles.cardLink]}
        ariaLabel={[slide.subtitleProps.text, slide.titleProps.text].join(' ')}
      />
      <figcaption className={styles.slideTextWrapper}>
        {slide.subtitleProps.text && (
          <TextStyled
            Component="h4"
            data-qa="title"
            appearance="body2"
            className={styles.slideTitle}
            color={
              slide.subtitleProps.isTextColorSet
                ? slide.subtitleProps.textColor
                : 'secondary'
            }
            dangerouslySetInnerHTML={createMarkup(slide.subtitleProps.text)}
          />
        )}

        <DimensionTypeContext.Consumer>
          {deviceInfo => (
            <TextStyled
              Component="h3"
              appearance="title4"
              className={styles.slideBodyText}
              color={slide.titleProps.textColor}
              dangerouslySetInnerHTML={createMarkup(
                smartTextCut(
                  slide.titleProps.text,
                  deviceInfo.isDesktop
                    ? MAX_BODY_LENGTH
                    : MAX_BODY_LENGTH_MOBILE,
                ),
              )}
            />
          )}
        </DimensionTypeContext.Consumer>
      </figcaption>
    </figure>
  );
};

RelatedFilmstripSlide.propTypes = gallerySlideShape;
