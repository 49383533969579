import React from 'react';
import PropTypes from 'prop-types';
import { PAGE_TYPES } from '@nike/ciclp-config';
import { values } from 'ramda';
import { videoCardShape } from '../../shapes';
import { VideoCardLandingPages } from './VideoCardLandingPages';
import { VideoCardEditorial } from './VideoCardEditorial';
import { getTextLocation } from '../videoMedia/helpers';

export const VideoCard = ({ pageType, imageHeight, ...props }) => {
  const textLocation = getTextLocation(props);

  const VideoComponent =
    pageType === PAGE_TYPES.ARTICLE
      ? VideoCardEditorial
      : VideoCardLandingPages;
  return <VideoComponent {...props} textLocation={textLocation} />;
};

VideoCard.propTypes = {
  ...videoCardShape,
  pageType: PropTypes.oneOf(values(PAGE_TYPES)),
};
