import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import cx from 'clsx';
import { selectors } from '@nike/ciclp-redux-app';
import styles from './errorLayout.styl';
import { RecommendationsCarousel } from '../recommendationsCarousel';

export const ErrorLayout = props => {
  const legend1 = useSelector(state =>
    selectors.translationSelector(state, 'errorPage_legend_1'),
  );
  const legend2 = useSelector(state =>
    selectors.translationSelector(state, 'errorPage_legend_2'),
  );

  return (
    <div>
      <div
        data-qa="error-page"
        className={cx('ncss-container', styles.errorLayout)}
      >
        <div className={styles.mainMessage}>
          <p data-qa="error-layout-legend-1" className={'headline-3'}>
            {props.legend1 || legend1}
          </p>
          <p data-qa="error-layout-legend-2" className={'headline-3'}>
            {props.legend2 || legend2}
          </p>
        </div>
      </div>
      {process.env.NEXT_PUBLIC_RECOMMENDATION_MFE_DISABLED !== 'true' && (
        <RecommendationsCarousel carouselId="ciclp.404" />
      )}
    </div>
  );
};

ErrorLayout.propTypes = {
  legend1: PropTypes.string,
  legend2: PropTypes.string,
};
