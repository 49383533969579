import cx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';

import styles from './banner.styl';

export const BannerSlide = ({
  title,
  msg,
  actions,
  onSlideFocus,
  onSlideBlur,
  isHidden,
}) => (
  <div
    className={styles.bannerSlide}
    onFocus={onSlideFocus}
    onBlur={onSlideBlur}
    aria-hidden={isHidden}
  >
    <span className={styles.bannerTitle}>{title}</span>
    <div
      className={styles.bannerSubtitle}
      dangerouslySetInnerHTML={{
        __html: msg?.replace(
          /<a\s/g,
          `<a class="${styles.bannerLink}" tabindex="${isHidden ? -1 : 0}" `,
        ),
      }}
    />
    {actions
      ? actions.map(action => (
          <a
            key={action.id}
            href={action.url}
            className={cx(styles.bannerLink, action.className)}
            style={action.style}
            tabIndex={isHidden ? -1 : 0}
          >
            {action.text}
          </a>
        ))
      : null}
  </div>
);

BannerSlide.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
      type: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  isHidden: PropTypes.bool,
  msg: PropTypes.string,
  onSlideBlur: PropTypes.func,
  onSlideFocus: PropTypes.func,
  title: PropTypes.string,
};
