import React from 'react';
import cx from 'clsx';
import PropTypes from 'prop-types';
import styles from './imageMedia.styl';
import { focalPointShape } from '../../shapes';

export const ImageElement = ({
  draggable,
  onLoad,
  focalPoint,
  altText,
  url,
  isImageLoaded,
  innerRef,
  className,
  portraitURL,
  landscapeURL,
}) => (
  <img
    // innerRef is needed for React Waypoint
    ref={innerRef}
    draggable={draggable}
    data-qa="image-media-img"
    alt={altText}
    className={cx(
      className,
      styles[`v-${focalPoint?.vertical ?? 'center'}`],
      styles[`h-${focalPoint?.horizontal ?? 'center'}`],
      {
        [styles.withHardCrop]: focalPoint?.hardCrop,
      },
      isImageLoaded && styles.loadedImage,
    )}
    src={url}
    data-landscape-url={landscapeURL}
    data-portrait-url={portraitURL}
    data-image-loaded-class={styles.loadedImage}
    onLoad={onLoad}
  />
);

ImageElement.propTypes = {
  altText: PropTypes.string,
  className: PropTypes.string,
  draggable: PropTypes.bool,
  focalPoint: focalPointShape,
  innerRef: PropTypes.func,
  isImageLoaded: PropTypes.bool,
  landscapeURL: PropTypes.string,
  onLoad: PropTypes.func,
  portraitURL: PropTypes.string,
  url: PropTypes.string,
};
