import React, { useContext } from 'react';
import cx from 'clsx';
import {
  TextStyled,
  useMatchMedia,
  useBreakpoint,
} from '@nike/nike-design-system-components';
import { cardStyledTextProperties } from '../../shapes';
import { BrandContext } from '../../context';
import { createMarkup } from '../../helpers/text';

import {
  shouldUppercaseText,
  isEmptyTextString,
  getTextAppearance,
  fontStyleMapper,
} from './helpers';

export const TitleText = ({
  appearance,
  text,
  fontFamily,
  fontSize,
  fontStyle,
  textColor,
  customClassName,
  renderAs,
}) => {
  const brand = useContext(BrandContext);
  const isLargeScreen = useMatchMedia(useBreakpoint(`gte`, `m`));

  if (isEmptyTextString(text)) {
    return null;
  }

  const customStyles = [customClassName, fontStyleMapper[fontStyle]];
  const textAppearance =
    appearance ??
    getTextAppearance('title', brand, fontFamily, fontSize, isLargeScreen);
  const uppercase = shouldUppercaseText(textAppearance, brand);

  return (
    <TextStyled
      data-qa="title"
      Component={renderAs}
      appearance={textAppearance}
      className={cx(customStyles)}
      weight={fontStyle}
      uppercase={uppercase}
      color={textColor}
      dangerouslySetInnerHTML={createMarkup(text)}
    />
  );
};
TitleText.displayName = 'TitleText';
TitleText.propTypes = cardStyledTextProperties;

TitleText.defaultProps = {
  colorTheme: 'dark',
  fontFamily: 'marketing',
  fontSize: 'medium',
  fontStyle: 'regular',
  renderAs: 'h3',
  text: null,
  textColor: null,
};
