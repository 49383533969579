import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import styles from './toolsAndSupplies.styl';
import { TitleText } from '../textOverlay';
import NavigationLink from '../navigationLink';

const getAnchorTarget = isOpenInNewTab => (isOpenInNewTab ? '_blank' : '_self');

const TitledList = ({ title, list = [] }) => (
  <div className={styles.listWrapper}>
    <TitleText
      customClassName={styles.listTitle}
      fontFamily="base"
      fontSize="large"
      renderAs="h4"
      text={title}
    />
    <ul className={styles.itemsList}>
      {list.map(({ url, text, openInNewTab, analyticActionId, id }) => (
        <li className={cx(styles.listItem, 'responsive-body-2-1')} key={id}>
          {url ? (
            <NavigationLink
              data-analytics-action-id={analyticActionId}
              className={styles.listLink}
              href={url}
              aria-label={`Visit ${url}`}
              target={getAnchorTarget(openInNewTab)}
            >
              {text}
            </NavigationLink>
          ) : (
            text
          )}
        </li>
      ))}
    </ul>
  </div>
);

TitledList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      analyticActionId: PropTypes.string,
      id: PropTypes.string,
      openInNewTab: PropTypes.bool,
      text: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  title: PropTypes.string,
};

const ToolsAndSupplies = ({
  tools,
  supplies,
  toolsLabel,
  suppliesLabel,
  analyticsItemId,
}) => {
  if (!supplies?.length && !tools?.length) {
    return null;
  }

  return (
    <div
      className={cx([styles.sectionWrapper])}
      data-analytics-placement-id={analyticsItemId}
      data-analytics-has-landmark="true"
    >
      {!!supplies?.length && (
        <TitledList list={supplies} title={suppliesLabel} />
      )}
      {!!tools?.length && <TitledList list={tools} title={toolsLabel} />}
    </div>
  );
};

ToolsAndSupplies.propTypes = {
  analyticsItemId: PropTypes.string,
  supplies: PropTypes.array,
  suppliesLabel: PropTypes.string,
  tools: PropTypes.array,
  toolsLabel: PropTypes.string,
};

export default ToolsAndSupplies;
