import { NCSS_VERSION } from '../../constants';

const GC_NCSS_VERSION = process.env.NCSS_VERSION;

export const networking = [
  {
    href: `https://insights-collector.newrelic.com/`,
    rel: 'dns-prefetch',
  },
  {
    href: `https://c.static-${process.env.NEXT_PUBLIC_NIKE_DOTCOM_DOMAIN}/`,
    rel: 'dns-prefetch',
  },
  {
    href: `https://cdnjs.cloudflare.com/`,
    rel: 'dns-prefetch',
  },
  {
    href: `https://secure-store.${process.env.NEXT_PUBLIC_NIKE_DOTCOM_DOMAIN}/`,
    rel: 'dns-prefetch',
  },
  {
    href: `https://web.${process.env.NEXT_PUBLIC_NIKE_DOTCOM_DOMAIN}/`,
    rel: 'dns-prefetch',
  },
  {
    href: `https://static.${process.env.NEXT_PUBLIC_NIKE_DOTCOM_DOMAIN}/`,
    rel: 'dns-prefetch',
  },
  {
    href: `https://unite.${process.env.NEXT_PUBLIC_NIKE_DOTCOM_DOMAIN}/`,
    rel: 'dns-prefetch',
  },
  {
    href: `https://api.segment.io/`,
    rel: 'dns-prefetch',
  },
  {
    href: `https://${process.env.NEXT_PUBLIC_API_HOST_NAME}`,
    rel: 'dns-prefetch',
  },
  {
    href: `https://connect.facebook.net/`,
    rel: 'dns-prefetch',
  },
  {
    href: `https://analytics.${process.env.NEXT_PUBLIC_NIKE_DOTCOM_DOMAIN}/`,
    rel: 'dns-prefetch',
  },
  {
    as: 'font',
    crossOrigin: 'crossorigin',
    href: `https://${process.env.NEXT_PUBLIC_HOST_NAME}/static/ncss/${NCSS_VERSION}/dotcom/fonts/Nike-Futura.woff2`,
    rel: 'preload',
    type: 'font/woff2',
  },
];

export const networkingGc = [
  {
    href: `https://insights-collector.newrelic.com/`,
    rel: 'dns-prefetch',
  },
  {
    href: `https://c.static-${process.env.NEXT_PUBLIC_NIKE_DOTCOM_DOMAIN}/`,
    rel: 'dns-prefetch',
  },
  {
    href: `https://g.alicdn.com/`,
    rel: 'dns-prefetch',
  },
  {
    href: `https://aeu.alicdn.com/`,
    rel: 'dns-prefetch',
  },
  {
    href: `https://secure-store.${process.env.NEXT_PUBLIC_NIKE_DOTCOM_DOMAIN}/`,
    rel: 'dns-prefetch',
  },
  {
    href: `https://web.${process.env.NEXT_PUBLIC_NIKE_DOTCOM_DOMAIN}/`,
    rel: 'dns-prefetch',
  },
  {
    href: `https://static.${process.env.NEXT_PUBLIC_NIKE_DOTCOM_DOMAIN}/`,
    rel: 'dns-prefetch',
  },
  {
    href: `https://unite.${process.env.NEXT_PUBLIC_NIKE_DOTCOM_DOMAIN}/`,
    rel: 'dns-prefetch',
  },
  // GC video metadata still comes from api.nike.com, so keep both for now. To be removed when video delivery is migrated.
  {
    href: `https://api.nike.com/`,
    rel: 'dns-prefetch',
  },
  {
    href: `https://api.nike.com.cn/`,
    rel: 'dns-prefetch',
  },
  {
    href: `https://manifest.prod.boltdns.net/`,
    rel: 'dns-prefetch',
  },
  {
    href: `https://analytics.${process.env.NEXT_PUBLIC_NIKE_DOTCOM_DOMAIN}/`,
    rel: 'dns-prefetch',
  },
  {
    as: 'font',
    crossOrigin: 'crossorigin',
    href: `https://${process.env.NEXT_PUBLIC_HOST_NAME}/static/ncss/${GC_NCSS_VERSION}/dotcom/fonts/Nike-Futura.woff2`,
    rel: 'preload',
    type: 'font/woff2',
  },
];
