import React from 'react';
import { ErrorLayout } from '../components/errorLayout';

const AuthExpiredView = () => (
  <ErrorLayout
    legend1="Authentication token has expired."
    legend2="Please generate a new preview link from IRIS."
  />
);

export default AuthExpiredView;
