import { selectors } from '@nike/ciclp-redux-app';
import { BRAND_NAMES } from '@nike/ciclp-config';
import defaultOpenGraphData from './defaultOpenGraphData';
import defaultTwitterCardData from './defaultTwitterCardData';
import { getFavicons } from './metadata-favicons';
import { networking, networkingGc } from './metadata-networking';
import { TITLE_TEMPLATE_TRANSLATION_KEYS } from '../../constants';

const {
  marketplaceSelector,
  routeNameSelector,
  languageRegionSelector,
  urlSelector,
  canonicalUrlSelector,
  translationSelector,
  pageDataPropertiesSelector,
  pageNumberSelector,
  countryCodeSelector,
} = selectors;

export const capitalizeFirstLetter = (str = '') =>
  `${str[0]?.toUpperCase()}${str?.slice(1)}`;

const COUNTRIES_WITHOUT_URL_LOCALE = ['US', 'CN'];

export const titleTemplate = (title, template, marketPlace) => {
  const shouldAddUrlLocale =
    !COUNTRIES_WITHOUT_URL_LOCALE.includes(marketPlace);
  const brandName = BRAND_NAMES.NIKE;
  const transformedBrandName =
    marketPlace === 'CN'
      ? brandName.toUpperCase()
      : capitalizeFirstLetter(brandName);
  const templateName = template
    .replace('[country]', shouldAddUrlLocale ? `${marketPlace}` : '')
    .replace('[brand]', transformedBrandName)
    .trimRight();

  return `${title}${templateName}`;
};

const MetaTags = class {
  constructor() {
    this.tags = [];
  }

  /**
   * @param {Object} name - label, value pair of meta tag
   * @param {String} value - content value of meta tag
   */
  push(name, value) {
    if (!value) {
      return;
    }

    this.tags.push({
      [name.label]: name.value,
      content: value,
    });
  }
};

const normalPageCanonical = store => ({
  link: [
    ...getFavicons(),
    ...(countryCodeSelector(store) === 'cn' ? networkingGc : networking),
    {
      href: canonicalUrlSelector(store),
      rel: 'canonical',
    },
  ],
});

const noCanonical = () => undefined;

const canonicalUrlGetters = {
  AUTH_EXPIRED: noCanonical,
  NOT_FOUND: noCanonical,
  PAGE_VIEW: normalPageCanonical,
  PREVIEW: noCanonical,
  PREVIEW_V2: noCanonical,
  SERVER_ERROR: noCanonical,
};

const openGraphDataPropertyLabelsMap = {
  description: 'og:description',
  imageUrl: 'og:image',
  locale: 'og:locale',
  siteName: 'og:site_name',
  title: 'og:title',
  type: 'og:type',
  url: 'og:url',
};

const twitterCardDataPropertyLabelsMap = {
  card: 'twitter:card',
  creator: 'twitter:creator',
  description: 'twitter:description',
  imageUrl: 'twitter:image',
  site: 'twitter:site',
  title: 'twitter:title',
};

function pushOgTagsToMetaData(
  metaDataPushFn,
  openGraphProperties,
  languageRegion,
  currentUrl,
  store,
) {
  const openGraphData = {
    ...openGraphProperties,
    // No trailing slash here as per requirement
    url: `https://${process.env.NEXT_PUBLIC_HOST_NAME}${currentUrl}`,
  };

  if (languageRegion) {
    openGraphData.locale = languageRegion;
  }

  const openGraphDataWithDefaults = {
    ...defaultOpenGraphData(store),
    ...openGraphData,
  };

  const ogMetaTagsCollection = Object.keys(openGraphDataWithDefaults).map(
    openGraphKey => ({
      content: openGraphDataWithDefaults[openGraphKey],
      name: {
        label: 'property',
        value: openGraphDataPropertyLabelsMap[openGraphKey],
      },
    }),
  );

  ogMetaTagsCollection.forEach(ogMetaTagObject => {
    metaDataPushFn(ogMetaTagObject.name, ogMetaTagObject.content);
  });
}

function pushTwitterCardToMetaData(
  store,
  metaDataPushFn,
  twitterDataProperties,
) {
  const twitterData = { ...twitterDataProperties };

  const twitterCardDataWithDefaults = {
    ...defaultTwitterCardData(store),
    ...twitterData,
  };

  const twitterTagsCollection = Object.keys(twitterCardDataWithDefaults).map(
    twitterCardKey => ({
      content: twitterCardDataWithDefaults[twitterCardKey],
      name: {
        label: 'name',
        value: twitterCardDataPropertyLabelsMap[twitterCardKey],
      },
    }),
  );

  twitterTagsCollection.forEach(ogMetaTagObject => {
    metaDataPushFn(ogMetaTagObject.name, ogMetaTagObject.content);
  });
}

export const getTitleTranslationKeyForCountry = country => {
  if (TITLE_TEMPLATE_TRANSLATION_KEYS[country]) {
    return TITLE_TEMPLATE_TRANSLATION_KEYS[country];
  }
  return TITLE_TEMPLATE_TRANSLATION_KEYS.default;
};

const metadataFromFeed = store => {
  const metaTags = new MetaTags();
  const pageData = pageDataPropertiesSelector(store);
  const languageRegion = languageRegionSelector(store);
  const currentUrl = urlSelector(store);
  const marketPlace = marketplaceSelector(store);
  const domainBasedBrandName = BRAND_NAMES.NIKE;
  const template = translationSelector(
    store,
    getTitleTranslationKeyForCountry(marketPlace),
  );
  const { keywords, description, title, doNotIndex } =
    pageData?.seoProperties || {};
  const robots = doNotIndex ? 'noindex, nofollow' : 'index, follow';
  metaTags.push({ label: 'name', value: 'keywords' }, keywords);
  metaTags.push({ label: 'name', value: 'robots' }, robots);
  metaTags.push({ label: 'name', value: 'description' }, description);
  metaTags.push(
    { label: 'httpEquiv', value: 'content-language' },
    `${languageRegion}`,
  );
  metaTags.push(
    { label: 'name', value: 'application-name' },
    `${capitalizeFirstLetter(domainBasedBrandName)}.com`,
  );

  pushOgTagsToMetaData(
    metaTags.push.bind(metaTags),
    pageData.openGraph,
    languageRegion,
    currentUrl,
    store,
  );

  pushTwitterCardToMetaData(
    store,
    metaTags.push.bind(metaTags),
    pageData.openGraph,
  );

  return {
    meta: metaTags.tags,
    title,
    titleTemplate: t => titleTemplate(t, template, marketPlace),
  };
};

const metadataGetters = {
  AUTH_EXPIRED: () => ({
    title: 'Token has expired',
  }),
  NOT_FOUND: () => ({
    title: 'Page not found',
  }),
  PAGE_VIEW: metadataFromFeed,
  PREVIEW: metadataFromFeed,
  PREVIEW_V2: metadataFromFeed,
  SERVER_ERROR: () => ({
    title: 'Internal error',
  }),
};

const getMetadata = store => {
  const routeName = routeNameSelector(store);
  const marketPlace = marketplaceSelector(store);
  const template = translationSelector(
    store,
    getTitleTranslationKeyForCountry(marketPlace),
  );
  const titlePageNumberPostfix = translationSelector(
    store,
    'title_page_number_postfix',
  );
  const pageNumber = pageNumberSelector(store);
  const pageNumberPostfix =
    pageNumber && pageNumber > 1 && titlePageNumberPostfix
      ? titlePageNumberPostfix.replace('[X]', pageNumber)
      : '';

  if (!routeName) {
    return undefined;
  }

  if (!Object.prototype.hasOwnProperty.call(metadataGetters, routeName)) {
    throw new Error(`No metadatagetter defined for route '${routeName}'`);
  }
  const pageSpecificMetadata = metadataGetters[routeName](store);
  const pageSpecificCanonical = canonicalUrlGetters[routeName](store);

  return {
    ...pageSpecificMetadata,
    ...pageSpecificCanonical,
    titleTemplate: t =>
      titleTemplate(t, `${template}${pageNumberPostfix}`, marketPlace),
  };
};

export default getMetadata;
