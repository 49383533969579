import React from 'react';
import cx from 'clsx';
import PropTypes from 'prop-types';
import CardOverlay from '../cardOverlay';
import styles from './videoCard.styl';
import { BodyText, SubtitleText, TitleText } from '../textOverlay';
import { cardStyledTextShape, textLocationShape } from '../../shapes';

const defaultSubtitleColor = '#ccc';

export const addDefaultClass = (
  textProps,
  defaultClassName,
  defaultFontStyleClassName,
) => ({
  ...textProps,
  ...(!textProps.fontSize &&
    !textProps.fontFamily && {
      customClassName: cx(
        textProps.customClassName,
        defaultClassName,
        defaultFontStyleClassName,
      ),
    }),
});

export const VideoCardContent = ({
  bodyProps,
  withH1,
  subtitleProps,
  textLocation,
  titleProps,
  playButtonElement,
}) => {
  return (
    <CardOverlay renderAs="div" position={textLocation}>
      <SubtitleText
        {...addDefaultClass(subtitleProps, styles.defaultSubtitle, 'body-3')}
        {...{
          textColor: subtitleProps.textColor ?? defaultSubtitleColor,
        }}
      />
      <TitleText
        {...{
          ...addDefaultClass(titleProps, styles.defaultTitle, 'headline-5'),
          ...(withH1 && { renderAs: 'h1' }),
        }}
      />
      <BodyText
        {...addDefaultClass(bodyProps, styles.defaultBody, 'headline-5')}
      />
      {playButtonElement}
    </CardOverlay>
  );
};

VideoCardContent.propTypes = {
  bodyProps: cardStyledTextShape,
  playButtonElement: PropTypes.node,
  subtitleProps: cardStyledTextShape,
  textLocation: textLocationShape,
  titleProps: cardStyledTextShape,
  withH1: PropTypes.bool,
};
