import { createSelector } from 'reselect';
import { identity } from 'ramda';
import { CARD_TYPES, selectors } from '@nike/ciclp-redux-app';
import { cardDataSelector } from '@nike/ciclp-redux-app/store/data/getCardDataSelector';

import constants from '../../constants';

/**
 * Generates a map object containing data for
 * all (recursive) grid child elements up to the depth
 * of 'block' entity
 * @param {Object} objData grid data entity
 * @param {Object} objAcc accumulator object instance
 * @param {Object} itemsMap map of all layoutItems
 * @returns {Object} map of gird elements data
 * @example return object structure
 * // {
 * //   [layoutItemId]: { ...layoutItemData }
 * // }
 */
export const denormaliseChildren = (objData, objAcc, itemsMap) => {
  if (Object.prototype.hasOwnProperty.call(objData, 'items')) {
    objData.items.reduce((accumulator, strItemId) => {
      accumulator[strItemId] = itemsMap[strItemId];
      accumulator = denormaliseChildren(
        accumulator[strItemId],
        accumulator,
        itemsMap,
      );
      return accumulator;
    }, objAcc);
  }
  return objAcc;
};

/**
 * Selector generating a map object containing data for
 * all (recursive) grid child elements down to the depth
 * of a 'block' entity
 */
export const gridChildrenSelector = createSelector(
  selectors.dataSelector,
  selectors.layoutItemSelector,
  (appData, gridData) => denormaliseChildren(gridData, {}, appData.layoutItems),
);

/**
 * Selector returns block entities
 */
export const gridBlocksSelector = createSelector(
  gridChildrenSelector,
  childrenData =>
    Object.values(childrenData).filter(childData => childData.mode === 'block'),
);

/**
 * Selector returns cards inside grid
 * @return {Array}
 */
export const gridCardsSelector = createSelector(
  identity,
  gridBlocksSelector,
  (state, blocks) =>
    blocks.map(block => cardDataSelector(state, { cardId: block.data })),
);

/**
 * Selector determines is a grid empty (not visible) or not
 * @return {Boolean}
 */
export const gridEmptyDataSelector = createSelector(
  gridCardsSelector,
  gridCards => {
    const container = gridCards.find(card => card?.containerType);
    // product containers are empty when we have less then 3 elements
    switch (container?.containerType) {
      case CARD_TYPES.EXTERNAL_COLLECTION:
        return !container?.slides?.length;
      case CARD_TYPES.PRODUCT_RECOMMENDER:
      case CARD_TYPES.TOP_TRENDING:
        return (
          !container.isLoading &&
          (!container?.slides?.length ||
            container?.slides?.length < constants.MIN_PRODUCT_LIST_LENGTH)
        );
      default:
        return false;
    }
  },
);

/**
 * Selector returns containerType
 */
export const gridContainerTypeSelector = createSelector(
  gridCardsSelector,
  gridCards => gridCards?.[0]?.containerType ?? 'empty',
);
