import React from 'react';
import cx from 'clsx';
import ImageMedia from '../imageMedia';
import { gallerySlideShape } from '../../shapes';
import imageHeights from '../../constants/imageHeights.styl';
import { SlideContent } from './slideContent';
import styles from './filmstripSlide.styl';

export const FilmstripImageMedia = props => (
  <>
    <ImageMedia
      {...props}
      customClass={cx([
        props.imageHeight && imageHeights[props.imageHeight],
        props.imageWrapper,
      ])}
      assetsAspectRatios={props.assetsAspectRatios}
      loadedImageClassNames={[styles.imageIsLoaded]}
    />
    <SlideContent {...props} />
  </>
);

FilmstripImageMedia.propTypes = gallerySlideShape;
