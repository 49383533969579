import React from 'react';
import PropTypes from 'prop-types';
import styles from './rainbowText.styl';

export const RainbowText = ({ children }) => (
  <span className={styles.rainbowText}>{children}</span>
);

RainbowText.propTypes = {
  children: PropTypes.node,
};
