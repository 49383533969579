import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { equals } from 'ramda';
import { gridBlocksSelector } from '../gridSelectors';
import { Card } from '../../card';
import styles from './fourUp.styl';

export const renderBlock = block => (
  <div className={styles.card}>
    <Card
      analyticsItemId={block.id}
      cardId={block.data}
      withH1={block.withH1}
      key={block.id}
    />
  </div>
);

/**
 * Component to display four cards in specific manner
 * For mobile it should look this way:
 * -----
 * | 1 |
 * -----
 * | 2 |
 * -----
 * | 3 |
 * -----
 * | 4 |
 * -----
 *
 * For tablet+ it looks this way:
 * ---------
 * | 1 | 2 |
 * |   |---|
 * |---| 4 |
 * | 3 |   |
 * ---------
 *
 * Because of CMS implementation details we get blocks from gridBlocksSelector
 * in a bit different order: 1 3 2 4 (separated by columns), so we need to do a reshuffle
 */
export const FourUp = ({ blocks }) => (
  <div className={styles.fourUp}>
    {blocks[0] && renderBlock(blocks[0])}
    {blocks[2] && renderBlock(blocks[2])}
    {blocks[1] && renderBlock(blocks[1])}
    {blocks[3] && renderBlock(blocks[3])}
  </div>
);

FourUp.propTypes = {
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      data: PropTypes.string,
      id: PropTypes.string,
    }),
  ).isRequired,
};

const mapStateToProps = (state, props) => ({
  blocks: gridBlocksSelector(state, { itemId: props.parentId }),
});

export default connect(mapStateToProps, null, null, { areStatesEqual: equals })(
  FourUp,
);
