import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

const SEOMetaTags = ({ metaTags }) => (
  <Head>
    {
      // meta, link and title need to be a direct child for next/head element
    }
    {metaTags?.meta?.length &&
      metaTags.meta.map(metaTag => {
        const tagId = metaTag.name || metaTag.property || metaTag.httpEquiv;
        return <meta {...metaTag} key={`meta-tag-${tagId}`} />;
      })}
    {metaTags?.link?.length &&
      metaTags.link.map(linkTag => (
        <link {...linkTag} key={`link-tag-${linkTag.href}`} />
      ))}
    <title>
      {typeof metaTags?.titleTemplate === 'function'
        ? metaTags.titleTemplate(metaTags?.title || '')
        : metaTags?.title || ''}
    </title>
  </Head>
);

SEOMetaTags.propTypes = {
  metaTags: PropTypes.shape({
    link: PropTypes.arrayOf(PropTypes.object), // array of <link/> tags
    meta: PropTypes.arrayOf(PropTypes.object), // array of <meta/> tags
    title: PropTypes.string,
    titleTemplate: PropTypes.func,
  }),
};

export default SEOMetaTags;
