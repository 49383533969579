import { useContext, useEffect, useRef, useState } from 'react';
import optimizely from '@optimizely/optimizely-sdk';
import { selectors } from '@nike/ciclp-redux-app';

import { useSelector } from 'react-redux';
import { OptimizelyContext } from './optimizely';

const isPartOfExperimentDefault = ({ target } = {}) =>
  Boolean(target?.matches('a') || target?.closest('a'));

export const useOptimizelyExperiment = ({
  experimentName,
  fallbackVariation,
  attributes,
  isPartOfExperiment = isPartOfExperimentDefault,
}) => {
  const isDisabled = useSelector(selectors.disableUiExperimentsSelector);
  const context = useContext(OptimizelyContext);

  useEffect(() => {
    context?.activateExperiment?.({
      attributes,
      experimentName,
      fallbackVariation,
    });
    // we don't expect any changes in here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const trackOptimizelyEvent = e => {
    if (isPartOfExperiment(e)) {
      context.trackEvent?.(experimentName, attributes);
    }
  };

  const activeVariation = isDisabled
    ? fallbackVariation
    : context.activeExperiments?.[experimentName]?.activeVariation;

  return {
    activeVariation,
    trackOptimizelyEvent,
  };
};
export const useExperimentQueue = () => {
  const experimentsToActivateRef = useRef({});
  const addExperimentToQueue = experiment => {
    experimentsToActivateRef.current[experiment.experimentName] = experiment;
  };
  const removeExperimentFromQueue = experimentName => {
    delete experimentsToActivateRef.current[experimentName];
  };
  const getExperimentsToActivate = () => {
    return Object.values(experimentsToActivateRef.current);
  };
  return {
    addExperimentToQueue,
    getExperimentsToActivate,
    removeExperimentFromQueue,
  };
};

export const useOptimizelyInstance = datafile => {
  const optimizelyInstanceRef = useRef(null);
  const [isOptimizelyReady, setOptimizelyReady] = useState(false);

  useEffect(() => {
    const instance = optimizely.createInstance({
      datafile,
    });
    instance.onReady().then(() => {
      setOptimizelyReady(true);
    });

    optimizelyInstanceRef.current = instance;
  }, [datafile]);

  return {
    isOptimizelyReady,
    optimizelyInstanceRef,
  };
};
