import React from 'react';
import PropTypes from 'prop-types';
import { RemoteComponent } from '@nike/remote-component';
import { NIKE_BASE_URL } from '@nike/ciclp-config';
import { useSelector } from 'react-redux';
import { analyticsViewProperties, selectors } from '@nike/ciclp-redux-app';

export const RecommendationsCarousel = ({
  carouselId,
  title,
  taxonomies,
  clearance,
  maxResults,
}) => {
  const pageType = useSelector(selectors.analyticsPageTypeSelector);
  const pageName = useSelector(selectors.pageNameSelector);
  const pageDetail = useSelector(selectors.pageDetailSelector);

  const analyticsConfig = {
    experienceType: analyticsViewProperties.SITE,
    pageDetail,
    pageName,
    pageType,
  };

  return (
    <RemoteComponent
      endpoint={`${NIKE_BASE_URL}/fragments/recommendations-carousel`}
      // forwarded props below
      carouselId={carouselId}
      options={{
        includeClearance: clearance,
        numberOfProducts: maxResults,
        taxonomyIds: taxonomies,
      }}
      title={title ?? ''}
      analyticsConfig={analyticsConfig}
    />
  );
};

RecommendationsCarousel.propTypes = {
  carouselId: PropTypes.string,
  clearance: PropTypes.bool,
  maxResults: PropTypes.number,
  taxonomies: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
};

RecommendationsCarousel.defaultProps = {
  carouselId: 'ciclp.main',
  clearance: false,
};
