import React from 'react';
import PropTypes from 'prop-types';
import HoverIntent from 'react-hoverintent';
import { TextStyled } from '@nike/nike-design-system-components';
import cx from 'clsx';
import styles from './merchMenu.styl';
import NavigationLink from '../navigationLink';
import { DimensionTypeContext } from '../../context';

const renderLinkItem = (link, isHidden) => (
  <li key={link.id}>
    <NavigationLink
      data-analytics-action-id={link.id}
      aria-hidden={isHidden}
      href={link.url}
    >
      <TextStyled Component="span" appearance="body1Strong">
        {link.label}
      </TextStyled>
    </NavigationLink>
  </li>
);

export const calculateHeight = ({
  element,
  shouldExpand,
  isMouseOver,
  isMobile,
  itemsCount,
}) => {
  let height = '0';
  if (isMobile && element && shouldExpand && isMouseOver) {
    height = `${element.offsetHeight}px`;
  }
  if (!isMobile) {
    // 9em is a default height calculated as 4 items height + margin
    height = isMouseOver ? `${itemsCount * 10}em` : `9em`;
  }
  return height;
};

class MerchMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mouseover: false,
      selectedRadio: null,
    };
    this.onMouseOver = this.onMouseOver.bind(this);
    this.onMouseOut = this.onMouseOut.bind(this);
  }

  static contextType = DimensionTypeContext;

  /* istanbul ignore next */
  onMouseOver() {
    this.setState({
      mouseover: true,
    });
  }

  /* istanbul ignore next */
  onMouseOut() {
    this.setState({
      mouseover: false,
    });
  }

  toggleCheckbox(id) {
    this.setState(state => ({
      selectedRadio: id === state.selectedRadio ? null : id,
    }));
  }

  render() {
    const { items, className, analyticsItemId } = this.props;
    const { isMobile } = this.context;
    return (
      <div
        id={analyticsItemId}
        className={cx([
          'ncss-container merchMenu',
          styles.merchMenu,
          className,
        ])}
        data-qa="merch-menu"
        onFocus={this.onMouseOver}
        onBlur={this.onMouseOut}
      >
        <HoverIntent
          onMouseOver={this.onMouseOver}
          onMouseOut={this.onMouseOut}
          sensitivity={10}
          interval={100}
          timeout={350}
        >
          <div
            className={cx([
              'merchMenuWrapper',
              styles.merchMenuWrapper,
              styles[`merchMenuWrapperCol${items.length}`],
              { [styles.active]: this.state.mouseover },
            ])}
            onChange={event => {
              this.toggleCheckbox(event.target.id);
            }}
          >
            {items.map((item, colIndex) => (
              <React.Fragment key={item.id}>
                <input
                  type="checkbox"
                  defaultValue={colIndex}
                  id={`toggle${colIndex}`}
                  checked={this.state.selectedRadio === `toggle${colIndex}`}
                  readOnly
                />
                <h4>
                  <TextStyled
                    Component="label"
                    appearance="title4"
                    role="button"
                    htmlFor={`toggle${colIndex}`}
                    aria-expanded={
                      isMobile
                        ? this.state.selectedRadio === `toggle${colIndex}`
                        : this.state.mouseover
                    }
                    onKeyUp={event => {
                      // if enter or space key pressed, execute expand event
                      // Older browsers may return "Spacebar" instead of " " for the Space Bar key: https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values
                      if (
                        event.key === 'Enter' ||
                        event.key === ' ' ||
                        event.key === 'Spacebar'
                      ) {
                        return this.toggleCheckbox(event.target.htmlFor);
                      }
                      return true;
                    }}
                    tabIndex={isMobile ? '0' : '-1'}
                  >
                    {item.label}
                  </TextStyled>
                </h4>
                {item.links && (
                  <div
                    style={{
                      maxHeight: calculateHeight({
                        element: this[`toggle${colIndex}`],
                        isMobile,
                        isMouseOver: this.state.mouseover,
                        itemsCount: item.links.length,
                        shouldExpand:
                          this.state.selectedRadio === `toggle${colIndex}`,
                      }),
                    }}
                  >
                    <ul
                      ref={ref => {
                        /* istanbul ignore next  line */
                        this[`toggle${colIndex}`] = ref;
                      }}
                    >
                      {item.links.map(l => {
                        const isLinkHidden = isMobile
                          ? this.state.selectedRadio !== `toggle${colIndex}`
                          : !this.state.mouseover;
                        return renderLinkItem(l, isLinkHidden);
                      })}
                    </ul>
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </HoverIntent>
      </div>
    );
  }
}

const menuItemShape = {
  destinationType: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  openInNewTab: PropTypes.bool,
};

MerchMenu.propTypes = {
  analyticsItemId: PropTypes.string,
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      ...menuItemShape,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          ...menuItemShape,
          url: PropTypes.string.isRequired,
        }),
      ),
    }),
  ).isRequired,
};

export default MerchMenu;
