module.exports = {
  HP_NOT_FOUND_RESPONSE_STATUS: 500,
  NCSS_GLYPHS_VERSION: '2.6',
  NCSS_VERSION: '5.0',
  TITLE_TEMPLATE_TRANSLATION_KEYS: {
    GB: 'GB.NikeCom_Suffix',
    JP: 'JP.NikeCom_Suffix',
    KR: 'KR.NikeCom_Suffix',
    default: 'metatag_title_appendix',
  },
};
