/**
 * Delays navigation for a set amount of time to allow other operations to complete
 * @param {MouseEvent} e
 * @param {string} href
 * @param {string} target
 * @param {function} [onClick]
 * @param {number} [timeout=300] - how long in ms until the navigation occurs
 */
export const delayNavigation = (e, href, target, onClick, timeout = 300) => {
  // defaultPrevented needed to check if event was prevented in carousel
  if (e.defaultPrevented) {
    return;
  }
  if (typeof onClick === 'function') {
    onClick();
  }

  if (
    e.altKey ||
    e.ctrlKey ||
    e.shiftKey ||
    e.metaKey ||
    e.button === 2 || // middle mouse button click
    target === '_blank'
  ) {
    return;
  }

  e.preventDefault?.();

  setTimeout(() => {
    window.location.href = href;
  }, timeout);
};
