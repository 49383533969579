import React from 'react';
import PropTypes from 'prop-types';
import {
  SwitchOptionStyled,
  SwitchStyled,
} from '@nike/nike-design-system-components';

export const UnitsToggle = ({
  onUnitsToggleClick,
  className,
  values,
  selectedValue,
}) => {
  return (
    <SwitchStyled
      className={className}
      onClick={onUnitsToggleClick}
      selectedValue={selectedValue}
      name="units-toggle"
    >
      {values.map(measurement => (
        <SwitchOptionStyled key={measurement.id} {...measurement} />
      ))}
    </SwitchStyled>
  );
};

UnitsToggle.propTypes = {
  className: PropTypes.string,
  onUnitsToggleClick: PropTypes.func,
  selectedValue: PropTypes.string,
  unitsType: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};

UnitsToggle.defaultValues = {
  values: [],
};
