// the function coverts duration in munites to ISO 8601 duration format used in structured data
// ISO 8601 duration format docs: https://www.digi.com/resources/documentation/digidocs/90001437-13/reference/r_iso_8601_duration_format.htm
export const covertMinToISOduration = min => {
  const minInDay = 1440;
  const minInHour = 60;

  const days = Math.floor(min / minInDay);
  const minWithoutDays = min - days * minInDay;
  const hours = Math.floor(minWithoutDays / minInHour);
  const minWithoutHours = minWithoutDays - hours * 60;

  const hasDurationData = days || hours || minWithoutHours;

  if (!hasDurationData) {
    return undefined;
  }

  const daysCode = days ? `${days}D` : '';
  const hoursCode = hours ? `${hours}H` : '';
  const timeCode = hours || minWithoutHours ? `T` : '';
  const minCode = minWithoutHours ? `${minWithoutHours}M` : '';

  return `P${daysCode}${timeCode}${hoursCode}${minCode}`;
};

export const getCardObject = card => Object.values(card)[0];
