import React from 'react';
import cx from 'clsx';
import { VideoMedia } from '../videoMedia';
import styles from './videoCard.styl';
import videoMediaStyles from '../videoMedia/components/playerOverlay/styles.styl';
import { videoCardShape } from '../../shapes';
import { VideoCardContent } from './videoCardContent';
import { isTextPositionAfter } from '../../helpers/mediaContent';

export const VideoCardEditorial = ({
  bodyProps,
  withH1,
  subtitleProps,
  textLocation,
  titleProps,
  analyticsItemId,
  loop,
  videoId,
  portraitVideoId,
  controlOptions,
  autoPlay,
  portraitPosterUrl,
  landscapePosterUrl,
  watchCtaButtonText,
  locale,
  language,
  customClass,
  assetsAspectRatios,
}) => {
  const isCardOverlayExist = !!(
    titleProps?.text ||
    subtitleProps?.text ||
    bodyProps?.text
  );
  const hasTextPositionAfter = isTextPositionAfter(textLocation);

  return (
    <div
      data-analytics-placement-id={analyticsItemId}
      data-analytics-has-landmark="true"
      data-qa="video-card"
      className={cx(styles.videoCardContainer, styles.isEditorial, {
        [styles.isTextPositionAfter]: hasTextPositionAfter,
      })}
    >
      <VideoMedia
        autoPlay={autoPlay}
        loop={loop}
        pauseOnLeave={!autoPlay}
        videoId={videoId}
        portraitVideoId={portraitVideoId}
        controlOptions={controlOptions}
        portraitPosterUrl={portraitPosterUrl}
        landscapePosterUrl={landscapePosterUrl}
        locale={locale}
        language={language}
        previewOnHover
        customClass={customClass}
        isCardOverlayExist={isCardOverlayExist}
        watchCtaButtonText={watchCtaButtonText}
        watchCtaButtonStyle={cx(
          hasTextPositionAfter && [
            styles.watchButton,
            videoMediaStyles.overlayPlayBtn,
          ],
        )}
        isTextPositionAfter={hasTextPositionAfter}
        assetsAspectRatios={assetsAspectRatios}
      >
        {!hasTextPositionAfter &&
          (({ playButtonElement }) => (
            <VideoCardContent
              titleProps={titleProps}
              textLocation={textLocation}
              subtitleProps={subtitleProps}
              bodyProps={bodyProps}
              withH1={withH1}
              playButtonElement={playButtonElement}
            />
          ))}
      </VideoMedia>
      {hasTextPositionAfter && (
        <VideoCardContent
          titleProps={titleProps}
          textLocation={textLocation}
          subtitleProps={subtitleProps}
          bodyProps={bodyProps}
          withH1={withH1}
        />
      )}
    </div>
  );
};

VideoCardEditorial.propTypes = videoCardShape;
