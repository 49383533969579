import { selectors } from '@nike/ciclp-redux-app';
import { BRAND_NAMES } from '@nike/ciclp-config';

const { translationSelector, brandNameSelector } = selectors;

const nikeTwitterCardData = store => ({
  card: 'summary_large_image',
  creator: '@nike',
  description: translationSelector(store, 'socialCard_description'),
  imageUrl: `https://c.static-${process.env.NEXT_PUBLIC_NIKE_DOTCOM_DOMAIN}/a/images/w_1200,c_limit/bzl2wmsfh7kgdkufrrjq/seo-title.jpg`,
  site: '@nike',
  title: 'Nike. Just Do It. Nike.com',
});
const getTwitterCardData = store => ({
  [BRAND_NAMES.NIKE]: nikeTwitterCardData(store),
  [BRAND_NAMES.JORDAN]: nikeTwitterCardData(store),
});

const defaultTwitterCardData = store =>
  getTwitterCardData(store)?.[brandNameSelector(store)];

export default defaultTwitterCardData;
