import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { values } from 'ramda';
import { actions, routeNames, selectors } from '@nike/ciclp-redux-app';
import { getErrorInstance } from '@nike/ciclp-redux-app/utils/error';
import { BRAND_NAMES } from '@nike/ciclp-config';
import Routes from './routes';
import { Banner } from '../components/banner';
import { AppContextProvider } from '../context';
import constants from '../constants';
import '../constants/globalStyles.styl';
import { VIDEO_ANALYTICS_EVENT } from '../components/videoMedia/constants';
import { delayNavigation } from '../helpers/delayNavigation';

/////////////////////////
//////////////////////////////////////////////////
/////////
import { DynamicStructuredSeoData } from '../components/structuredSeoData';

const { analyticsActions, bootstrapActions } = actions;

const {
  ANALYTICS_ACTION_SELECTOR,
  ANALYTICS_PLACEMENT_SELECTOR,
  ANALYTICS_ALLOWED_ELEMENTS_SELECTOR,
} = constants;

export class RootContainer extends React.PureComponent {
  static propTypes = {
    analyticsTrack: PropTypes.func.isRequired,
    bootstrapInit: PropTypes.func.isRequired,
    brand: PropTypes.oneOf(values(BRAND_NAMES)),
    error: PropTypes.object,
    isBootstrapped: PropTypes.bool,
    routeName: PropTypes.string,
    showPromoBanner: PropTypes.bool,
  };

  componentDidMount() {
    const { bootstrapInit, analyticsTrack } = this.props;
    bootstrapInit();
    window.addEventListener(VIDEO_ANALYTICS_EVENT, e =>
      analyticsTrack(e.detail),
    );
  }

  handleClick = e => {
    const { target } = e;
    const el = target.matches(ANALYTICS_ALLOWED_ELEMENTS_SELECTOR)
      ? target
      : target.closest(ANALYTICS_ALLOWED_ELEMENTS_SELECTOR);
    if (!el) {
      return;
    }
    const actionElement = target.matches(ANALYTICS_ACTION_SELECTOR)
      ? target
      : target.closest(ANALYTICS_ACTION_SELECTOR);
    const placementElement = target.closest(ANALYTICS_PLACEMENT_SELECTOR);
    const actionId = actionElement?.dataset?.analyticsActionId;
    const analyticsClickActivityText =
      actionElement?.dataset?.analyticsClickActivityText;
    const placementId = placementElement?.dataset?.analyticsPlacementId;
    if (!actionId && !placementId) {
      return;
    }
    const eventData = {
      actionId,
      placementId,
      ...(analyticsClickActivityText && {
        clickContentLabel: analyticsClickActivityText,
      }),
    };

    this.props.analyticsTrack(eventData);
    if (el?.href) {
      delayNavigation(e, el.href, el.target);
    }
  };

  render() {
    const { routeName, error, isBootstrapped, brand, showPromoBanner } =
      this.props;

    let errorModal;
/////////////////////////////
/////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////
/////
/////////////

    return isBootstrapped ? (
      <AppContextProvider brand={brand}>
        <DynamicStructuredSeoData />
        <div role="main" onClick={this.handleClick}>
          {showPromoBanner && <Banner />}
          <Routes />
          {errorModal}
        </div>
      </AppContextProvider>
    ) : null;
  }
}

const mapStateToProps = state => ({
  brand: selectors.brandNameSelector(state),
  error: selectors.errorSelector(state),
  isBootstrapped: selectors.isBootstrappedSelector(state),
  routeName: selectors.routeNameSelector(state),
  showPromoBanner: selectors.showPromoBannerSelector(state),
});

const mapDispatchToProps = {
  analyticsTrack: analyticsActions.track,
  bootstrapInit: bootstrapActions.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(RootContainer);
