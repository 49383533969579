import NikeOneVideoPlayer from '@nike/nike-one-video-player';
import { VIDEO_EVENTS } from '../../constants';

const BasePlugin = NikeOneVideoPlayer.getPlugin('plugin');

export class PauseOnLeave extends BasePlugin {
  constructor(player, options) {
    super(player, options);
    this.options = options;

    this.isAutoplay = this.player.autoplay();

    this.player.on(VIDEO_EVENTS.DISPOSE, this.disable);
    this.player.on(VIDEO_EVENTS.CANPLAY, this.enable);
  }

  observerCallback = ([entry]) => {
    if (this.player.isFullscreen()) {
      return;
    }
    if (this.isAutoplay) {
      if (entry.isIntersecting && !this.player.state.isInitiatedByUser) {
        this.player.autoplay('muted');
      } else if (entry.isIntersecting && this.player.state.isInitiatedByUser) {
        this.player.autoplay();
      } else {
        this.player.pause();
      }
    } else if (
      !entry.isIntersecting &&
      this.player.state.isInitiatedByUser &&
      this.options.enabled
    ) {
      this.player.pause();
    }
  };

  enable = () => {
    this.observer = new IntersectionObserver(this.observerCallback, {
      threshold: this.options.threshold || 0,
    });
    this.observer.observe(this.player.el());
  };

  disable = () => {
    this.observer?.disconnect();
    this.player?.off(VIDEO_EVENTS.CANPLAY, this.enable);
  };
}
