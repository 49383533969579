import { useRef, useState, useEffect } from 'react';
import { isElementVisible } from './helpers';

export const useIsVideoRendered = ({ isMobile, isInitedOnClient }) => {
  const wrapper = useRef(null);
  const [isVideoRendered, setIsVideoRendered] = useState(false);

  useEffect(() => {
    if (wrapper.current && isInitedOnClient) {
      setIsVideoRendered(isElementVisible(wrapper.current));
    }
  }, [isMobile, isInitedOnClient]);

  return [wrapper, isVideoRendered];
};

export const useResolvedVideoParams = ({
  isPortrait,
  portraitPosterUrl,
  landscapePosterUrl,
  portraitVideoId,
  videoId,
}) => {
  const [posterImage, setPosterImage] = useState('');
  const [renderedVideoId, setRenderedVideoId] = useState('');
  useEffect(() => {
    setPosterImage(
      isPortrait && portraitPosterUrl ? portraitPosterUrl : landscapePosterUrl,
    );
    setRenderedVideoId(
      isPortrait && portraitVideoId ? portraitVideoId : videoId,
    );
  }, [
    isPortrait,
    portraitPosterUrl,
    landscapePosterUrl,
    portraitVideoId,
    videoId,
  ]);

  return [posterImage, renderedVideoId];
};
