import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import styles from './dynamicCarousel.styl';
import ContentSlide from '../contentSlide';
import SectionHeadline from '../sectionHeadline';
import constants from '../../constants';
import { sectionHeadlineShape, gallerySlideShape } from '../../shapes';
import ContentFeatured from '../contentFeatured';
import { DynamicCarouselCarousel, DynamicTwoInRow } from './templates';

export const renderSlides = (
  filmstripItems,
  analyticsItemId,
  hasFeaturedCard,
) => {
  return filmstripItems.map((slide, index) => {
    const analyticsSlideId = `${analyticsItemId}_${
      index + (hasFeaturedCard ? 2 : 1)
    }`;
    return (
      <ContentSlide
        key={slide.id}
        {...slide}
        analyticsItemId={analyticsSlideId}
      />
    );
  });
};

const getDynamicTemplate = shouldCenterFilmstrip => {
  if (shouldCenterFilmstrip) {
    return DynamicTwoInRow;
  }
  return DynamicCarouselCarousel;
};

export const DynamicCarousel = ({
  analyticsItemId,
  sectionHeadline,
  slides,
  featuredCard,
}) => {
  const filmstripItems = slides.slice(0, constants.MAX_PRODUCT_LIST_LENGTH);
  const headlineAnalyticsId = `${analyticsItemId}_0`;
  const shouldCenterFilmstrip =
    filmstripItems.length < constants.MIN_PRODUCT_LIST_LENGTH;
  const Template = getDynamicTemplate(shouldCenterFilmstrip);
  const hasFeaturedCard = !!featuredCard;
  const dynamicCarouselSlides = renderSlides(
    filmstripItems,
    analyticsItemId,
    hasFeaturedCard,
  );

  return (
    <div data-qa="dynamic-carousel" className={styles.dynamicContent}>
      {hasFeaturedCard && (
        <div
          className={cx({
            [styles.featuredWrapper]: !shouldCenterFilmstrip,
            [styles.twoInRowWrapper]: shouldCenterFilmstrip,
          })}
        >
          <SectionHeadline
            {...sectionHeadline}
            analyticsItemId={headlineAnalyticsId}
          />
          <ContentFeatured
            {...featuredCard}
            analyticsItemId={`${analyticsItemId}_1`}
          />
        </div>
      )}
      <div data-qa="dynamic-carousel-slides">
        <Template
          analyticsItemId={analyticsItemId}
          sectionHeadline={sectionHeadline}
          filmstripSlides={dynamicCarouselSlides}
          hasFeaturedCard={hasFeaturedCard}
          headlineAnalyticsId={headlineAnalyticsId}
        />
      </div>
    </div>
  );
};

DynamicCarousel.propTypes = {
  analyticsItemId: PropTypes.string,
  featuredCard: PropTypes.shape(gallerySlideShape),
  sectionHeadline: sectionHeadlineShape,
  slides: PropTypes.arrayOf(PropTypes.shape(gallerySlideShape)),
};
