import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import {
  CarouselStyled,
  useBreakpoint,
  useMatchMedia,
} from '@nike/nike-design-system-components';
import { getCardHash, LAUNCH_SLUGS } from '@nike/ciclp-redux-app';
import ProductSlide from '../productSlide';
import styles from './productContainer.styl';
import carouselCommonStyles from '../../constants/carouselsCommonStyles.styl';
import ProductContainerPlaceholder from './productContainerPlaceholder';
import constants from '../../constants';

import { productContainerShape } from '../../shapes';

import { ProductCarouselTitle } from './productContainerTitle';
import { ProductCarouselIcon } from './productContainerIcon';

const composeViewAllButtonProps = (
  { actions },
  activeSnkrsTab,
  analyticsItemId,
) => {
  const action = actions?.[0];

  if (!action) {
    return null;
  }

  const LAUNCH_SLUG = LAUNCH_SLUGS[activeSnkrsTab] || '';

  return {
    'aria-describedby': `title-${analyticsItemId}`,
    'data-analytics-action-id': action.id,
    href: `${action.destinationId}${LAUNCH_SLUG}`,
    target: action.openInNewTab ? '_blank' : '_self',
    text: action.actionText,
  };
};

export const ProductContainer = ({
  slides,
  labels,
  sectionHeadline,
  analyticsItemId,
  isLoading,
  dataQa,
  containerType,
  SlideComponent,
  ToggleComponent,
  placementGroupIndex,
  activeSnkrsTab,
}) => {
  const hasLessSlidesThanScreenContains =
    slides?.length < constants.MIN_PRODUCT_LIST_LENGTH;
  const mobileMediaQuery = useBreakpoint(`lt`, `s`);
  const isMobile = useMatchMedia(mobileMediaQuery);
  const shouldShowArrows =
    !isMobile && slides.length > constants.MIN_PRODUCT_LIST_LENGTH;
  const navigationMode = shouldShowArrows
    ? constants.CAROUSEL_NAVIGATION_MODE.buttonAbove
    : constants.CAROUSEL_NAVIGATION_MODE.scroll;

  if (isLoading) {
    return (
      <ProductContainerPlaceholder className={styles.containerMinHeight} />
    );
  }

  if (hasLessSlidesThanScreenContains) {
    return null;
  }

  return (
    <div
      data-qa="product-container"
      data-qa-container={dataQa}
      className={cx(shouldShowArrows && styles.containerMinHeight)}
    >
      <ProductCarouselIcon containerType={containerType} />
      <CarouselStyled
        childContentFocusable
        conditionallyHideNavButtons={false}
        navigationMode={navigationMode}
        slidesToShow={{
          xs: constants.MIN_PRODUCT_LIST_LENGTH,
        }}
        alignToGrid
        className={carouselCommonStyles.carousel}
        classes={{
          listContainer: carouselCommonStyles.container,
          navBtnWrapper: carouselCommonStyles.navigation,
        }}
        headerProps={{
          'data-analytics-placement-id': `${analyticsItemId}_0`,
        }}
        slots={{
          TitleSlot: (
            <ProductCarouselTitle
              ToggleComponent={ToggleComponent}
              sectionHeadline={{
                ...sectionHeadline,
                actions: [],
                analyticsItemId,
              }}
              id={analyticsItemId}
            />
          ),
        }}
        titleProps={{
          id: analyticsItemId, // to be used as carousel aria-labelledby attribute value, should repeat id on TitleSlot component, see Carousel component code
        }}
        viewAllButtonProps={composeViewAllButtonProps(
          sectionHeadline,
          activeSnkrsTab,
          analyticsItemId,
        )}
      >
        {slides?.map((productItem, index) => (
          <SlideComponent
            analyticsItemId={`${getCardHash({
              hasSeveralPlacements: true,
              id: analyticsItemId,
              index: index + 1,
              tabIndex: placementGroupIndex,
            })}`}
            labels={labels}
            key={`${productItem.productId}-${productItem.productThreadId}`}
            {...productItem}
          />
        ))}
      </CarouselStyled>
    </div>
  );
};

ProductContainer.propTypes = {
  ...productContainerShape,
  SlideComponent: PropTypes.elementType,
  analyticsItemId: PropTypes.string,
  containerType: PropTypes.string,
};

ProductContainer.defaultProps = {
  SlideComponent: ProductSlide,
  slides: [],
};
