import React from 'react';
import {
  TextStyled,
  useBreakpoint,
  useMatchMedia,
} from '@nike/nike-design-system-components';
import { useSelector, shallowEqual } from 'react-redux';
import { selectors } from '@nike/ciclp-redux-app';
import { RainbowText } from '../rainbowText';
import styles from './multipleBadges.styl';

export const MultipleBadges = () => {
  const { labels } = useSelector(
    selectors.productCardLabelsSelector,
    shallowEqual,
  );
  const isLarge = useMatchMedia(useBreakpoint(`gte`, `m`));
  const badgeAppearance = isLarge ? `body1Strong` : `body2Strong`;

  return (
    <>
      <TextStyled
        appearance={badgeAppearance}
        className={styles.memberExclusive}
      >
        {labels.memberAccessLabel}
      </TextStyled>
      <TextStyled appearance={badgeAppearance}>
        <RainbowText>{labels.nikeByYouLabel}</RainbowText>
      </TextStyled>
    </>
  );
};
