import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import styles from './glyphRenderer.styl';
import { GLYPH_URL } from './constants';

const getDash = hasIndex => (hasIndex ? '-' : '');
const convertToDashCase = (letter, index) =>
  `${getDash(index)}${letter.toLowerCase()}`;

const getGlyphSrc = glyph =>
  `${GLYPH_URL}${
    // converting camel case to dash case
    glyph.replace(/[A-Z]/g, convertToDashCase)
  }.svg`;

const GlyphRenderer = ({ glyph, size, title }) => (
  <h1 className={cx(['mb3-md', 'mb1-sm', styles.wrapper])}>
    <img
      src={getGlyphSrc(glyph)}
      className={cx([styles.logo, styles[size] || styles.medium])}
      alt={title}
    />
  </h1>
);

GlyphRenderer.propTypes = {
  glyph: PropTypes.string.isRequired,
  size: PropTypes.string,
  title: PropTypes.string,
};

export default GlyphRenderer;
