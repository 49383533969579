import React from 'react';
import PropTypes from 'prop-types';
import SectionHeadline from '../sectionHeadline';
import styles from './productContainer.styl';

import { productContainerShape } from '../../shapes';

export const ProductCarouselTitle = ({ sectionHeadline, ToggleComponent }) => (
  <>
    <SectionHeadline
      {...sectionHeadline}
      containerClassName={styles.headline}
      ToggleComponent={ToggleComponent}
    />
  </>
);

ProductCarouselTitle.propTypes = {
  ToggleComponent: PropTypes.element,
  id: PropTypes.string,
  sectionHeadline: productContainerShape.sectionHeadline,
};
