import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { actions, selectors } from '@nike/ciclp-redux-app';
import { UnitsToggle } from '../../components/unitsToggle';

export const UnitsToggleContainer = ({ className, unitsType, values }) => {
  const savedMeasurementsSettings = useSelector(
    selectors.measurementSelector,
    shallowEqual,
  );
  const dispatch = useDispatch();

  const handleUnitsToggleClick = (event, selectedValue) => {
    dispatch(
      actions.measurementActions.set({
        [unitsType]: selectedValue,
      }),
    );
  };

  return (
    <UnitsToggle
      className={className}
      selectedValue={savedMeasurementsSettings[unitsType]}
      onUnitsToggleClick={handleUnitsToggleClick}
      values={values}
    />
  );
};

UnitsToggleContainer.propTypes = {
  className: PropTypes.string,
  unitsType: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};
