import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { TextStyled } from '@nike/nike-design-system-components';
import NavigationLink from '../navigationLink';
import styles from './localNav.styl';
import { DimensionTypeContext } from '../../context';

export default class LocalNavDropdown extends Component {
  static contextType = DimensionTypeContext;

  subMenu = React.createRef();

  container = React.createRef();

  state = {
    isFocusedOrHovered: false,
    isTapped: false,
  };

  componentDidMount() {
    window.addEventListener('resize', this.isPositionCheckNeeded);
    window.addEventListener('touchstart', this.handleOutsideEvent);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.isPositionCheckNeeded);
    window.removeEventListener('touchstart', this.handleOutsideEvent);
  }

  isPositionCheckNeeded = () => {
    const { isTablet, isDesktop, isDesktopWide } = this.context;

    if (isTablet || isDesktop || isDesktopWide) {
      this.container.current.addEventListener('mouseenter', this.isFitToScreen);
    }
  };

  isFitToScreen = () => {
    const { current: subMenu } = this.subMenu;
    const { current: container } = this.container;
    const delta =
      window.innerWidth -
      (container.getBoundingClientRect().left + subMenu.clientWidth);
    if (delta <= 0) {
      subMenu.classList.add(styles.right);
    } else {
      subMenu.classList.remove(styles.right);
    }
  };

  handleTouchStart = event => {
    this.swipe = { x: event.touches[0].clientX };
  };

  handleTouchMove = event => {
    if (event.changedTouches?.length) {
      this.swipe.swiping = true;
    }
  };

  handleTouchEnd = event => {
    const absX = Math.abs(event.changedTouches[0].clientX - this.swipe.x);
    if (!this.swipe.swiping || absX <= 10) {
      this.setState(state => ({
        isFocusedOrHovered: !state.isFocusedOrHovered,
      }));
    }
    this.swipe = {};
  };

  handleMouseOver = () => {
    this.setState({
      isFocusedOrHovered: true,
    });
  };

  handleMouseOut = () => {
    this.setState({
      isFocusedOrHovered: false,
      isTapped: false,
    });
  };

  handleFocus = () => {
    this.setState({
      isFocusedOrHovered: true,
    });
  };

  handleBlur = () => {
    this.setState({
      isFocusedOrHovered: false,
      isTapped: false,
    });
  };

  handleOutsideEvent = event => {
    if (
      this.container.current.closest('.ncss-container').contains(event.target)
    ) {
      return;
    }
    this.setState({
      isFocusedOrHovered: false,
    });
  };

  handleClick = event => {
    const { isTouchDevice } = this.context;
    const { isTapped } = this.state;

    // for touch screen devices, expand the dropdown on the first tap and navigate to the link on the second
    if (!isTapped && isTouchDevice) {
      event.preventDefault();
      this.setState({
        isTapped: true,
      });
    }
  };

  render() {
    const { content } = this.props;
    const { isFocusedOrHovered, isTapped } = this.state;
    const { isTablet, isDesktop, isDesktopWide } = this.context;
    const isExpanded = !!(isFocusedOrHovered || isTapped);

    return (
      <div
        className={cx(styles.withSubMenu, {
          [styles.isFocusedOrHovered]: isFocusedOrHovered,
        })}
        ref={this.container}
        {...((isTablet || isDesktop || isDesktopWide) && {
          onMouseEnter: () => this.isFitToScreen(),
        })}
        onMouseOver={this.handleMouseOver}
        onMouseOut={this.handleMouseOut}
      >
        {content.url ? (
          <NavigationLink
            href={content.url}
            data-analytics-action-id={content.id}
            className={styles.localNavItem}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            aria-expanded={isExpanded}
            onClick={this.handleClick}
          >
            {content.label}
          </NavigationLink>
        ) : (
          <TextStyled
            appearance="body1Strong"
            Component="span"
            tabIndex="0"
            aria-haspopup="true"
            role="menuitem"
            className={styles.localNavItem}
            onTouchStart={this.handleTouchStart}
            onTouchMove={this.handleTouchMove}
            onTouchEnd={this.handleTouchEnd}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            aria-expanded={isExpanded}
          >
            {content.label}
          </TextStyled>
        )}
        <ul className={styles.dropdown} ref={this.subMenu}>
          {content.links.map(link => (
            <li key={link.id}>
              <NavigationLink
                href={link.url}
                data-analytics-action-id={link.id}
                className={styles.localNavItem}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
              >
                {link.label}
              </NavigationLink>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

LocalNavDropdown.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
    links: PropTypes.array,
    url: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
};
