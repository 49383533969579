import React from 'react';
import cx from 'clsx';
import {
  VideoMedia,
  mergeButtons,
  getFilmstripVideoSlideProps,
} from '../videoMedia';
import { gallerySlideShape } from '../../shapes';
import { SlideContent } from './slideContent';
import imageHeights from '../../constants/imageHeights.styl';
import videoMediaStyles from '../videoMedia/components/playerOverlay/styles.styl';
import { getSlideContentProps } from './helpers';
import {
  isCardOverlayNeeded,
  isTextPositionAfter,
} from '../../helpers/mediaContent';

export const FilmstripVideoContent = props => {
  const hasTextPositionAfter = isTextPositionAfter(props.textLocation);
  const isCardOverlayExist =
    !hasTextPositionAfter && isCardOverlayNeeded(props);

  const videoProps = getFilmstripVideoSlideProps(props);
  const slideContentProps = getSlideContentProps(props);

  return (
    <>
      <VideoMedia
        {...videoProps}
        isTextPositionAfter={hasTextPositionAfter}
        isCardOverlayExist={isCardOverlayExist}
        watchCtaButtonStyle={
          hasTextPositionAfter && videoMediaStyles.overlayPlayBtn
        }
        customClass={cx(props.imageHeight && imageHeights[props.imageHeight])}
      >
        {!hasTextPositionAfter &&
          (({ buttonProps }) => (
            <SlideContent
              {...slideContentProps}
              actionButtons={mergeButtons(props.actionButtons, buttonProps)}
            />
          ))}
      </VideoMedia>
      {hasTextPositionAfter && <SlideContent {...props} />}
    </>
  );
};

FilmstripVideoContent.propTypes = gallerySlideShape;
