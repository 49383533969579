import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { CtaButton } from '../ctaButton/ctaButton';

import styles from './stackedCta.styl';
import {
  actionButtonsShape,
  actionTypeShape,
  cardOverlayPositionsShape,
} from '../../shapes';
import {
  css,
  getAnimationEndCssProps,
  getAnimationStartCssProps,
} from './helpers';
import { DimensionTypeContext } from '../../context';

export default class StackedCta extends Component {
  static contextType = DimensionTypeContext;

  state = {
    menuEndState: { height: 0, width: 0 },
    menuInitState: { height: 0, width: 0 },
    showStackedCta: false,
  };

  stackedCtaRef = React.createRef();

  stackedCtaMenuRef = React.createRef();

  outsideClickEvents = ['click', 'mousedown', 'touchend', 'scroll'];

  componentDidMount() {
    const { current: stackedCtaBtn } = this.stackedCtaRef;
    const { current: stackedCtaMenu } = this.stackedCtaMenuRef;
    // +1 is applied to stacked stackedCtaMenu.offsetWidth to compensate decimals rounding and to avoid ellipsis if possible
    if (stackedCtaBtn && stackedCtaMenu) {
      this.setState({
        isMenuNarrow:
          stackedCtaBtn.firstChild.clientWidth > stackedCtaMenu.offsetWidth,
        menuEndState: {
          height: stackedCtaMenu.offsetHeight,
          width: Math.max(
            stackedCtaBtn.firstChild.clientWidth,
            stackedCtaMenu.offsetWidth + 1,
          ),
        },
        menuInitState: {
          height: stackedCtaBtn.firstChild.clientHeight,
          width: stackedCtaBtn.firstChild.clientWidth,
        },
      });
    }
  }

  componentWillUnmount() {
    this.outsideClickEvents.forEach(eventName => {
      document.removeEventListener(eventName, this.handleOutsideClick);
    });
  }

  showStackedCtaMenu = actions => () => {
    if (actions.filter(action => action.actionText).length > 0) {
      this.setState({
        showStackedCta: true,
      });
      this.outsideClickEvents.forEach(eventName => {
        document.addEventListener(eventName, this.handleOutsideClick);
      });
    }
  };

  handleOutsideClick = e => {
    if (e && this.stackedCtaRef.current.contains(e.target)) {
      return;
    }

    this.setState({
      showStackedCta: false,
    });
    this.outsideClickEvents.forEach(eventName => {
      document.removeEventListener(eventName, this.handleOutsideClick);
    });
  };

  render() {
    const { actionButton, index, colorTheme, dataQa, isMenuOnTop } = this.props;
    const { actions, textLocation } = actionButton;
    const { showStackedCta, menuInitState, menuEndState, isMenuNarrow } =
      this.state;

    return (
      <div className={styles.stackedCta} ref={this.stackedCtaRef}>
        <CtaButton
          dataQa={dataQa}
          index={index}
          {...actionButton}
          colorTheme={colorTheme}
          handleClick={this.showStackedCtaMenu(actions)}
          renderAs="button"
        />
        <div
          style={{
            height: `${
              showStackedCta ? menuEndState.height : menuInitState.height
            }px`,
            width: `${
              showStackedCta ? menuEndState.width : menuInitState.width
            }px`,
          }}
          className={cx(
            styles.stackedCtaMenu,
            styles[colorTheme],
            css(isMenuOnTop, textLocation),
            showStackedCta ? styles.stackedCtaMenuVisible : '',
          )}
        >
          <ul
            ref={this.stackedCtaMenuRef}
            className={cx(
              styles.stackedCtaMenuList,
              styles[colorTheme],
              isMenuNarrow && styles.stackedCtaMenuNarrow,
            )}
            tabIndex={showStackedCta ? 0 : -1}
          >
            {actions.map((action, idx) => {
              const animationPos = this.context.isMobile
                ? { horizontal: 'start' }
                : textLocation;
              const animationCssProps = showStackedCta
                ? getAnimationEndCssProps(animationPos, idx)
                : getAnimationStartCssProps(animationPos, idx);

              return (
                <li
                  key={`cta-button-${index + idx}`}
                  className={styles.stackedCtaMenuItem}
                  style={{
                    ...animationCssProps,
                  }}
                >
                  <CtaButton
                    dataQa={dataQa}
                    index={index + idx}
                    {...action}
                    colorTheme
                    className={cx(
                      styles.textLink,
                      colorTheme === 'light'
                        ? styles.ctaTextOnLight
                        : styles.ctaTextOnDark,
                    )}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}

StackedCta.propTypes = {
  actionButton: PropTypes.shape({
    actionType: actionTypeShape,
    actions: actionButtonsShape,
    textLocation: PropTypes.shape(cardOverlayPositionsShape),
  }),
  colorTheme: PropTypes.oneOf(['dark', 'light']),
  dataQa: PropTypes.string,
  index: PropTypes.number,
  isMenuOnTop: PropTypes.bool,
};

StackedCta.defaultProps = {
  colorTheme: 'dark',
};
