import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { TextStyled } from '@nike/nike-design-system-components';

import { BodyText, SubtitleText, TitleText } from '../textOverlay';
import { isTextNotEmpty } from '../../helpers/mediaContent';
import styles from './textCard.styl';
import { MultiCta } from '../ctaButton';
import { CardLink } from '../cardLink';
import {
  cardStyledTextShape,
  genericCardDataShape,
  actionButtonsShape,
  textLocationShape,
  colorThemeShape,
} from '../../shapes';
import { isTextPresent } from '../../helpers/text';

const css = ({ horizontal }) => {
  const horizontalFix = horizontal === 'before' ? 'start' : horizontal;
  return styles[`h_${horizontalFix}`];
};

export const TextCard = ({
  bodyProps,
  analyticsItemId,
  titleProps,
  subtitleProps,
  backgroundColor,
  withH1,
  textLocation,
  cardLinkId,
  cardLinkUrl,
  openInNewTab,
  actionButtons,
  cardLinkMemberOnly,
  colorTheme,
  destinationType,
  cardLinkCollectionId,
  originallyPublished,
  originallyPublishedTemplate,
}) => {
  const body = isTextPresent(bodyProps?.text);
  const isBodyLarge = !bodyProps?.fontSizeSet;
  const title = titleProps?.text;
  const subTitle = subtitleProps?.text;

  if (!title && !body && !originallyPublished) {
    return null;
  }

  const originallyPublishedFormatted =
    !!originallyPublished &&
    originallyPublishedTemplate?.replace('[date]', originallyPublished);

  return (
    <div
      data-analytics-placement-id={analyticsItemId}
      data-analytics-has-landmark="true"
      className={cx([
        styles.textCard,
        body && styles.withBody,
        css(textLocation ?? {}),
      ])}
      data-qa="text-card"
      style={{ backgroundColor }}
    >
      {cardLinkId && (
        <CardLink
          actionId={cardLinkId}
          destinationType={destinationType}
          analyticsItemId={analyticsItemId}
          collectionId={cardLinkCollectionId}
          memberOnly={cardLinkMemberOnly}
          destinationUrl={cardLinkUrl}
          openInNewTab={openInNewTab}
          tabbable={!actionButtons?.length}
          ariaLabel={`${subtitleProps?.text} ${titleProps?.text}`}
        />
      )}
      {subTitle && (
        <SubtitleText {...subtitleProps} customClassName={styles.subtitle} />
      )}
      {title && (
        <TitleText
          {...{
            ...titleProps,
            customClassName: styles.title,
            ...(withH1 && { renderAs: 'h1' }),
          }}
        />
      )}
      {body && (
        <BodyText
          {...bodyProps}
          customClassName={isBodyLarge ? styles.bodyLarge : styles.body}
          renderAs="div"
        />
      )}
      {!!originallyPublishedFormatted && (
        <TextStyled
          data-qa="publish-date"
          appearance="body1"
          color="secondary"
          className="mt6-sm"
        >
          {originallyPublishedFormatted}
        </TextStyled>
      )}
      {actionButtons?.length ? (
        <MultiCta
          actionButtons={actionButtons}
          colorTheme={colorTheme}
          analyticsItemId={analyticsItemId}
          addTopSpacing={isTextNotEmpty(
            bodyProps.text,
            subtitleProps.text,
            titleProps.text,
          )}
        />
      ) : null}
    </div>
  );
};

TextCard.propTypes = {
  actionButtons: actionButtonsShape,
  analyticsItemId: PropTypes.string,
  backgroundColor: PropTypes.string,
  bodyProps: cardStyledTextShape,
  cardLinkCollectionId: PropTypes.string,
  cardLinkId: PropTypes.string,
  cardLinkMemberOnly: PropTypes.bool,
  cardLinkUrl: PropTypes.string,
  colorTheme: colorThemeShape,
  destinationType: PropTypes.string,
  openInNewTab: PropTypes.bool,
  originallyPublished: PropTypes.string,
  originallyPublishedTemplate: PropTypes.string,
  properties: genericCardDataShape,
  subtitleProps: cardStyledTextShape,
  textLocation: textLocationShape,
  titleProps: cardStyledTextShape,
  withH1: PropTypes.bool,
};

export default TextCard;
