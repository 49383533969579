export const organizationData = {
  '@id': 'https://www.nike.com/#organization',
  contactPoint: [
    {
      '@type': 'ContactPoint',
      areaServed: 'US',
      availableLanguage: 'English',
      contactType: 'customer support',
      telephone: '+1-800-806-6453',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'AT',
      availableLanguage: ['English', 'German'],
      contactType: 'customer support',
      telephone: '+43 (0) 13602773720',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'BE',
      availableLanguage: ['English', 'Dutch', 'French', 'German'],
      contactType: 'customer support',
      telephone: '+32 (0) 26200166',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'CN',
      availableLanguage: 'Simplified Chinese',
      contactOption: 'Mobile',
      contactType: 'customer support',
      telephone: '+86 400-880-6453',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'CN',
      availableLanguage: 'Simplified Chinese',
      contactOption: 'Landline',
      contactType: 'customer support',
      telephone: '+86 800-820-8865',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'CZ',
      availableLanguage: ['English', 'Czech'],
      contactType: 'customer support',
      telephone: '+420 225376453',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'DK',
      availableLanguage: ['English', 'Danish'],
      contactType: 'customer support',
      telephone: '+45 82333183',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'FI',
      availableLanguage: 'English',
      contactType: 'customer support',
      telephone: '+358 (0) 981710428',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'FR',
      availableLanguage: 'French',
      contactType: 'customer support',
      telephone: '+33 (0) 170489073',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'DE',
      availableLanguage: 'German',
      contactType: 'customer support',
      telephone: '+49 (0) 6995206453',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'GR',
      availableLanguage: 'Greek',
      contactType: 'customer support',
      telephone: '+30 2111813851',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'HU',
      availableLanguage: ['English', 'Hungarian'],
      contactType: 'customer support',
      telephone: '+36 13285338',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'IE',
      availableLanguage: 'English',
      contactType: 'customer support',
      telephone: '+353 (0) 15251800',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'IT',
      availableLanguage: 'Italian',
      contactType: 'customer support',
      telephone: '+39 0 238591453',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'JP',
      availableLanguage: 'Japanese',
      contactType: 'customer support',
      telephone: '+81 0120-6453-77',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'LU',
      availableLanguage: ['English', 'French', 'German'],
      contactType: 'customer support',
      telephone: '+352 27302076',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'NL',
      availableLanguage: ['English', 'Dutch'],
      contactType: 'customer support',
      telephone: '+31 (0) 207219453',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'PL',
      availableLanguage: 'Polish',
      contactType: 'customer support',
      telephone: '+48 (0) 225844279',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'PT',
      availableLanguage: ['English', 'Portuguese'],
      contactType: 'customer support',
      telephone: '+351 213180051',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'SI',
      availableLanguage: 'English',
      contactType: 'customer support',
      telephone: '+386 (0) 1600 1436',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'ES',
      availableLanguage: ['Spanish', 'Catalan'],
      contactType: 'customer support',
      telephone: '+34 913753366',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'SE',
      availableLanguage: ['English', 'Swedish'],
      contactType: 'customer support',
      telephone: '+46 (0) 851992310',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'GB',
      availableLanguage: 'English',
      contactType: 'customer support',
      telephone: '+44 (0) 2076604453',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'AU',
      availableLanguage: 'English',
      contactType: 'customer support',
      telephone: '+61 1300 656 453',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'BG',
      availableLanguage: 'English',
      contactType: 'customer support',
      telephone: '+35929358236',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'CA',
      availableLanguage: ['English', 'French'],
      contactType: 'customer support',
      telephone: '+1-844-430-6453',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'CL',
      availableLanguage: 'Spanish',
      contactType: 'customer support',
      telephone: '+56 800-395-180',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'HR',
      availableLanguage: 'English',
      contactType: 'customer support',
      telephone: '+38517776390',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'EG',
      availableLanguage: 'English',
      contactType: 'customer support',
      telephone: '+20 08000009360',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'IN',
      availableLanguage: 'English',
      contactType: 'customer support',
      telephone: '+91 000 800 100 9538',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'ID',
      availableLanguage: 'English',
      contactType: 'customer support',
      telephone: '+62 001-803-65-6453',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'IL',
      availableLanguage: 'English',
      contactType: 'customer support',
      telephone: '+972 09-9720922',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'MY',
      availableLanguage: 'English',
      contactType: 'customer support',
      telephone: '+1-800-80-6453',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'MX',
      availableLanguage: 'Spanish',
      contactType: 'customer support',
      telephone: '+52 01-800-062-3550',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'MA',
      availableLanguage: ['English', 'French'],
      contactType: 'customer support',
      telephone: '+212 520 480072',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'NZ',
      availableLanguage: 'English',
      contactType: 'customer support',
      telephone: '+64 0800 113 661',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'NO',
      availableLanguage: ['English', 'Norwegian'],
      contactType: 'customer support',
      telephone: '+47 (0) 23500397',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'PR',
      availableLanguage: 'Spanish',
      contactType: 'customer support',
      telephone: '+1 844-301-7028',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'RO',
      availableLanguage: 'English',
      contactType: 'customer support',
      telephone: '+40311305016',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'RU',
      availableLanguage: 'Russian',
      contactType: 'customer support',
      telephone: '+7 8 (495) 926-72-53',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'SA',
      availableLanguage: 'English',
      contactType: 'customer support',
      telephone: '+966 8008140085',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'SG',
      availableLanguage: 'English',
      contactType: 'customer support',
      telephone: '+65 1800 811 6453',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'SK',
      availableLanguage: 'English',
      contactType: 'customer support',
      telephone: '+421250112158',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'ZA',
      availableLanguage: 'English',
      contactType: 'customer support',
      telephone: '+27 011 256 0701',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'CH',
      availableLanguage: ['English', 'German', 'French', 'Italian'],
      contactType: 'customer support',
      telephone: '+41 (0) 225675466',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'TW',
      availableLanguage: 'Traditional Chinese',
      contactType: 'customer support',
      telephone: '+886 0800-886-453',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'TH',
      availableLanguage: 'Thai',
      contactType: 'customer support',
      telephone: '+66 001-800-65-6453',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'TR',
      availableLanguage: 'Turkish',
      contactType: 'customer support',
      telephone: '+90 (212) 365 04 90',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'AE',
      availableLanguage: 'English',
      contactType: 'customer support',
      telephone: '+971 800035703048',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'VN',
      availableLanguage: 'English',
      contactOption: 'Viettel',
      contactType: 'customer support',
      telephone: '+84 12032484',
    },
    {
      '@type': 'ContactPoint',
      areaServed: 'VN',
      availableLanguage: 'English',
      contactOption: 'VTI',
      contactType: 'customer support',
      telephone: '+84 12032487',
    },
  ],
  logo: 'https://static.nike.com/a/images/f_jpg,q_auto:eco/61b4738b-e1e1-4786-8f6c-26aa0008e80b/swoosh-logo-black.png',
  name: 'Nike',
  sameAs: [
    'https://www.wikidata.org/wiki/Q483915',
    'https://en.wikipedia.org/wiki/Nike,_Inc.',
    'https://www.youtube.com/user/nike',
    'https://www.linkedin.com/company/nike',
    'https://www.facebook.com/nike',
    'https://twitter.com/nike',
    'https://www.instagram.com/nike/',
  ],
  subOrganization: [
    {
      '@type': 'Organization',
      name: 'Converse',
      sameAs: [
        'https://www.wikidata.org/wiki/Q319515',
        'https://en.wikipedia.org/wiki/Converse_(shoe_company)',
        'https://www.youtube.com/channel/UCfigptVgX1LGgUKZkQ2BZMQ',
        'https://www.linkedin.com/company/converse',
        'https://www.facebook.com/converse/',
        'https://twitter.com/converse',
        'https://www.instagram.com/converse/',
      ],
      url: 'https://www.converse.com/',
    },
    {
      '@type': 'Organization',
      name: 'Jordan Brand',
      sameAs: [
        'https://www.wikidata.org/wiki/Q420953',
        'https://en.wikipedia.org/wiki/Air_Jordan',
        'https://www.youtube.com/channel/UCqnGgTN0ajGp0LaoKkyxAxA',
        'https://www.linkedin.com/company/jumpman23',
        'https://www.facebook.com/jumpman23/',
        'https://twitter.com/jumpman23',
        'https://www.instagram.com/jumpman23/',
      ],
      url: 'https://www.nike.com/us/en_us/c/jordan',
    },
  ],
  url: 'https://www.nike.com/',
};

export const webSiteData = {
  '@id': 'https://www.nike.com/#website',
  name: 'Nike',
  potentialAction: {
    '@type': 'SearchAction',
    'query-input': 'required name=search_term_string',
    target:
      'https://www.nike.com/w?q={search_term_string}&vst={search_term_string}&cp=91120261542_sea_',
  },
  url: 'https://www.nike.com/',
};
