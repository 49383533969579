import React from 'react';
import PropTypes from 'prop-types';
import { TEXT_TEMPLATE_TYPES } from '@nike/ciclp-redux-app';
import TextCard from '../../components/textCard';
import { PullQuoteCard } from '../../components/pullQuoteCard';
import {
  cardStyledTextShape,
  genericCardDataShape,
  actionButtonsShape,
  textLocationShape,
  colorThemeShape,
} from '../../shapes';

export const TextCardContainer = ({ templateType, ...rest }) => {
  return templateType === TEXT_TEMPLATE_TYPES.PULL_QUOTE ? (
    <PullQuoteCard {...rest} />
  ) : (
    <TextCard {...rest} />
  );
};

TextCardContainer.propTypes = {
  actionButtons: actionButtonsShape,
  analyticsItemId: PropTypes.string,
  backgroundColor: PropTypes.string,
  bodyProps: cardStyledTextShape,
  cardLinkCollectionId: PropTypes.string,
  cardLinkId: PropTypes.string,
  cardLinkMemberOnly: PropTypes.bool,
  cardLinkUrl: PropTypes.string,
  colorTheme: colorThemeShape,
  destinationType: PropTypes.string,
  openInNewTab: PropTypes.bool,
  properties: genericCardDataShape,
  subtitleProps: cardStyledTextShape,
  templateType: PropTypes.string,
  textLocation: textLocationShape,
  titleProps: cardStyledTextShape,
};
