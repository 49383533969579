import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'clsx';
import { css } from 'emotion';
import { selectors, CARD_TYPES, actions } from '@nike/ciclp-redux-app';
import { equals, is } from 'ramda';
import FourUp from './fourUp/fourUp';
import {
  gridChildrenSelector,
  gridEmptyDataSelector,
  gridContainerTypeSelector,
} from './gridSelectors';
import { Card } from '../card';
import { useIdentity } from '../identity';

import styles from './grid.styl';

/**
 * Main rendering logic of the grid component.
 * Given 'childrenData' map (@see gridChildrenSelector) will return
 * a 'mapper' function that would render the grid child element given its id
 * @param {Object} childrenData
 * @param {Object} params
 */
export const renderItemById = (childrenData, onError, params = {}) => {
  if (!childrenData) throw new Error('Unexpected invalid parameters.');
  // eslint-disable-next-line react/display-name
  return strItemId => {
    if (!strItemId) throw new Error('Unexpected invalid parameters.');
    const itemData = childrenData[strItemId];
    if (!itemData)
      throw new Error('Unexpected child item data not found error.');
    switch (itemData.mode) {
      case 'row':
        return (
          <GridRow
            key={strItemId}
            data={{ ...itemData, ...params }}
            childrenData={childrenData}
          />
        );
      case 'col':
        return (
          <GridColumn
            key={strItemId}
            data={{ ...itemData, ...params }}
            childrenData={childrenData}
          />
        );
      case 'block':
        if (!itemData.data && onError) {
          onError('No card id for layout item', {
            itemId: itemData.id,
          });
        }
        return itemData.data ? (
          <Card
            analyticsItemId={itemData.id}
            cardId={itemData.data}
            withH1={itemData.withH1}
            key={strItemId}
          />
        ) : null;
      default:
        throw new Error('Unexpected grid element mode');
    }
  };
};

export const GridColumn = ({ data, childrenData }) => (
  <div
    id={data.id}
    data-qa="grid-col"
    className={cx([
      'grid-col',
      'va-sm-t',
      `ncss-col-lg-${data.span.large}`,
      `ncss-col-md-${data.span.medium}`,
      `ncss-col-sm-${data.span.small}`,
      `ncss-col-lg-offset-${data.offset.large}`,
      `ncss-col-md-offset-${data.offset.medium}`,
      `ncss-col-sm-offset-${data.offset.small}`,
    ])}
  >
    {data.items.map(renderItemById(childrenData))}
  </div>
);

GridColumn.propTypes = {
  childrenData: PropTypes.any,
  data: PropTypes.shape({
    gutter: PropTypes.bool,
    id: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.string).isRequired,
    mode: PropTypes.oneOf(['col']).isRequired,
    offset: PropTypes.shape({
      large: PropTypes.number.isRequired,
      medium: PropTypes.number.isRequired,
      small: PropTypes.number.isRequired,
    }).isRequired,
    span: PropTypes.shape({
      large: PropTypes.number.isRequired,
      medium: PropTypes.number.isRequired,
      small: PropTypes.number.isRequired,
    }).isRequired,
  }).isRequired,
  position: PropTypes.shape({
    large: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    medium: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    small: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  }),
};

/**
 * Checks if row has required amount of children in the left and in the right column
 * @returns {boolean}
 */
export const checkRowColumnLength = (
  rowData,
  childrenData,
  firstColumnRequiredLength,
  secondColumnRequiredLength,
) => {
  if (!rowData || !childrenData || !rowData.items) {
    return false;
  }
  if (rowData.items.length !== 2) {
    return false;
  }

  const firstColumn = childrenData[rowData.items[0]];
  const secondColumn = childrenData[rowData.items[1]];

  const firstColumnLength = firstColumn?.items?.length ?? 0;
  const secondColumnLength = secondColumn?.items?.length ?? 0;

  return (
    firstColumnLength === firstColumnRequiredLength &&
    secondColumnLength === secondColumnRequiredLength
  );
};

export const GridRow = ({
  data,
  data: {
    attributes: { gutter },
  },
  childrenData,
}) => (
  <div
    id={data.id}
    data-qa="grid-row"
    className={cx([
      'ncss-row',
      'grid-row',
      { 'mt4-sm': data.attributes.gutter && data.topMargin },
      { triptych: checkRowColumnLength(data, childrenData, 1, 2) },
      { fourUp: checkRowColumnLength(data, childrenData, 2, 2) },
      !gutter ? 'withDisabledGutter' : '',
    ])}
  >
    {checkRowColumnLength(data, childrenData, 2, 2) ? (
      <FourUp parentId={data.id} />
    ) : (
      data.items.map(renderItemById(childrenData, undefined, { gutter }))
    )}
  </div>
);

GridRow.propTypes = {
  childrenData: PropTypes.any,
  data: PropTypes.shape({
    attributes: PropTypes.shape({
      gutter: PropTypes.bool.isRequired,
      header: PropTypes.bool,
    }).isRequired,
    id: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.string).isRequired,
    mode: PropTypes.oneOf(['row']).isRequired,
    topMargin: PropTypes.bool,
  }).isRequired,
  position: PropTypes.shape({
    large: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    medium: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    small: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  }),
};

const getGridContainerTypeClassName = containerType => {
  const mapContainerTypeToClassName = {
    [CARD_TYPES.LOCAL_NAV]: styles.localNavigationContainer,
    [CARD_TYPES.TEAM_SELECTOR]: styles.teamSelectorContainer,
    [CARD_TYPES.SIZE_CHARTS]: styles.sizeChartsContainer,
  };

  return mapContainerTypeToClassName?.[containerType] ?? '';
};

export const Grid = ({
  itemId,
  gridData,
  childrenData,
  isEmpty,
  hasScrollableContent,
  containerType,
  marginTop,
  dispatchError,
}) => {
  const opts = {};
  const isLoggedIn = useIdentity(global.webShellClient?.identity.getIsLoggedIn);

  if (gridData.renderCondition) {
    const loggedInRenderCondition =
      gridData.renderCondition.loggedIn || gridData.renderCondition.fallback;
    const loggedOutRenderCondition =
      gridData.renderCondition.loggedOut || gridData.renderCondition.fallback;

    if (!isLoggedIn && !loggedOutRenderCondition) {
      return null;
    }

    if (isLoggedIn && !loggedInRenderCondition) {
      return null;
    }
  }

  const handleError = (message, details) => {
    dispatchError({
      details,
      message,
      name: 'Grid rendering error',
    });
  };

  return (
    <div
      id={itemId}
      data-qa="grid"
      data-container-type={containerType}
      data-fluid-grid={gridData.attributes.fluid}
      className={cx([
        'grid',
        styles.grid,
        `${!gridData.attributes.fluid ? 'fixed-' : ''}fluid`,
        getGridContainerTypeClassName(containerType),
        {
          'ncss-container': !hasScrollableContent,
          [styles.gridFreeContainer]: hasScrollableContent,
          [styles.hideLarge]: !gridData.display.large,
          [styles.hideMedium]: !gridData.display.medium,
          [styles.hideSmall]: !gridData.display.small,
          [styles.isEmpty]: isEmpty,
        },
        is(Number, gridData.attributes.margin?.top?.mobile) &&
          css({
            '@media (max-width: 639px)': {
              marginTop: marginTop || gridData.attributes.margin.top.mobile,
            },
            marginTop: gridData.attributes.margin.top.mobile,
          }),
        is(Number, gridData.attributes.margin?.top?.desktop) &&
          css({
            '@media (min-width: 640px)': {
              marginTop: gridData.attributes.margin.top.desktop,
            },
          }),
      ])}
      style={{ position: 'relative' }}
      {...opts}
    >
      {gridData.items.map(renderItemById(childrenData, handleError))}
    </div>
  );
};

Grid.propTypes = {
  childrenData: PropTypes.any,
  containMoreThanOneChildInRow: PropTypes.bool,
  containerType: PropTypes.oneOf([...Object.values(CARD_TYPES), 'empty']),
  dispatchError: PropTypes.func,
  gridData: PropTypes.shape({
    attributes: PropTypes.shape({
      fluid: PropTypes.bool.isRequired,
      margin: PropTypes.shape({
        top: PropTypes.shape({
          desktop: PropTypes.number,
          mobile: PropTypes.number,
        }),
      }),
    }).isRequired,
    display: PropTypes.shape({
      large: PropTypes.bool.isRequired,
      medium: PropTypes.bool.isRequired,
      small: PropTypes.bool.isRequired,
    }).isRequired,
    id: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.string).isRequired,
    mode: PropTypes.oneOf(['grid']).isRequired,
    position: PropTypes.shape({
      large: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
      medium: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
      small: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    }),
    renderCondition: PropTypes.shape({
      fallback: PropTypes.bool,
      loggedIn: PropTypes.bool,
      loggedOut: PropTypes.bool,
    }),
  }).isRequired,
  hasScrollableContent: PropTypes.bool,
  isEmpty: PropTypes.bool,
  itemId: PropTypes.string.isRequired,
  marginTop: PropTypes.number,
};

Grid.defaultProps = {
  hasScrollableContent: false,
};

const mapStateToProps = (state, ownProps) => ({
  childrenData: gridChildrenSelector(state, ownProps),
  containerType: gridContainerTypeSelector(state, ownProps),
  gridData: selectors.layoutItemSelector(state, ownProps),
  hasScrollableContent: selectors.hasGridScrollableContentSelector(
    state,
    ownProps,
  ),
  isEmpty: gridEmptyDataSelector(state, ownProps),
});

const mapDispatchToProps = {
  dispatchError: actions.logActions.warn,
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  areStatePropsEqual: equals,
})(Grid);
