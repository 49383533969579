import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { PlayFilled } from '@nike/nike-design-system-icons';
import styles from './styles.styl';
import { Timestamp } from '../timestamp';
import { getPlayerInstance, canStartVideo } from '../../helpers';
import { CtaButtonDefaultComponent } from '../../../ctaButton/ctaButton';

const focusPlayer = player => {
  player && player.tech().el().focus();
};

export const playButtonProps = ({
  onClick,
  watchCtaButtonText,
  watchCtaButtonStyle,
}) => {
  return {
    Icon: <PlayFilled size="s" />,
    actionText: watchCtaButtonText,
    actionType: 'button',
    appearance: 'primary',
    ariaLabel: watchCtaButtonText,
    className: cx([watchCtaButtonStyle]),
    dataButtonType: 'play-button',
    handleClick: onClick,
    iconPlacement: 'end',
    renderAs: 'button',
  };
};

export const handlePlayButtonClick = forceFullscreenOnStart => e => {
  if (!canStartVideo(e.target)) {
    return;
  }
  e.preventDefault();
  const player = getPlayerInstance(e.target);
  const previewModule = player.preview();

  if (previewModule) {
    previewModule.stop();
    previewModule.disable();
  }
  if (forceFullscreenOnStart) {
    player.requestFullscreen();
  }
  player.muted(false);
  player.controls(true);
  if (player.currentTime()) {
    player.currentTime(0);
  }
  player.setState({ isInitiatedByUser: true });
  player.play().then(() => {
    focusPlayer(player);
  });
};

const handleNonPlayButtonClick = e => {
  const player = getPlayerInstance(e.target);

  focusPlayer(player);
};

export const PlayerOverlay = ({
  children,
  showPlayButton,
  duration,
  showTimestamp,
  isTextPositionAfter,
  showPlayerOverlay,
  imageHeightClass,
  watchCtaButtonText,
  watchCtaButtonStyle,
  timestampWithPlay,
  forceFullscreenOnStart,
}) => {
  const onClick = showPlayButton
    ? handlePlayButtonClick(forceFullscreenOnStart)
    : handleNonPlayButtonClick;

  const buttonProps = showPlayButton
    ? playButtonProps({
        onClick,
        watchCtaButtonStyle,
        watchCtaButtonText,
      })
    : {};

  const playButton =
    showPlayButton && !timestampWithPlay ? (
      <CtaButtonDefaultComponent {...buttonProps} />
    ) : null;

  const timestamp = showTimestamp ? (
    <Timestamp
      duration={duration}
      action={onClick}
      timestampWithPlay={timestampWithPlay}
    />
  ) : null;

  const childComponents = children
    ? children({ buttonProps, playButtonElement: playButton })
    : playButton;
  const hasPlayOverlayContent =
    Boolean(timestamp) || Boolean(!isTextPositionAfter && childComponents);

  return (
    <>
      {showPlayerOverlay && (
        <div
          data-preview-wrapper
          role={hasPlayOverlayContent ? 'button' : undefined}
          className={cx(
            styles.overlay,
            isTextPositionAfter ? imageHeightClass : styles.fullHeight,
            showPlayButton && styles.cursorPointer,
          )}
          data-qa="play-overlay"
          onClick={onClick}
        >
          {timestamp}
          {!isTextPositionAfter && childComponents}
        </div>
      )}
      {isTextPositionAfter && childComponents}
    </>
  );
};

PlayerOverlay.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  duration: PropTypes.string,
  forceFullscreenOnStart: PropTypes.bool,
  imageHeightClass: PropTypes.string,
  isTextPositionAfter: PropTypes.bool,
  showPlayButton: PropTypes.bool,
  showPlayerOverlay: PropTypes.bool,
  showTimestamp: PropTypes.bool,
  timestampWithPlay: PropTypes.bool,
  watchCtaButtonStyle: PropTypes.string,
  watchCtaButtonText: PropTypes.string,
};
