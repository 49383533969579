import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { CARD_TYPES } from '@nike/ciclp-redux-app';
import { LocalNavStyled } from '../../components/localNavNew';
import { LocalNav } from '../../components/localNav';
import { getElementBySelector, getHeader } from '../../context/utils';
import styles from './localNavWrapper.styl';
import NavigationLink from '../../components/navigationLink';

export const getLocationHref = () =>
  typeof window !== 'undefined' ? window.location.href : '';

export const Link = props => (
  <NavigationLink
    href={props.href}
    className={cx([styles.menuLink, props.isSelected && styles.selected])}
    data-analytics-action-id={props.id}
  >
    {props.text}
  </NavigationLink>
);

Link.propTypes = {
  href: PropTypes.string,
  id: PropTypes.string,
  isSelected: PropTypes.bool,
  text: PropTypes.string.isRequired,
};

const getTitleProps = props => ({
  className: !props.isNikeJournal && styles.titleClassName,
  renderAs: props.withH1 ? 'h1' : 'h2',
  text: props.title,
});

export const getItems = (items, locationHref) =>
  items.map(item => ({
    href: item.url,
    id: item.id,
    isSelected: locationHref === item.url,
    text: item.label,
  }));

export const LocalNavWrapper = props => {
  const [transformedItems, setTransformedItems] = useState(
    getItems(props.items, ''),
  );

  useEffect(() => {
    const localNavContainer = getElementBySelector(
      `div[data-container-type="${CARD_TYPES.LOCAL_NAV}"]`,
    );

    if (window.NikeDotcomNavReady) {
      // eslint-disable-next-line babel/new-cap
      window.NikeDotcomNavReady(() => {
        const header = getHeader();

        if (header && localNavContainer) {
          header.classList.add(styles.static);
        }
      });
    }

    return () => {
      const header = getHeader();

      if (header) {
        header.classList.remove(styles.static);
      }
    };
  }, []);

  useEffect(() => {
    setTransformedItems(getItems(props.items, getLocationHref()));
  }, [props.items]);

  const { items, ...rest } = props;
  const titleProps = getTitleProps(props);

  if (props.isNikeJournal) {
    return (
      <LocalNavStyled
        titleProps={{ ...titleProps }}
        items={transformedItems}
        LinkComponent={Link}
        withSticky
        showOnlySticky
        alignment="center"
        containerClassName={styles.localNavContainer}
        {...rest}
      />
    );
  }
  return <LocalNav {...props} />;
};

LocalNavWrapper.propTypes = {
  className: PropTypes.string,
  isNikeJournal: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string,
    }),
  ),
};
