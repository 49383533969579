import React from 'react';
import { Grid, GridItem } from '@nike/nike-design-system-components';
import PropTypes from 'prop-types';
import ContentSlide from '../contentSlide';
import SectionHeadline from '../sectionHeadline';
import styles from './contentGrid.styl';
import {
  gallerySlideShape,
  paginationShape,
  sectionHeadlineShape,
} from '../../shapes';
import PaginationWrapper from '../pagination';
import ContentFeatured from '../contentFeatured';

export const ContentGrid = ({
  featuredCard,
  slides = [],
  sectionHeadline,
  analyticsItemId,
  pagination,
  translations,
}) => {
  return (
    (slides.length >= 3 || pagination?.totalPages > 1) && (
      <div data-qa="dynamic-grid">
        {pagination?.totalPages !== 1 && (
          <div
            className={styles.anchorPoint}
            id={`content-grid-${analyticsItemId}`}
          />
        )}
        {!!sectionHeadline && (
          <SectionHeadline
            {...sectionHeadline}
            analyticsItemId={`${analyticsItemId}_0`}
            containerClassName={styles.headline}
            className={styles.headlineText}
          />
        )}
        {!!featuredCard && (
          <ContentFeatured
            {...featuredCard}
            analyticsItemId={`${analyticsItemId}_1`}
          />
        )}
        <Grid
          xs={{
            columnGap: '0px',
            rowGap: '60px',
          }}
          s={{
            columnGap: '16px',
            rowGap: '84px',
          }}
          m={{
            columnGap: '16px',
          }}
          className={styles.gridContainer}
        >
          {slides?.map((slide, index) => (
            <GridItem xs={12} s={6} m={4} key={slide.id}>
              <ContentSlide
                {...slide}
                analyticsItemId={`${analyticsItemId}_${
                  index + (featuredCard ? 2 : 1)
                }`}
              />
            </GridItem>
          ))}
        </Grid>
        {pagination?.totalPages !== 1 && (
          <PaginationWrapper
            {...pagination}
            anchor={`content-grid-${analyticsItemId}`}
            translations={translations}
          />
        )}
      </div>
    )
  );
};

ContentGrid.defaultProps = {
  pagination: {
    pageNumber: 1,
    totalPages: 1,
  },
  slides: [],
  translations: {},
};

ContentGrid.propTypes = {
  analyticsItemId: PropTypes.string,
  featuredCard: gallerySlideShape,
  pagination: PropTypes.shape(paginationShape),
  sectionHeadline: sectionHeadlineShape,
  slides: PropTypes.arrayOf(PropTypes.shape(gallerySlideShape)),
  translations: PropTypes.shape({}),
};
