import React, { useContext } from 'react';
import { BRAND_NAMES } from '@nike/ciclp-config';

import cx from 'clsx';

import {
  TextStyled,
  useBreakpoint,
  useMatchMedia,
} from '@nike/nike-design-system-components';

import { cardStyledTextProperties } from '../../shapes';
import { BrandContext } from '../../context';
import { createMarkup } from '../../helpers/text';

import {
  isEmptyTextString,
  getTextAppearance,
  fontStyleMapper,
} from './helpers';

export const BodyText = ({
  appearance,
  text,
  colorTheme,
  fontFamily,
  fontSize,
  fontStyle,
  textColor,
  customClassName,
  renderAs,
  fontSizeSet, // to avoid this being rendered as unrecognized HTML attribute
  ...props
}) => {
  const brand = useContext(BrandContext);
  const isLargeScreen = useMatchMedia(useBreakpoint(`gte`, `m`));

  const textAppearance =
    appearance ??
    getTextAppearance('body', brand, fontFamily, fontSize, isLargeScreen);

  const classNames = [customClassName];

  if (brand === BRAND_NAMES.NIKE_JOURNAL) {
    classNames.push('mt3-sm');
  }

  if (brand === BRAND_NAMES.NIKE) {
    classNames.push('mt6-sm');
  }

  if (isEmptyTextString(text)) {
    return null;
  }

  return (
    <TextStyled
      data-qa="body"
      Component={renderAs}
      appearance={textAppearance}
      className={cx(customClassName, 'mt3-sm', fontStyleMapper[fontStyle])}
      bold={fontStyle === 'medium'}
      color={textColor}
      dangerouslySetInnerHTML={createMarkup(text)}
      {...props}
    />
  );
};
BodyText.displayName = 'BodyText';
BodyText.propTypes = cardStyledTextProperties;
BodyText.defaultProps = {
  colorTheme: 'dark',
  fontFamily: 'base',
  fontSize: 'extra_small',
  fontStyle: 'regular',
  renderAs: 'p',
  text: null,
  textColor: null,
};
