import NikeOneVideoPlayer from '@nike/nike-one-video-player';

const BasePlugin = NikeOneVideoPlayer.getPlugin('plugin');

const runCallbacks =
  (...fns) =>
  data =>
    fns.forEach(fn => fn(data));

export class ReactAdapter extends BasePlugin {
  constructor(player, options) {
    super(player, options);
    this.options = options;

    this.assignListeners();
  }

  assignListeners = () => {
    this.options.eventsToObserve.forEach(eventName => {
      const callback = this.options.callbacks[eventName];
      this.player.on(
        eventName,
        callback
          ? runCallbacks(this.options.dispatchFn, callback)
          : this.options.dispatchFn,
      );
    });
    Object.keys(this.options.callbacks).forEach(eventName => {
      if (!this.options.eventsToObserve.includes(eventName)) {
        this.player.on(eventName, this.options.callbacks[eventName]);
      }
    });
  };
}
