export const addQueryParamToUrl = (originalUrl, paramName, value) => {
  try {
    const url = new URL(originalUrl);

    if (paramName) {
      const queryParams = new URLSearchParams(url.search);
      queryParams.set(paramName, value);
      url.search = queryParams.toString();
    }

    return url.toString();
  } catch {
    return '';
  }
};
