export const getImage = imageURL => ({
  '@type': 'ImageObject',
  url: imageURL,
});

const getHowToDirection = text => ({
  '@type': 'HowToDirection',
  text,
});

export const getHowToStep = ({ name, imageURL, text }) => ({
  '@type': 'HowToStep',
  name,
  ...(imageURL && { image: getImage(imageURL) }),
  ...(text && { itemListElement: [getHowToDirection(text)] }),
});

export const getMonetaryAmount = ({ currency, cost }) => ({
  '@type': 'MonetaryAmount',
  currency,
  value: cost?.toString(),
});

export const getHowToTool = name => ({
  '@type': 'HowToTool',
  name,
});

export const getHowToSupply = name => ({
  '@type': 'HowToSupply',
  name,
});

export const getCommonProperties = typeName => ({
  '@context': 'http://schema.org',
  '@type': typeName,
});

export const getScriptContent = ({ type, data }) =>
  data
    ? `<script type="application/ld+json" data-qa="${type}Script">
    ${JSON.stringify({
      ...getCommonProperties(type),
      ...data,
    })}
  </script>`
    : '';
