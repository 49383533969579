import { isWeb } from '@nike/ciclp-config';
import { install } from 'resize-observer';

export const isSafari = () => {
  if (!isWeb()) {
    return false;
  }
  const ua = navigator.userAgent.toLowerCase();
  if (ua.includes('chrome')) {
    return false;
  }
  if (ua.includes('safari')) {
    return true;
  }
  return false;
};

export const loadPolyfills = () => {
  if (isSafari()) {
    if (!window.ResizeObserver) {
      install();
    }
  }
};
