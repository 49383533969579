import React from 'react';
import { CAPTION_POSITIONS } from '@nike/ciclp-redux-app';
import CardOverlay from '../cardOverlay';
import { CardLink } from '../cardLink';
import { MultiCta } from '../ctaButton';
import { BodyText, CaptionText, SubtitleText, TitleText } from '../textOverlay';
import { isTextNotEmpty } from '../../helpers/mediaContent';

import styles from './filmstripSlide.styl';
import { gallerySlideShape } from '../../shapes';

export const SlideContent = ({
  cardLinkMemberOnly,
  textLocation,
  analyticsItemId,
  cardLinkCollectionId,
  destinationType,
  filmstripId,
  cardLinkVariations,
  colorTheme,
  ...slide
}) => {
  let caption;
  const shouldRenderCaption =
    slide.actionButtons?.length > 0 ||
    slide.bodyProps.text ||
    slide.subtitleProps.text ||
    slide.titleProps.text;
  if (shouldRenderCaption) {
    const ctaButtons = (
      <MultiCta
        dataQa={slide.id}
        actionButtons={slide.actionButtons}
        isMenuOnTop={slide.isTextBelowImage}
        filmstripId={filmstripId}
        colorTheme={colorTheme}
        addTopSpacing={isTextNotEmpty(
          slide.bodyProps.text,
          slide.subtitleProps.text,
          slide.titleProps.text,
        )}
      />
    );

    caption = slide.isTextBelowImage ? (
      <div className={styles.slideTextWrapper} data-qa="fig-caption">
        {slide.subtitleProps.text && <SubtitleText {...slide.subtitleProps} />}
        {slide.titleProps.text && (
          <TitleText {...slide.titleProps} appearance="title4" />
        )}
        {slide.bodyProps.text && <BodyText {...slide.bodyProps} />}
        {ctaButtons}
      </div>
    ) : (
      <CardOverlay renderAs="div" position={textLocation}>
        <SubtitleText {...slide.subtitleProps} />
        <TitleText {...slide.titleProps} />
        <BodyText {...slide.bodyProps} />
        {ctaButtons}
      </CardOverlay>
    );
  }

  return (
    <>
      {slide?.captionProps?.position === CAPTION_POSITIONS.BELOW && (
        <CaptionText colorTheme={colorTheme} />
      )}
      {caption}
      {slide.cardLinkId && (
        <CardLink
          actionId={slide.cardLinkId}
          analyticsItemId={analyticsItemId}
          collectionId={cardLinkCollectionId}
          destinationType={destinationType}
          filmstripId={filmstripId}
          memberOnly={cardLinkMemberOnly}
          destinationUrl={slide.cardLinkUrl}
          openInNewTab={slide.openInNewTab}
          cardLinkVariations={cardLinkVariations}
          renderAs="a"
          ariaLabel={`${slide.subtitleProps?.text} ${slide.titleProps?.text}`}
          tabbable={!slide.actionButtons?.length}
        />
      )}
    </>
  );
};

SlideContent.propTypes = gallerySlideShape;
