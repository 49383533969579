import React, { useContext } from 'react';
import cx from 'clsx';
import { BRAND_NAMES } from '@nike/ciclp-config';

import { TextStyled } from '@nike/nike-design-system-components';
import styles from './textOverlay.styl';

import { cardStyledTextProperties } from '../../shapes';
import { BrandContext } from '../../context';
import { createMarkup } from '../../helpers/text';
import { isEmptyTextString } from './helpers';

export const CaptionText = ({ text, position, textColor }) => {
  const brand = useContext(BrandContext);
  const appearance = brand === BRAND_NAMES.JORDAN ? 'body3' : 'body3Strong';

  if (isEmptyTextString(text)) {
    return null;
  }
  return (
    <div data-qa="caption" className={cx([styles.caption, position])}>
      <TextStyled
        Component="p"
        appearance={appearance}
        color={textColor}
        dangerouslySetInnerHTML={createMarkup(text)}
      />
    </div>
  );
};
CaptionText.displayName = 'CaptionText';
CaptionText.propTypes = cardStyledTextProperties;

CaptionText.defaultProps = {
  colorTheme: 'dark',
  text: null,
  textColor: null,
};
