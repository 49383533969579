import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  SwitchStyled,
  SwitchOptionStyled,
} from '@nike/nike-design-system-components';
import {
  ANALYTICS_TAB_INDEXES,
  PRODUCT_AVAILABILITY,
  actions,
} from '@nike/ciclp-redux-app';
import { ProductContainer } from '../productContainer';
import ProductSnkrsSlide from '../productSnkrsSlide';
import { productContainerShape } from '../../shapes';

const snkrsSlidesMap = {
  [PRODUCT_AVAILABILITY.IN_STOCK]: 'slides',
  [PRODUCT_AVAILABILITY.UPCOMING]: 'slidesUpcoming',
};

export const SnkrsTabsContainer = props => {
  const [activeSnkrsTab, setActiveSnkrsTab] = useState(
    PRODUCT_AVAILABILITY.IN_STOCK,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.impressionObserverActions.init());
  }, [activeSnkrsTab, dispatch]);

  const hasUpcomingSlides = props.slidesUpcoming?.length > 0;
  const handleToggleSelect = (_, selectedValue) => {
    setActiveSnkrsTab(selectedValue);
  };

  // This reproduces logic of getProductContainerPlacements (packages/ciclp-redux-app/src/services/transforms/helpers/analytics.js:111)
  // to generate slides analytics id with/without tab index prefix
  const placementGroupIndex = hasUpcomingSlides
    ? ANALYTICS_TAB_INDEXES[activeSnkrsTab]
    : undefined;

  const Toggle = hasUpcomingSlides ? (
    <SwitchStyled
      onClick={handleToggleSelect}
      name="snkrs-switch"
      a11ySwitchDescription={props.labels.snkrs_drops_a11y_switch_desc}
    >
      <SwitchOptionStyled
        id={PRODUCT_AVAILABILITY.IN_STOCK}
        value={PRODUCT_AVAILABILITY.IN_STOCK}
        label={props.labels.in_stock}
      />
      <SwitchOptionStyled
        id={PRODUCT_AVAILABILITY.UPCOMING}
        value={PRODUCT_AVAILABILITY.UPCOMING}
        label={props.labels.upcoming}
      />
    </SwitchStyled>
  ) : null;

  return (
    <ProductContainer
      SlideComponent={ProductSnkrsSlide}
      {...props}
      ToggleComponent={Toggle}
      slides={props[snkrsSlidesMap[activeSnkrsTab]]}
      placementGroupIndex={placementGroupIndex}
      activeSnkrsTab={activeSnkrsTab}
    />
  );
};

SnkrsTabsContainer.propTypes = {
  ...productContainerShape,
  analyticsItemId: PropTypes.string,
  localeForCurrency: PropTypes.string,
};

SnkrsTabsContainer.defaultProps = {
  slides: [],
  slidesUpcoming: [],
};
