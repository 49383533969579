import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { CARD_ACTION_TYPES } from '@nike/ciclp-redux-app';
import { ButtonStyled } from '@nike/nike-design-system-components';
import { Loading, Lock, Unlock } from '@nike/nike-design-system-icons';
import withMemberOnly, { withMemberOnlyShape } from './withMemberOnly';
import { colorThemeShape } from '../../shapes';
import NavigationLink from '../navigationLink';

import styles from './ctaButton.styl';

const getIcon = (
  { Icon, memberOnly, isMemberOnlyNotLogged },
  isButtonPlaceholder,
) => {
  if (!memberOnly) {
    return Icon;
  }

  if (isButtonPlaceholder) {
    return <Loading className={styles.rotatingIcon} />;
  }

  return isMemberOnlyNotLogged ? <Lock /> : <Unlock />;
};

const getLinkProps = ({ renderAs, getUrl, destinationId, openInNewTab }) => {
  const isButtonElement = renderAs === 'button';
  if (isButtonElement) {
    return {};
  }

  const href = getUrl(destinationId);
  return {
    href,
    target: openInNewTab ? '_blank' : '_self',
    ...(openInNewTab && { rel: 'noopener' }),
  };
};

// eslint-disable-next-line import/prefer-default-export
export const CtaButtonDefaultComponent = props => {
  const {
    id,
    appearance,
    actionText,
    actionType,
    allowAnalytics,
    ariaExpanded,
    ariaLabel,
    className,
    colorTheme,
    dataQa,
    disabled,
    renderAs,
    handleClick,
    getActionText,
    memberOnly,
    buttonStyle,
    countryCode,
    dataButtonType,
    children,
    iconPlacement,
    isButtonType,
    showPlaceholder,
    type,
  } = props;
  const isTextLink = actionType === CARD_ACTION_TYPES.LINK;
  const actionTypeSpecificClasses = isTextLink
    ? [styles.ctaTextLink]
    : [styles.ctaButton];
  const buttonActionText = getActionText(actionText, isButtonType);
  const isButtonPlaceholder = isButtonType && showPlaceholder;
  const icon = getIcon(props, isButtonPlaceholder);
  const linkProps = getLinkProps(props);
  const labelText = ariaLabel || buttonActionText;

  return (
    <ButtonStyled
      appearance={!appearance && isTextLink ? 'textUnderline' : appearance}
      aria-expanded={ariaExpanded}
      ariaLabel={labelText}
      background={colorTheme === 'light' ? 'dark' : 'light'}
      className={cx(
        'inline-flx-btn',
        styles.ctaItem,
        ...actionTypeSpecificClasses,
        className,
        buttonStyle && styles[buttonStyle],
        styles[countryCode],
        colorTheme === 'light' || isTextLink
          ? styles.ctaTextOnLight
          : styles.ctaTextOnDark,
      )}
      Component={renderAs}
      data-actiontext={buttonActionText}
      data-analytics-action-id={id}
      {...(memberOnly && {
        'data-analytics-click-activity-text': buttonActionText,
      })}
      data-analytics-allow={allowAnalytics}
      data-button-type={dataButtonType || actionType}
      data-qa={isTextLink ? 'cta-text-link' : 'cta-button'}
      {...(dataQa && { 'data-qa-card-id': dataQa })}
      disabled={Boolean(disabled || isButtonPlaceholder)}
      Icon={icon}
      iconPlacement={memberOnly ? 'start' : iconPlacement}
      onClick={handleClick}
      size={isTextLink ? 'large' : 'small'}
      tabIndex="0"
      type={type}
      {...linkProps}
    >
      {children || buttonActionText}
    </ButtonStyled>
  );
};

CtaButtonDefaultComponent.propTypes = {
  ...withMemberOnlyShape,
  Icon: PropTypes.oneOf([PropTypes.string, PropTypes.element]),
  actionText: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
  actionType: PropTypes.oneOf(Object.values(CARD_ACTION_TYPES)),
  allowAnalytics: PropTypes.bool,
  appearance: PropTypes.oneOf([
    'primary',
    'secondary',
    'text',
    'textUnderline',
  ]),
  ariaExpanded: PropTypes.bool,
  ariaLabel: PropTypes.string,
  buttonStyle: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  colorTheme: colorThemeShape,
  countryCode: PropTypes.string,
  dataButtonType: PropTypes.string,
  dataQa: PropTypes.string,
  destinationId: PropTypes.string,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func,
  iconPlacement: PropTypes.oneOf(['start', 'end']),
  id: PropTypes.string,
  openInNewTab: PropTypes.bool,
  renderAs: PropTypes.oneOf(['a', 'button', NavigationLink]),
  type: PropTypes.oneOf(['submit', 'button', 'reset']),
};

CtaButtonDefaultComponent.defaultProps = {
  actionType: CARD_ACTION_TYPES.BUTTON,
  allowAnalytics: false,
  colorTheme: 'dark',
  disabled: false,
  getActionText: text => text,
  getUrl: url => (!url ? '#' : url),
  openInNewTab: false,
  renderAs: NavigationLink,
  type: 'button',
};

export const CtaButton = withMemberOnly(CtaButtonDefaultComponent);
CtaButton.displayName = 'CtaButton';
CtaButton.propTypes = CtaButtonDefaultComponent.propTypes;
