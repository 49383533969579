import cx from 'clsx';
import styles from './stackedCta.styl';

export const css = (isMenuOnTop, { horizontal, vertical } = {}) =>
  cx([
    styles[`stackedCtaMenu-h_${horizontal}`],
    styles[`stackedCtaMenu-v_${isMenuOnTop ? 'before' : vertical}`],
  ]);

const ANIMATION_DELAY_STEP = 25;
const getListItemAnimationDelayInMs = (
  baseDelay,
  itemIndex,
  step = ANIMATION_DELAY_STEP,
) => baseDelay + itemIndex * step;
const getTransitionCssValue = ({ cssProp, duration, timingFunc, delay }) =>
  `${cssProp} ${duration}ms ${timingFunc} ${delay}ms`;
const transform = (duration, delay) =>
  getTransitionCssValue({
    cssProp: 'transform',
    delay,
    duration,
    timingFunc: 'ease',
  });
const opacity = (duration, delay) =>
  getTransitionCssValue({
    cssProp: 'opacity',
    delay,
    duration,
    timingFunc: 'ease',
  });

export const getAnimationStartCssProps = ({ horizontal }, itemIndex) => {
  const startEndTransition = `${opacity(
    800,
    getListItemAnimationDelayInMs(100, itemIndex),
  )}, ${transform(2000, getListItemAnimationDelayInMs(200, itemIndex))}`;

  switch (horizontal) {
    case 'start':
      return {
        opacity: 0,
        transform: 'translateX(-300%)',
        transition: startEndTransition,
      };
    case 'end':
      return {
        opacity: 0,
        transform: 'translateX(300%)',
        transition: startEndTransition,
      };
    case 'center':
      return {
        opacity: 0,
        transition: opacity(200, getListItemAnimationDelayInMs(100, itemIndex)),
      };
    default:
      return {};
  }
};

export const getAnimationEndCssProps = ({ horizontal }, itemIndex) => {
  switch (horizontal) {
    case 'start':
    case 'end':
      return {
        opacity: 1,
        transform: 'translateX(0)',
        transition: `${transform(
          600,
          getListItemAnimationDelayInMs(200, itemIndex),
        )}, ${opacity(950, getListItemAnimationDelayInMs(400, itemIndex))}`,
      };
    case 'center':
      return {
        opacity: 1,
        transition: opacity(
          2000,
          getListItemAnimationDelayInMs(100, itemIndex),
        ),
      };
    default:
      return {};
  }
};
