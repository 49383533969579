export const getExperimentEventKey = (optimizelyConfig, experimentId) => {
  return optimizelyConfig.events.find(event =>
    event.experimentIds.includes(experimentId),
  )?.key;
};

export const getExperimentParameters = (
  optimizelyConfig,
  experimentName,
  activeVariation,
  fallbackVariation,
) => {
  if (!activeVariation) {
    return {
      activeVariation: fallbackVariation,
      experimentName,
      isFallbackVariation: true,
    };
  }
  const { id, variationsMap } = optimizelyConfig.experimentsMap[experimentName];
  const eventKey = getExperimentEventKey(optimizelyConfig, id);

  return {
    activeVariation,
    analyticsKey: `${id}:${variationsMap[activeVariation].id}`,
    eventKey,
    experimentName,
  };
};

export const getActiveVariationFromOptimizely = ({
  optimizely,
  experimentName,
  userId,
  attributes,
  isAbleToPersonalize,
  forcedVariations,
}) => {
  const forcedVariation = forcedVariations?.[experimentName];
  if (forcedVariation) {
    const isVariationSet = optimizely.setForcedVariation(
      experimentName,
      userId,
      forcedVariation,
    );
    return isVariationSet ? forcedVariation : null;
  }
  if (isAbleToPersonalize) {
    return optimizely.activate(experimentName, userId, attributes);
  }
  return null;
};

export const canTrackOptimizelyEvent = ({
  experiment,
  isReadyForExperimentation,
}) => isReadyForExperimentation && !experiment?.isFallbackVariation;
