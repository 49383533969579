import React from 'react';
import PropTypes from 'prop-types';
import styles from '../dynamicCarousel.styl';
import SectionHeadline from '../../sectionHeadline';
import { sectionHeadlineShape } from '../../../shapes';

export const DynamicTwoInRow = ({
  sectionHeadline,
  headlineAnalyticsId,
  filmstripSlides,
  hasFeaturedCard,
}) => {
  return (
    <div className={styles.twoInRowWrapper}>
      {!hasFeaturedCard && (
        <SectionHeadline
          {...sectionHeadline}
          analyticsItemId={headlineAnalyticsId}
          className={styles.headlineText}
        />
      )}
      <div className={styles.twoInRow}>{filmstripSlides}</div>
    </div>
  );
};

DynamicTwoInRow.propTypes = {
  analyticsItemId: PropTypes.string,
  filmstripSlides: PropTypes.arrayOf(PropTypes.node),
  hasFeaturedCard: PropTypes.bool,
  headlineAnalyticsId: PropTypes.string,
  sectionHeadline: sectionHeadlineShape,
};
