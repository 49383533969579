import React, { useContext } from 'react';
import cx from 'clsx';

import { TextStyled } from '@nike/nike-design-system-components';

import { cardStyledTextProperties } from '../../shapes';
import { BrandContext } from '../../context';
import { createMarkup } from '../../helpers/text';

import styles from './textOverlay.styl';

import {
  isEmptyTextString,
  getTextAppearance,
  fontStyleMapper,
} from './helpers';

export const SubtitleText = ({
  appearance,
  text,
  fontFamily,
  fontSize,
  fontStyle,
  textColor,
  customClassName,
  renderAs,
}) => {
  const brand = useContext(BrandContext);
  const textAppearance =
    appearance ?? getTextAppearance('subtitle', brand, fontFamily, fontSize);

  if (isEmptyTextString(text)) {
    return null;
  }
  return (
    <TextStyled
      Component={renderAs}
      data-qa="subtitle"
      appearance={textAppearance}
      className={cx(
        customClassName,
        fontStyleMapper[fontStyle],
        styles.subtitle,
      )}
      color={textColor}
      dangerouslySetInnerHTML={createMarkup(text)}
    />
  );
};
SubtitleText.displayName = 'Subtitle';
SubtitleText.propTypes = cardStyledTextProperties;

SubtitleText.defaultProps = {
  colorTheme: 'dark',
  fontFamily: 'base',
  fontSize: 'extra_small',
  fontStyle: 'regular',
  renderAs: 'p',
  text: null,
  textColor: null,
};
