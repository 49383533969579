import { isWeb } from '@nike/ciclp-config';
import { addQueryParamToUrl } from './addQueryParamToUrl';

export const setQueryParam = (paramName, value) => {
  if (!isWeb() || !paramName) {
    return;
  }

  const newUrl = addQueryParamToUrl(window.location.href, paramName, value);
  window.history.replaceState({ path: newUrl }, '', newUrl);
};
