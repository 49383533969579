import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { TextStyled } from '@nike/nike-design-system-components';
import NavigationLink from '../navigationLink';
import { TitleText } from '../textOverlay';

import styles from './sectionHeadline.styl';

const renderLink = (action, titleId) => (
  <NavigationLink
    data-analytics-action-id={action.id}
    className={cx('btn-lg', styles.link)}
    href={action.destinationId}
    target={action?.openInNewTab ? '_blank' : '_self'}
    aria-describedby={titleId}
  >
    {action.actionText}
  </NavigationLink>
);

const getAction = actions => {
  if (!actions || actions.length === 0 || !actions[0].destinationId) {
    return null;
  }

  return actions[0];
};

// As per Leo's request, not using cta button even if defined
export const SectionHeadline = ({
  analyticsItemId,
  actions,
  title,
  subtitle,
  withH1,
  className,
  containerClassName,
  textLocation,
  titleProps,
  ToggleComponent,
}) => {
  if (!title) {
    return null;
  }
  const { fontFamily = 'base', fontSize, fontStyle, textColor } = titleProps;
  const action = getAction(actions);
  const isToggleComponentExists = !!ToggleComponent;
  const alignmentStyle = styles[`title-align-${textLocation.horizontal}`];
  const titleId = `title-${analyticsItemId}`;

  return (
    <>
      {subtitle && (
        <TextStyled Component="p" appearance="title4" className="mb1-sm">
          {subtitle}
        </TextStyled>
      )}
      <div
        data-analytics-placement-id={analyticsItemId}
        data-qa="section-headline"
        className={cx(styles.headline, containerClassName)}
      >
        <div
          className={cx(styles.sectionHeadlineContent, {
            [styles.sectionHeadlineContentWithActions]:
              isToggleComponentExists || action,
          })}
        >
          <TitleText
            text={title}
            customClassName={cx(
              {
                [styles.sectionHeadlineTitle]: isToggleComponentExists,
              },
              alignmentStyle,
              styles.copy,
              className,
            )}
            fontFamily={fontFamily}
            fontSize={fontSize}
            textColor={textColor}
            fontStyle={fontStyle}
            renderAs={withH1 ? 'h1' : 'h2'}
            id={titleId}
          />
          {action && renderLink(action, titleId)}
        </div>
        {isToggleComponentExists && (
          <div className={styles.toggleWrapper}>{ToggleComponent}</div>
        )}
      </div>
    </>
  );
};

SectionHeadline.propTypes = {
  ToggleComponent: PropTypes.element,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      actionText: PropTypes.string.isRequired,
      actionType: PropTypes.string.isRequired,
      destinationId: PropTypes.string.isRequired,
      destinationType: PropTypes.string,
    }),
  ),
  analyticsItemId: PropTypes.string,
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  subtitle: PropTypes.string,
  textLocation: PropTypes.shape({
    horizontal: PropTypes.string,
    vertical: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
  titleProps: PropTypes.shape({
    colorTheme: PropTypes.string,
    fontFamily: PropTypes.string,
    fontSize: PropTypes.string,
    fontStyle: PropTypes.string,
    textColor: PropTypes.string,
  }),
  withH1: PropTypes.bool,
};

SectionHeadline.defaultProps = {
  actions: [],
  textLocation: {},
  titleProps: {},
};

export default SectionHeadline;
