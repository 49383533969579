import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'debounce';

import { isPortraitView } from '../../context/utils';

import { getResolvedImgUrl, getMediaFromRef } from './helpers';

// Return new url to render if width increases by more than 15%
// or the orientation of the page changes
const WIDTH_THRESHOLD = 1.15;

const getResizeObserverCallback =
  ({ portraitURL, landscapeURL, setResolvedUrl }) =>
  (entries, observer, forceUpdateUrl = false) => {
    entries.forEach(entry => {
      if (!entry.contentRect.width) {
        return;
      }

      const isPortrait = isPortraitView();

      const elWidth = Math.floor(entry.contentRect.width);
      const elHeight = Math.floor(entry.contentRect.height);
      const prevWidth = Number(entry.target.dataset.requestedWidth) || 0;
      const prevIsPortrait =
        entry.target.dataset.isPortraitImageRequested === 'true';

      const shouldRequestImage =
        elWidth / prevWidth > WIDTH_THRESHOLD || prevIsPortrait !== isPortrait;

      if (!shouldRequestImage && !forceUpdateUrl) {
        return;
      }

      const mediaElement = getMediaFromRef(entry.target);
      const isImageElement = mediaElement?.hasAttributes(
        'data-landscape-url',
        'data-portrait-url',
      );
      if (isImageElement && mediaElement.dataset?.lcpLoaded) {
        mediaElement.dataset.lcpLoaded = 'false';
      }

      entry.target.dataset.requestedWidth = `${elWidth}`;
      entry.target.dataset.requestedHeight = `${elHeight}`;
      entry.target.dataset.isPortraitImageRequested = `${isPortrait}`;

      setResolvedUrl(
        getResolvedImgUrl({
          dimensionType: { isPortrait },
          height: elHeight,
          imageRef: entry.target,
          landscapeURL,
          portraitURL,
          width: elWidth,
        }),
      );
    });
  };

export const CloudinaryImage = ({
  children,
  portraitURL,
  landscapeURL,
  ...props
}) => {
  const [resolvedUrl, setResolvedUrl] = useState(null);
  const imageContainerRef = useRef(null);

  useEffect(() => {
    const resizeObserverCallback = getResizeObserverCallback({
      landscapeURL,
      portraitURL,
      setResolvedUrl,
    });
    const debouncedObserverCallback = debounce(resizeObserverCallback, 100);
    const resizeObserver = new ResizeObserver(debouncedObserverCallback);
    resizeObserver.observe(imageContainerRef.current);

    const entry = {
      contentRect: {
        height: imageContainerRef.current.clientHeight,
        width: imageContainerRef.current.clientWidth,
      },
      target: imageContainerRef.current,
    };

    resizeObserverCallback([entry], resizeObserver, true);

    return () => {
      resizeObserver.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portraitURL, landscapeURL]);

  return (
    <div
      style={{ height: '100%', width: '100%' }}
      {...props.dataAttrs}
      ref={imageContainerRef}
    >
      {children(resolvedUrl)}
    </div>
  );
};

CloudinaryImage.propTypes = {
  children: PropTypes.func.isRequired,
  dataAttrs: PropTypes.shape({
    'data-qa': PropTypes.string,
  }),
  landscapeURL: PropTypes.string.isRequired,
  portraitURL: PropTypes.string.isRequired,
};
