export const isNotEmpty = v => Boolean(v);
export const isTextNotEmpty = (body, subtitle, title) =>
  (isNotEmpty(body) && body !== '<p></p>') ||
  isNotEmpty(subtitle) ||
  isNotEmpty(title);

export const isCardOverlayNeeded = ({
  titleProps,
  subtitleProps,
  bodyProps,
  actionButtons,
}) => {
  const hasTextContent = isTextNotEmpty(
    bodyProps?.text,
    subtitleProps?.text,
    titleProps?.text,
  );
  const hasActionButtons = actionButtons?.length !== 0;
  return hasTextContent || hasActionButtons;
};

export const isTextPositionAfter = textLocation =>
  textLocation?.vertical === 'after';
