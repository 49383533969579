import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { CARD_TYPES } from '@nike/ciclp-redux-app';
import { groupWith } from 'ramda';
import { Card } from '../card';
import SectionHeadline from '../sectionHeadline';
import styles from './listicle.styl';
import { gallerySlideShape, sectionHeadlineShape } from '../../shapes';

export const Listicle = ({ slides, analyticsItemId, sectionHeadline }) => {
  const groups = groupWith(
    (slideA, slideB) => !slideB.isListicleHeadline,
    slides,
  );
  let counter = 0;
  return (
    <>
      <SectionHeadline
        {...sectionHeadline}
        analyticsItemId={`${analyticsItemId}_0`}
        className="headline-2"
      />
      <ol className={styles.listicle}>
        {groups.map(group => {
          const [listicleHeadline] = group;
          return (
            <li key={listicleHeadline.id} className={styles.listicleItem}>
              {group.map(item => (
                <div
                  className={cx(styles.listicleItemContent, {
                    [styles.isTextCard]: item.containerType === CARD_TYPES.TEXT,
                    [styles.isImage]: item.containerType === CARD_TYPES.IMAGE,
                  })}
                  key={item.id}
                >
                  {item.isListicleHeadline ? (
                    <h3 className="headline-3">
                      <span className={styles.counter}>{item.counter}.</span>
                      {item.title}
                    </h3>
                  ) : (
                    <Card
                      cardId={item.id}
                      cardData={item}
                      analyticsItemId={`${analyticsItemId}_${++counter}`}
                    />
                  )}
                </div>
              ))}
            </li>
          );
        })}
      </ol>
    </>
  );
};

Listicle.propTypes = {
  analyticsItemId: PropTypes.string,
  sectionHeadline: sectionHeadlineShape,
  slides: PropTypes.arrayOf(
    PropTypes.oneOf([
      PropTypes.shape({
        counter: PropTypes.number.isRequired,
        id: PropTypes.string.isRequired,
        isListicleHeadline: PropTypes.bool.isRequired,
        title: PropTypes.string.isRequired,
      }),
      PropTypes.shape(gallerySlideShape),
    ]).isRequired,
  ),
};
