import React from 'react';
import styled from '@emotion/styled';
import { LocalNav } from './LocalNav';
import * as style from './style';

export const LocalNavStyledComps = props => {
  return <LocalNav {...props} />;
};

// eslint-disable-next-line no-template-curly-in-string
export const LocalNavStyled = styled(LocalNavStyledComps)`
  ${style.baseStyles}
`;
