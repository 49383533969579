import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { breakpoints } from '@nike/nike-design-tokens';
import { TextStyled } from '@nike/nike-design-system-components';
import styles from './titleCard.styl';
import { tShirtSize } from '../../shapes';
import GlyphRenderer from './glyphRenderer';
import { TitleMeta } from './titleMeta';

const { podiumCdsBreakpointM } = breakpoints;

const titleStyles = ({ bottomMargin }) =>
  css`
    margin-top: var(--podium-cds-size-spacing-xxl);
    margin-bottom: var(--podium-cds-size-spacing-${bottomMargin});
    @media only screen and (min-width: ${podiumCdsBreakpointM}) {
      margin-top: var(--podium-cds-size-spacing-xxxl);
    }
  `;
const TitleWrapper = styled.div(titleStyles);

export const TitleCard = ({
  title,
  glyph,
  glyphSize,
  isArticle,
  lastUpdated,
  lastUpdatedStamp,
  lastUpdatedTemplate,
  readTime,
  readTimeTemplate,
  subTitle,
  body,
  bottomMargin,
  withH1,
  authors,
}) => {
  const articleProps = isArticle
    ? {
        itemScope: true,
        itemType: 'https://schema.org/Article',
      }
    : {};
  return (
    <TitleWrapper
      bottomMargin={bottomMargin}
      className={cx(styles.titleWrapper, {
        [styles.withLogo]: !!glyph,
      })}
      data-container="title"
      {...articleProps}
    >
      {glyph ? (
        <GlyphRenderer glyph={glyph} size={glyphSize} title={title} />
      ) : (
        <TextStyled
          data-qa="title"
          itemProp="headline"
          className={styles.title}
          Component={withH1 ? 'h1' : 'div'}
          appearance="title2"
        >
          {title}
        </TextStyled>
      )}
      {!!subTitle && (
        <TextStyled
          Component="h5"
          appearance={glyph ? 'title4' : 'body1'}
          data-qa="subtitle"
          className={cx({
            'pb1-sm': !!glyph,
            [styles.noGlyphSubtitle]: !glyph,
          })}
          color={!glyph && 'secondary'}
        >
          {subTitle}
        </TextStyled>
      )}
      {!!body && (
        <TextStyled
          appearance={isArticle ? 'editorialBody1' : 'body1'}
          className="mt6-sm"
        >
          {body}
        </TextStyled>
      )}
      {isArticle && (
        <TitleMeta
          authors={authors}
          lastUpdated={lastUpdated}
          lastUpdatedStamp={lastUpdatedStamp}
          lastUpdatedTemplate={lastUpdatedTemplate}
          readTime={readTime}
          readTimeTemplate={readTimeTemplate}
        />
      )}
    </TitleWrapper>
  );
};

TitleCard.propTypes = {
  authors: PropTypes.shape({
    profiles: PropTypes.array,
    roleTitle: PropTypes.string,
  }),
  body: PropTypes.string,
  bottomMargin: tShirtSize,
  glyph: PropTypes.string,
  glyphSize: PropTypes.string,
  isArticle: PropTypes.bool,
  lastUpdated: PropTypes.string,
  lastUpdatedStamp: PropTypes.string,
  lastUpdatedTemplate: PropTypes.string,
  readTime: PropTypes.number,
  readTimeTemplate: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  withH1: PropTypes.bool,
};

TitleCard.defaultProps = {
  bottomMargin: 'xl',
  isArticle: false,
  withH1: false,
};
