import React from 'react';
import PropTypes from 'prop-types';
import { TextStyled } from '@nike/nike-design-system-components';

export const TitleComponentBase = ({ text, renderAs, className, ...props }) => {
  return (
    <TextStyled
      className={className}
      appearance="body1Strong"
      Component={renderAs}
      color="primary"
      weight="medium"
      {...props}
    >
      {text}
    </TextStyled>
  );
};

TitleComponentBase.propTypes = {
  className: PropTypes.string,
  renderAs: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  text: PropTypes.string,
};

TitleComponentBase.defaultProps = {
  renderAs: 'h2',
};

export const LinkComponentBase = ({
  text,
  appearance,
  renderAs,
  className,
  ...props
}) => {
  return (
    <TextStyled
      className={className}
      appearance={appearance}
      Component={renderAs || (props?.href ? 'a' : 'span')}
      color="primary"
      weight="medium"
      {...props}
    >
      {text}
    </TextStyled>
  );
};

LinkComponentBase.propTypes = {
  appearance: PropTypes.string,
  className: PropTypes.string,
  href: PropTypes.string,
  renderAs: PropTypes.element,
  text: PropTypes.string.isRequired,
};

LinkComponentBase.defaultProps = {
  appearance: 'heading5',
};
