import React from 'react';
import { CARD_TYPES } from '@nike/ciclp-redux-app';

import cx from 'clsx';
import styles from './filmstripSlide.styl';
import { gallerySlideShape } from '../../shapes';
import { FilmstripVideoContent } from './filmstripVideoContent';
import { FilmstripImageMedia } from './filmstripImageMedia';
import { getTextLocation } from '../videoMedia';

export const FilmstripSlide = ({ analyticsItemId, ...slideProps }) => {
  const isVideoCard = slideProps.containerType === CARD_TYPES.VIDEO;
  const textLocation = getTextLocation(slideProps);
  const ContentComponent = isVideoCard
    ? FilmstripVideoContent
    : FilmstripImageMedia;

  return (
    <figure
      data-analytics-placement-id={analyticsItemId}
      data-analytics-has-landmark="true"
      data-qa="filmstrip-card"
      className={cx(styles.slide, {
        [styles.withVideoCard]: isVideoCard,
      })}
    >
      <ContentComponent
        analyticsItemId={analyticsItemId}
        textLocation={textLocation}
        {...slideProps}
      />
    </figure>
  );
};

FilmstripSlide.propTypes = gallerySlideShape;
