import React from 'react';
import { CARD_TYPES } from '@nike/ciclp-redux-app';
import styles from './productContainer.styl';

import { productContainerShape } from '../../shapes';

export const ProductCarouselIcon = ({ containerType }) => (
  <>
    {containerType === CARD_TYPES.SNKRS_DROPS && (
      <span className={styles.snkrsIcon} />
    )}
  </>
);

ProductCarouselIcon.propTypes = {
  containerType: productContainerShape.containerType,
};
