import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { selectors, actions } from '@nike/ciclp-redux-app';

import {
  getExperimentParameters,
  getActiveVariationFromOptimizely,
  canTrackOptimizelyEvent,
} from './helpers';
import { useOptimizelyInstance, useExperimentQueue } from './hooks';

const initialValue = {};

export const OptimizelyContext = React.createContext(initialValue);

export const OptimizelyProvider = ({
  children,
  datafile,
  forcedVariations,
}) => {
  const dispatch = useDispatch();
  const { isOptimizelyReady, optimizelyInstanceRef } =
    useOptimizelyInstance(datafile);
  const activeExperiments = useSelector(
    selectors.optimizelyActiveExperimentsSelector,
  );

  const isPrivacySet = useSelector(selectors.isPrivacySet);
  const isAbleToPersonalize = useSelector(selectors.canRunOptimizationSelector);
  const userId = useSelector(selectors.analyticsUserIdSelector);
  const isReadyForExperimentation = isPrivacySet && isOptimizelyReady;

  const {
    addExperimentToQueue,
    removeExperimentFromQueue,
    getExperimentsToActivate,
  } = useExperimentQueue();

  const processExperiment = ({
    experimentName,
    attributes,
    fallbackVariation,
  }) => {
    removeExperimentFromQueue(experimentName);
    if (activeExperiments[experimentName]) {
      return;
    }

    const activeVariation = getActiveVariationFromOptimizely({
      attributes,
      experimentName,
      forcedVariations,
      isAbleToPersonalize,
      optimizely: optimizelyInstanceRef.current,
      userId,
    });

    dispatch(
      actions.optimizelyActions.activateExperiment(
        getExperimentParameters(
          optimizelyInstanceRef.current.getOptimizelyConfig(),
          experimentName,
          activeVariation,
          fallbackVariation,
        ),
      ),
    );
  };

  const activateExperiment = experiment => {
    if (isReadyForExperimentation) {
      processExperiment(experiment);
      return;
    }
    addExperimentToQueue(experiment);
  };

  useEffect(() => {
    const experimentsToActivate = getExperimentsToActivate();
    if (experimentsToActivate.length && isReadyForExperimentation) {
      experimentsToActivate.forEach(processExperiment);
    }
    // we need to re-run this hook only if canProcessExperiments changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReadyForExperimentation]);

  const trackEvent = (experimentName, attributes) => {
    if (
      canTrackOptimizelyEvent({
        experiment: activeExperiments[experimentName],
        isReadyForExperimentation,
      })
    ) {
      optimizelyInstanceRef.current.track(
        activeExperiments[experimentName].eventKey,
        userId,
        attributes,
      );
    }
  };

  const contextValue = {
    activateExperiment,
    activeExperiments,
    trackEvent,
  };

  return (
    <OptimizelyContext.Provider value={contextValue}>
      {children}
    </OptimizelyContext.Provider>
  );
};

OptimizelyProvider.propTypes = {
  children: PropTypes.node,
  datafile: PropTypes.object,
  forcedVariations: PropTypes.object,
};

export const OptimizelyResolver = ({ children, datafile, forcedVariations }) =>
  datafile ? (
    <OptimizelyProvider datafile={datafile} forcedVariations={forcedVariations}>
      {children}
    </OptimizelyProvider>
  ) : (
    children
  );

OptimizelyResolver.propTypes = {
  children: PropTypes.node,
  datafile: PropTypes.object,
  forcedVariations: PropTypes.object,
};
