import styled from '@emotion/styled';
import { TextStyled } from '@nike/nike-design-system-components';

export const PriceWrapper = styled.span`
  text-align: right;
  display: inline-block;

  ${TextStyled} {
    white-space: nowrap;
  }

  ${TextStyled} + ${TextStyled} {
    padding-left: 4px;
  }
`;
