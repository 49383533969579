import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import styles from './cardLink.styl';
import NavigationLink from '../navigationLink';
import withMemberOnly, {
  withMemberOnlyShape,
} from '../ctaButton/withMemberOnly';

export const CardLink = ({
  actionId,
  destinationUrl,
  openInNewTab,
  dataQa,
  renderAs,
  title,
  ariaLabel,
  draggable,
  getUrl,
  getActionText,
  handleClick,
  allowAnalytics,
  memberOnly,
  classNames,
  tabbable,
}) => {
  const Render = renderAs;
  const isLink = renderAs === 'a' || renderAs === NavigationLink;

  const href = getUrl?.(destinationUrl) || destinationUrl;
  const label = ariaLabel || '';
  const wrappedAriaLabel = getActionText(label, true);

  const linkProps = {
    href,
    rel: openInNewTab ? 'noopener' : null,
    target: openInNewTab ? '_blank' : '_self',
  };

  return (
    <Render
      data-qa="card-link"
      data-analytics-action-id={actionId}
      {...(memberOnly && {
        'data-analytics-click-activity-text': wrappedAriaLabel,
      })}
      data-analytics-allow={allowAnalytics}
      title={title}
      draggable={draggable}
      className={cx(styles.cardLink, ...classNames)}
      onClick={handleClick}
      {...(dataQa && { 'data-qa-card-id': dataQa })}
      aria-label={wrappedAriaLabel || undefined}
      tabIndex={tabbable ? undefined : '-1'}
      {...(isLink && linkProps)}
    />
  );
};

export const cardLinkShape = {
  actionId: PropTypes.string,
  ariaLabel: PropTypes.string,
  classNames: PropTypes.arrayOf(PropTypes.string),
  dataQa: PropTypes.string,
  destinationType: PropTypes.string,
  destinationUrl: PropTypes.string,
  draggable: PropTypes.bool,
  openInNewTab: PropTypes.bool,
  renderAs: PropTypes.oneOf(['button', 'a', NavigationLink]), // eslint-disable-line react/no-unused-prop-types
  tabbable: PropTypes.bool,
  title: PropTypes.string,
  ...withMemberOnlyShape,
};

CardLink.propTypes = cardLinkShape;

CardLink.defaultProps = {
  actions: [],
  classNames: [],
  getActionText: text => text,
  getUrl: url => (!url ? '#' : url),
  renderAs: NavigationLink,
  tabbable: true,
};

export default withMemberOnly(CardLink);
