import { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { selectors } from '@nike/ciclp-redux-app';

export const useIdentity = (callback, params = []) => {
  const identityState = useSelector(
    selectors.identityStateSelector,
    shallowEqual,
  );
  const [data, setData] = useState(null);

  useEffect(() => {
    const getIdentityData = async () => {
      if (identityState.initialized) {
        if (callback) {
          const identityData = await callback(...params);
          setData(identityData);
        }
      }
    };

    getIdentityData();
  }, [identityState, callback, params]);

  return data;
};
