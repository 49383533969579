import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { LocalNavMenu } from './LocalNavMenu';

const baseStyles = ({ theme }) => {
  const {
    colorBackgroundAlwaysLight = '#fff',
    sizeSpacingGridGutterL = '12px',
  } = theme;
  return css`
    background: ${colorBackgroundAlwaysLight};
    padding: 18px 0;

    .local-nav-wrapper {
      display: flex;
      flex-wrap: wrap;
    }

    a {
      text-decoration: none;
    }

    .local-nav-title {
      &:first-child {
        margin-right: auto;
      }
      &:last-child {
        margin-left: auto;
      }
      flex: 1;
      display: flex;
    }

    .local-nav-title_hidden {
      opacity: 0;
      height: 0;
      visibility: hidden;
      line-height: 0;
      padding: 0;
    }

    .local-nav-menu-item:not(:last-of-type) {
      padding-right: calc(2 * ${sizeSpacingGridGutterL});
    }

    .local-nav-list {
      padding: 0;
      flex-flow: wrap;
      display: flex;
    }
  `;
};

const LocalNavMenuStyledComp = props => {
  return <LocalNavMenu {...props} />;
};

LocalNavMenuStyledComp.defaultProps = {
  ...LocalNavMenu.defaultProps,
};

// eslint-disable-next-line no-template-curly-in-string
export const LocalNavMenuStyled = styled(LocalNavMenuStyledComp)`
  ${baseStyles}
`;
