import NikeOneVideoPlayer from '@nike/nike-one-video-player';
import { VIDEO_EVENTS } from '../../constants';
import constants from '../../../../constants';

const PREVIEW_STOPPED_CLASS = 'vjs-preview-stopped';
const PREVIEW_WRAPPER_SELECTOR = '[data-preview-wrapper]';

const BasePlugin = NikeOneVideoPlayer.getPlugin('plugin');

const getPreviewWrapperElement = videoElement => {
  return (
    videoElement
      ?.closest(constants.ANALYTICS_PLACEMENT_SELECTOR)
      ?.querySelector(PREVIEW_WRAPPER_SELECTOR) ||
    videoElement?.closest(PREVIEW_WRAPPER_SELECTOR) ||
    videoElement
  );
};

export class Preview extends BasePlugin {
  timerId = 0;

  isPreviewStarted = false;

  isPreviewDisabled = false;

  constructor(player, options) {
    super(player, options);
    this.options = options;
    if (this.options.enable && !this.player.options().autoplay) {
      this.player.on(VIDEO_EVENTS.PLAY, () => {
        this.resetTimer();
        this.player.removeClass(PREVIEW_STOPPED_CLASS);
      });
      this.player.on(VIDEO_EVENTS.DISPOSE, this.disable);
      this.enable();
    }
  }

  // separate getter is needed because there is a chance that wrapper reference will be updated
  getPreviewWrapper = () => getPreviewWrapperElement(this.player.el());

  enable = () => {
    if (!this.player.autoplay()) {
      this.isPreviewDisabled = false;
      this.addListeners();
    }
  };

  disable = () => {
    this.isPreviewDisabled = true;
    this.removeListeners();
  };

  canStartPreview = target => {
    return (
      !target.closest('button, a') &&
      this.player.paused() &&
      !this.player.isFullscreen() &&
      !this.isPreviewDisabled
    );
  };

  handleTouchStart = e => {
    this.start(e);
  };

  handleTouchEnd = () => {
    this.stop();
  };

  handleMouseEnter = e => {
    this.start(e);
    const previewWrapper = this.getPreviewWrapper();

    previewWrapper.addEventListener('mousemove', this.start);
  };

  handleMouseLeave = () => {
    const previewWrapper = this.getPreviewWrapper();

    previewWrapper.removeEventListener('mousemove', this.start);

    this.stop();
  };

  addListeners() {
    const previewWrapper = this.getPreviewWrapper();
    if (!previewWrapper) {
      return;
    }
    previewWrapper.addEventListener('touchstart', this.handleTouchStart);
    previewWrapper.addEventListener('touchend', this.handleTouchEnd);
    previewWrapper.addEventListener('mouseenter', this.handleMouseEnter);
    previewWrapper.addEventListener('mouseleave', this.handleMouseLeave);
  }

  removeListeners() {
    const previewWrapper = this.getPreviewWrapper();
    if (!previewWrapper) {
      return;
    }
    previewWrapper.removeEventListener('touchstart', this.handleTouchStart);
    previewWrapper.removeEventListener('touchend', this.handleTouchEnd);
    previewWrapper.removeEventListener('mouseenter', this.handleMouseEnter);
    previewWrapper.removeEventListener('mouseleave', this.handleMouseLeave);
  }

  setTimerId(id) {
    this.resetTimer();
    this.timerId = id;
  }

  resetTimer() {
    clearTimeout(this.timerId);
  }

  start = ({ target }) => {
    if (!this.canStartPreview(target)) {
      this.stop();
      return;
    }
    const timer = setTimeout(() => {
      // add disable user-select class
      const previewWrapper = this.getPreviewWrapper();
      this.player.setState({ previewStarted: true });
      previewWrapper.removeEventListener('mousemove', this.start);
      document.body.classList.add(this.options.disableClass);
      this.isPreviewStarted = true;
      this.triggerEvent(true);
      this.player.muted(true);
      this.player.controls(false);
      this.player.currentTime(0);
      this.player.play();
    }, 500);
    this.setTimerId(timer);
  };

  triggerEvent(isStarted) {
    const event = isStarted
      ? VIDEO_EVENTS.PREVIEW_STARTED
      : VIDEO_EVENTS.PREVIEW_STOPPED;

    this.player.trigger(event);
  }

  stop() {
    this.resetTimer();
    if (!this.isPreviewStarted) return;

    this.isPreviewStarted = false;
    this.player.pause();
    this.player.currentTime(0);
    this.player.addClass(PREVIEW_STOPPED_CLASS);
    // remove disable user-select class
    document.body.classList.remove(this.options.disableClass);

    setTimeout(() => {
      this.player.setState({ previewStarted: false });
      this.triggerEvent(false);
    });
  }
}
