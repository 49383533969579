import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import { TextStyled } from '@nike/nike-design-system-components';
import styles from './titleCard.styl';

export const TitleMeta = ({
  authors,
  lastUpdated,
  lastUpdatedTemplate,
  lastUpdatedStamp,
  readTime,
  readTimeTemplate,
}) => {
  const shouldShowFirstMetaLine = !!lastUpdated || !!readTime;
  const shouldShowSecondMetaLine = !!(authors && authors.roleTitle);
  const lastUpdatedFormatted =
    !!lastUpdated && lastUpdatedTemplate?.replace('[date]', lastUpdated);
  const readTimeFormatted =
    !!readTime && readTimeTemplate?.replace('[durationInMinutes]', readTime);

  return (
    <>
      {shouldShowFirstMetaLine && (
        <div className={styles.metadataWrapper}>
          <TextStyled
            Component="div"
            appearance="body1"
            color="secondary"
            className={cx('mt6-sm', styles.metadataLine)}
          >
            {!!lastUpdatedFormatted && (
              <div className={styles.metadata} data-qa="lastUpdated">
                {lastUpdatedFormatted}
                <meta itemProp="dateModified" content={lastUpdatedStamp} />
              </div>
            )}
            {readTimeFormatted && (
              <div className={styles.metadata} data-qa="readTime">
                {readTimeFormatted}
              </div>
            )}
          </TextStyled>
        </div>
      )}
      {shouldShowSecondMetaLine && (
        <TextStyled
          data-qa="authors"
          Component="div"
          appearance="body1"
          className={cx('mt6-sm', styles.byline)}
        >
          {`${authors.roleTitle}: ${authors.profiles
            .map(profile => profile.fullName)
            .join(', ')}`}
        </TextStyled>
      )}
    </>
  );
};

TitleMeta.propTypes = {
  authors: PropTypes.shape({
    profiles: PropTypes.arrayOf(
      PropTypes.shape({
        fullName: PropTypes.string,
      }),
    ),
    roleTitle: PropTypes.string,
  }),
  lastUpdated: PropTypes.string,
  lastUpdatedStamp: PropTypes.string,
  lastUpdatedTemplate: PropTypes.string,
  readTime: PropTypes.number,
  readTimeTemplate: PropTypes.string,
};
