import React from 'react';
import PropTypes from 'prop-types';
import styles from './relatedFilmstrip.styl';
import { RelatedFilmstripSlide } from './relatedFilmstripSlide';
import constants from '../../constants';
import { sectionHeadlineShape, gallerySlideShape } from '../../shapes';
import { useDimensionTypeContext } from '../../context';
import { RelatedCarousel, RelatedList, RelatedTwoInRow } from './templates';

const getRelatedTemplate = (isMobile, shouldCenterFilmstrip) => {
  if (isMobile) {
    return RelatedList;
  }
  if (shouldCenterFilmstrip) {
    return RelatedTwoInRow;
  }
  return RelatedCarousel;
};

const renderSlides = (filmstripItems, analyticsItemId) => {
  return filmstripItems.map((slide, index) => {
    const analyticsSlideId = `${analyticsItemId}_${index + 1}`;
    return (
      <RelatedFilmstripSlide
        key={analyticsSlideId}
        analyticsItemId={analyticsSlideId}
        {...slide}
      />
    );
  });
};

export const RelatedFilmstrip = ({
  analyticsItemId,
  sectionHeadline,
  slides,
}) => {
  const { isMobile } = useDimensionTypeContext();
  const filmstripItems = slides.slice(0, constants.MAX_PRODUCT_LIST_LENGTH);
  const shouldCenterFilmstrip =
    filmstripItems.length < constants.MIN_PRODUCT_LIST_LENGTH;
  const filmstripSlides = renderSlides(filmstripItems, analyticsItemId);
  const Template = getRelatedTemplate(isMobile, shouldCenterFilmstrip);
  const headlineAnalyticsId = `${analyticsItemId}_0`;

  return (
    <div data-qa="related-filmstrip" className={styles.filmstrip}>
      <Template
        analyticsItemId={analyticsItemId}
        headlineAnalyticsId={headlineAnalyticsId}
        sectionHeadline={sectionHeadline}
        filmstripSlides={filmstripSlides}
      />
    </div>
  );
};

RelatedFilmstrip.propTypes = {
  analyticsItemId: PropTypes.string,
  sectionHeadline: sectionHeadlineShape,
  slides: PropTypes.arrayOf(PropTypes.shape(gallerySlideShape)),
};
