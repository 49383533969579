import React from 'react';
import styles from './productSlide.styl';
import { productSlideShape } from '../../shapes';
import EnhancedProductCard from '../enhancedProductCard';
import { MultipleBadges } from './multipleBadges';

export const ProductSlide = ({
  analyticsItemId,
  isNikeByYou,
  isMemberExclusive,
  labels,
  ...rest
}) => {
  const shouldShowOverlay = isNikeByYou || !isMemberExclusive;
  const overlayText = isNikeByYou ? labels.nikeByYouText : '';

  let textBadge;
  let TextBadgeComponent;
  if (isNikeByYou && isMemberExclusive) {
    TextBadgeComponent = MultipleBadges;
  } else if (isNikeByYou) {
    textBadge = labels.nikeByYouLabel;
  } else if (isMemberExclusive) {
    textBadge = labels.memberAccessLabel;
  }

  return (
    <div
      data-qa="product-slide"
      data-analytics-placement-id={analyticsItemId}
      data-analytics-has-landmark="true"
      className={styles.productSlide}
    >
      <EnhancedProductCard
        isNikeByYou={isNikeByYou}
        isMemberExclusive={isMemberExclusive}
        labels={labels}
        overlayText={shouldShowOverlay ? overlayText : undefined}
        textBadge={textBadge}
        TextBadgeComponent={TextBadgeComponent}
        {...rest}
      />
    </div>
  );
};

ProductSlide.defaultProps = {
  labels: {},
};

ProductSlide.propTypes = {
  ...productSlideShape,
};

export default ProductSlide;
