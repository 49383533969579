import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, shallowEqual } from 'react-redux';
import cx from 'clsx';
import { selectors, CARD_TYPES } from '@nike/ciclp-redux-app';
import ImageCard from '../imageCard';
import { VideoCard } from '../videoCard';
import { SlideshowContainer } from '../../containers/slideshow';
import { Filmstrip } from '../filmstrip';
import { RelatedFilmstrip } from '../relatedFilmstrip';
import { DynamicCarousel } from '../dynamicCarousel';
import { ProductContainer } from '../productContainer';
import MerchMenu from '../merchMenu';
import { LocalNavWrapper } from '../../containers/localNav';
import TextCard from '../textCard';
import SectionHeadline from '../sectionHeadline';
import { TeamSelectorContainer } from '../../containers/teamSelector';
import { RecommenderFallback } from '../../containers/recommenderFallback';
import { FAQContainer } from '../../containers/faq';
import { SizeChartsWrapper } from '../../containers/sizeChartsWrapper';
import { TextCardContainer } from '../../containers/textCardContainer';
import { InterestsContainer } from '../../containers/interestsContainer';
import { componentTypesShape, imageSizesShape } from '../../shapes';
import { ContentGrid } from '../contentGrid';
import { TitleCard } from '../titleCard';
import imageHeights from '../../constants/imageHeights.styl';
import MetaBoxCard from '../metaBox';
import ToolsAndSupplies from '../toolsAndSupplies';
import { Listicle } from '../listicle';
import { SnkrsTabsContainer } from '../snkrsTabsContainer';
import { BraSizeFinderContainer } from '../../containers/braSizeFinder';
import { ErrorBoundary } from '../errorBoundary';

const subTypeToCompMap = {
  [CARD_TYPES.IMAGE]: ImageCard,
  [CARD_TYPES.VIDEO]: VideoCard,
  [CARD_TYPES.TEXT]: TextCardContainer,
  [CARD_TYPES.SECTION_HEADLINE]: SectionHeadline,
  [CARD_TYPES.TITLE]: TitleCard,
  [CARD_TYPES.EXTERNAL_COLLECTION]: ProductContainer,
  [CARD_TYPES.PRODUCT_RECOMMENDER]: RecommenderFallback,
  [CARD_TYPES.PRODUCT_RECOMMENDER_TAXONOMY]: RecommenderFallback,
  [CARD_TYPES.SNKRS_DROPS]: SnkrsTabsContainer,
  [CARD_TYPES.MERCH_MENU]: MerchMenu,
  [CARD_TYPES.LOCAL_NAV]: LocalNavWrapper,
  [CARD_TYPES.SLIDESHOW]: SlideshowContainer,
  [CARD_TYPES.RELATED_FILMSTRIP]: RelatedFilmstrip,
  [CARD_TYPES.DYNAMIC_RELATED_FILMSTRIP]: RelatedFilmstrip,
  [CARD_TYPES.FILMSTRIP]: Filmstrip,
  [CARD_TYPES.DYNAMIC_CAROUSEL]: DynamicCarousel,
  [CARD_TYPES.DYNAMIC_GRID]: ContentGrid,
  [CARD_TYPES.TEAM_SELECTOR]: TeamSelectorContainer,
  [CARD_TYPES.PRODUCT_WALL]: ProductContainer,
  [CARD_TYPES.SIZE_CHARTS]: SizeChartsWrapper,
  [CARD_TYPES.ARTICLE_FOOTER]: TextCard,
  [CARD_TYPES.FAQ]: FAQContainer,
  [CARD_TYPES.LISTICLE]: Listicle,
  [CARD_TYPES.META_BOX]: MetaBoxCard,
  [CARD_TYPES.TOOLS_AND_SUPPLIES]: ToolsAndSupplies,
  [CARD_TYPES.BRA_SIZE_FINDER]: BraSizeFinderContainer,
  [CARD_TYPES.INTERESTS]: InterestsContainer,
};

export const Card = props => {
  const { analyticsItemId, cardData, withH1 } = props;
  const { cardId } = props;
  const storeCardData = useSelector(
    state => selectors.getCardDataSelector(state, cardId),
    shallowEqual,
  );
  const cardProps = cardData || storeCardData;

  const Component = subTypeToCompMap[cardProps.containerType];
  if (!Component) {
    return <div data-qa="card" />;
  }
  return (
    <ErrorBoundary>
      <Component
        className="card"
        {...cardProps}
        analyticsItemId={analyticsItemId}
        withH1={withH1}
        cardId={cardId}
        customClass={cx(
          cardProps.imageHeight && imageHeights[cardProps.imageHeight],
        )}
      />
    </ErrorBoundary>
  );
};

Card.propTypes = {
  analyticsItemId: PropTypes.string.isRequired,
  cardData: PropTypes.shape({
    containerType: componentTypesShape.isRequired,
    imageHeight: imageSizesShape,
  }),
  cardId: PropTypes.string.isRequired,
  withH1: PropTypes.bool,
};
