import { css } from '@emotion/core';

export const baseStyles = () => {
  return css`
    .sticky-container-animation {
      position: fixed;
      z-index: 5;
      top: -65px;
      width: 100%;
      transition: top 300ms ease-in-out;
    }

    .sticky-container-hidden {
      overflow: hidden;
    }

    .is-sticky {
      position: fixed;
      z-index: 5;
      width: 100%;
      top: 0;
    }
  `;
};
