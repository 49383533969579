import React from 'react';
import PropTypes from 'prop-types';
import { range } from 'ramda';
import cx from 'clsx';
import { selectors } from '@nike/ciclp-redux-app';
import { useSelector } from 'react-redux';
import { paginationShape } from '../../shapes';
import styles from './pagination.styl';

const getUrlForPage = (pageNumber, currentUrl = '', anchor = '') => {
  const urlWithoutPageParam = currentUrl.replace(/[?&]p=(\d+)/, e =>
    e[0] === '?' ? '?' : '',
  );
  const anchorFragment = anchor ? `#${anchor}` : '';
  if (pageNumber === 1) return `${urlWithoutPageParam}${anchorFragment}`;

  return [
    urlWithoutPageParam,
    urlWithoutPageParam.match(/[\\?]/g)
      ? `&p=${pageNumber}`
      : `?p=${pageNumber}`,
    anchorFragment,
  ].join('');
};

const getPaginationItems = (pageNumber, totalPages) => {
  // Calculations to get 5 pagination items with middle current page, taking into account first and last pages
  const numberOfVisibleItems = 5;
  const currentPageOffset = 2;
  if (totalPages <= numberOfVisibleItems) return range(1, totalPages + 1);

  const increasers = [
    totalPages - pageNumber >= currentPageOffset
      ? currentPageOffset
      : 4 - totalPages + pageNumber,
    pageNumber <= currentPageOffset
      ? numberOfVisibleItems - pageNumber
      : currentPageOffset,
  ];
  const rangeArrStart =
    pageNumber <= currentPageOffset ? 1 : pageNumber - increasers[0];
  const rangeArrEnd =
    totalPages - pageNumber >= currentPageOffset
      ? pageNumber + increasers[1]
      : totalPages;
  return range(rangeArrStart, rangeArrEnd + 1);
};

export const Pagination = ({
  totalPages,
  pageNumber,
  currentUrl,
  anchor,
  translations,
}) => {
  const pages = getPaginationItems(pageNumber, totalPages);
  const {
    paginationNext,
    paginationPrevious,
    paginationCurrent,
    paginationTitle,
    paginationItem,
  } = translations;

  return (
    <nav
      className={styles.paginationContainer}
      data-qa="pagination"
      role="navigation"
      aria-label={paginationTitle}
    >
      {pageNumber !== 1 && (
        <a
          data-qa-prev
          data-analytics-action-id={`pagination_${pageNumber - 1}`}
          href={getUrlForPage(pageNumber - 1, currentUrl, anchor)}
          aria-label={paginationPrevious}
          className={cx([styles.paginationItem, styles.paginationItemPrev])}
        />
      )}
      {pages.map(page => {
        const paginationItemLabel = paginationItem?.replace(
          '[pageNumber]',
          page,
        );
        if (pageNumber === page) {
          return (
            <span
              data-qa-current-page={page}
              key={page}
              className={cx(styles.paginationItem, styles.paginationItemActive)}
              aria-current="true"
              aria-label={`${paginationCurrent}, ${paginationItemLabel}`}
            >
              {page}
            </span>
          );
        }

        const isLastPage = pageNumber === totalPages && page === totalPages - 1;
        return (
          <a
            href={getUrlForPage(page, currentUrl, anchor)}
            key={page}
            data-analytics-action-id={`pagination_${page}`}
            className={cx(styles.paginationItem, {
              [styles.visibleOnMobile]: page === pageNumber + 1 || isLastPage,
            })}
            aria-label={paginationItemLabel}
          >
            {page}
          </a>
        );
      })}
      {pageNumber !== totalPages && (
        <a
          data-qa-next
          aria-label={paginationNext}
          data-analytics-action-id={`pagination_${pageNumber + 1}`}
          href={getUrlForPage(pageNumber + 1, currentUrl, anchor)}
          className={cx([styles.paginationItem, styles.paginationItemNext])}
        />
      )}
    </nav>
  );
};

Pagination.propTypes = {
  ...paginationShape,
  currentUrl: PropTypes.string,
};

Pagination.defaultProps = {
  translations: {
    paginationCurrent: 'Current Page',
    paginationItem: 'Page [pageNumber]',
    paginationNext: 'Next Page',
    paginationPrevious: 'Previous Page',
    paginationTitle: 'Pagination Navigation',
  },
};

const PaginationWrapper = props => {
  const url = useSelector(selectors.urlSelector);
  return <Pagination {...props} currentUrl={url} />;
};

export default PaginationWrapper;

PaginationWrapper.propTypes = paginationShape;
