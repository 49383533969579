import React from 'react';
import PropTypes from 'prop-types';
import { routeNames, selectors } from '@nike/ciclp-redux-app';
import { connect } from 'react-redux';
import Thread from './thread';
import NotFoundView from './404';
import ServerErrorView from './503';
import AuthExpiredView from './401';

const route2ComponentMap = {
  [routeNames.PREVIEW]: <Thread />,
  [routeNames.PREVIEW_V2]: <Thread />,
  [routeNames.PAGE_VIEW]: <Thread />,
  [routeNames.NOT_FOUND]: <NotFoundView />,
  [routeNames.SERVER_ERROR]: <ServerErrorView />,
  [routeNames.AUTH_EXPIRED]: <AuthExpiredView />,
  default: <NotFoundView />,
};

const getRoutedComponent = routeName =>
  Object.prototype.hasOwnProperty.call(route2ComponentMap, routeName)
    ? route2ComponentMap[routeName]
    : route2ComponentMap.default;

const Routes = ({ routeName }) => getRoutedComponent(routeName);

Routes.propTypes = {
  routeName: PropTypes.string,
};

Routes.defaultProps = {
  routeName: 'default',
};

const mapStateToProps = state => ({
  routeName: selectors.routeNameSelector(state),
});

export default connect(mapStateToProps)(Routes);
