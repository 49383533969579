import React from 'react';
import { BRAND_NAMES } from '@nike/ciclp-config';
import { CARD_TYPES } from '@nike/ciclp-redux-app';
import { smartTextCut } from '@nike/ciclp-redux-app/services/transforms/helpers';
import { TextStyled } from '@nike/nike-design-system-components';
import ImageMedia from '../imageMedia';
import { CardLink } from '../cardLink';
import styles from './contentSlide.styl';
import { gallerySlideShape } from '../../shapes';
import { BrandContext } from '../../context';
import { VideoMedia } from '../videoMedia';
import { createMarkup } from '../../helpers/text';

const MAX_BODY_LENGTH = 200;
const MAX_HEADLINE_LENGTH = 160;

const getComponent = slide =>
  ({
    [CARD_TYPES.IMAGE]: (
      <ImageMedia
        {...slide}
        loadedImageClassNames={[styles.imageIsLoaded]}
        imageHeight="medium"
        // design requirement to have 3:2 aspect ratio
        assetsAspectRatios={{ landscape: 1.5, portrait: 1.5 }}
        customClass={[styles.imageMedia]}
      />
    ),
    [CARD_TYPES.VIDEO]: (
      <VideoMedia
        {...slide}
        loop={false}
        autoPlay={false}
        showPlayButton
        alwaysMobile
        watchButtonText={slide.watchCtaButtonText}
        customClass={styles.imageMedia}
        timestampWithPlay
      />
    ),
  }[slide.containerType] ?? null);

const ContentSlide = ({ analyticsItemId, cardLinkMemberOnly, ...slide }) => (
  <figure
    data-analytics-placement-id={analyticsItemId}
    data-analytics-has-landmark="true"
    data-qa="content-slide"
    className={styles.slideContainer}
  >
    {getComponent(slide)}
    <CardLink
      actionId={slide.cardLinkId}
      memberOnly={cardLinkMemberOnly}
      destinationUrl={slide.cardLinkUrl}
      openInNewTab={slide.openInNewTab}
      renderAs="a"
      ariaLabel={[slide.subtitleProps.text, slide.titleProps.text].join(' ')}
      classNames={[styles.videoCardLink]}
    />
    <figcaption className={styles.slideTextWrapper}>
      {slide.subtitleProps.text && (
        <TextStyled
          data-qa="subtitle"
          appearance="body2"
          className={styles.slideSubTitle}
          color={
            slide.subtitleProps.isTextColorSet
              ? slide.subtitleProps.textColor
              : 'secondary'
          }
          dangerouslySetInnerHTML={createMarkup(slide.subtitleProps.text)}
        />
      )}
      <BrandContext.Consumer>
        {brand => (
          <TextStyled
            Component="h4"
            appearance="title4"
            data-qa="title"
            role="heading"
            weight={brand === BRAND_NAMES.NIKE_JOURNAL ? 'medium' : 'regular'}
            color={slide.titleProps.textColor}
            dangerouslySetInnerHTML={createMarkup(
              smartTextCut(slide.titleProps.text, MAX_HEADLINE_LENGTH),
            )}
          />
        )}
      </BrandContext.Consumer>
      {slide?.bodyProps?.text && (
        <TextStyled
          appearance="body2"
          className="mt2-sm"
          color={slide.bodyProps.textColor}
          dangerouslySetInnerHTML={createMarkup(
            smartTextCut(slide.bodyProps.text, MAX_BODY_LENGTH),
          )}
        />
      )}
    </figcaption>
  </figure>
);

ContentSlide.propTypes = gallerySlideShape;

export default ContentSlide;
