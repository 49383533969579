import React from 'react';
import PropTypes from 'prop-types';
import cx from 'clsx';
import styles from './productSnkrsSlide.styl';
import { enhancedProductCardShape } from '../../shapes';
import EnhancedProductCard from '../enhancedProductCard';

export const ProductSnkrsSlide = ({
  analyticsItemId,
  statusText,
  isSoldOut,
  ...rest
}) => (
  <div
    data-qa="product-snkrs-slide"
    data-analytics-placement-id={analyticsItemId}
    data-analytics-has-landmark="true"
  >
    <EnhancedProductCard
      detailsClass={cx(isSoldOut && styles.soldOutContainer)}
      textBadge={statusText}
      {...rest}
    />
  </div>
);

ProductSnkrsSlide.propTypes = {
  ...enhancedProductCardShape,
  isSoldOut: PropTypes.bool,
  statusText: PropTypes.string,
};

export default ProductSnkrsSlide;
