import { BRAND_NAMES } from '@nike/ciclp-config';

import styles from './textOverlay.styl';

const setAllSizes = appearance => ({
  extra_large: appearance,
  extra_small: appearance,
  large: appearance,
  medium: appearance,
  small: appearance,
});

const setAllFontFamilies = appearance => ({
  base: appearance,
  brand: appearance,
  marketing: appearance,
});

export const appearanceStyleMapping = {
  [BRAND_NAMES.NIKE]: {
    body: setAllFontFamilies({
      extra_large: 'editorialBody1',
      extra_small: 'body1',
      large: 'editorialBody1',
      medium: 'editorialBody1',
      small: { large: 'editorialBody1', small: 'body1' },
    }),
    subtitle: setAllFontFamilies(setAllSizes('body1Strong')),
    title: {
      base: {
        extra_large: 'title2',
        extra_small: 'body1Strong',
        large: 'title3',
        medium: 'title3',
        small: 'title4',
      },
      brand: setAllSizes('display2'),
      marketing: {
        extra_large: 'display2',
        extra_small: {
          large: 'display4',
          small: 'display2',
        },
        large: 'display2',
        medium: 'display2',
        small: {
          large: 'display4',
          small: 'display2',
        },
      },
    },
  },
  [BRAND_NAMES.NIKE_JOURNAL]: {
    body: setAllFontFamilies({
      extra_large: 'editorialBody1',
      extra_small: 'body1',
      large: 'editorialBody1',
      medium: 'editorialBody1',
      small: { large: 'editorialBody1', small: 'body1' },
    }),
    subtitle: setAllFontFamilies(setAllSizes('body1Strong')),
    title: {
      base: {
        extra_large: 'title2',
        extra_small: 'title4',
        large: 'title3',
        medium: 'title3',
        small: 'title4',
      },
      brand: {
        extra_large: 'display1',
        extra_small: 'display4',
        large: 'display2',
        medium: 'display2',
        small: 'display4',
      },
      marketing: {
        extra_large: 'display1',
        extra_small: 'display4',
        large: 'display2',
        medium: 'display2',
        small: 'display4',
      },
    },
  },
  [BRAND_NAMES.JORDAN]: {
    body: setAllFontFamilies({
      extra_large: 'editorialBody1',
      extra_small: 'body1Strong',
      large: 'editorialBody1',
      medium: 'editorialBody1',
      small: { large: 'editorialBody1', small: 'body1Strong' },
    }),
    subtitle: setAllFontFamilies(setAllSizes('body1Strong')),
    title: {
      base: {
        extra_large: 'title2',
        extra_small: 'body1Strong',
        large: 'title2',
        medium: 'title3',
        small: 'title4',
      },
      brand: {
        extra_large: 'display2',
        extra_small: 'display3',
        large: 'display2',
        medium: 'display2',
        small: 'display3',
      },
      marketing: setAllSizes({ large: 'display4', small: 'display2' }),
    },
  },
};

export const getTextAppearance = (
  textType,
  brand,
  fontFamily,
  fontSize,
  isLargeScreen,
) => {
  const selectedBrand =
    appearanceStyleMapping[brand] ??
    appearanceStyleMapping[brand][BRAND_NAMES.NIKE];

  const calculatedAppearance =
    selectedBrand[textType]?.[fontFamily]?.[fontSize] ?? '';

  if (typeof calculatedAppearance === 'object') {
    return isLargeScreen
      ? calculatedAppearance.large
      : calculatedAppearance.small;
  }

  return calculatedAppearance;
};

export const isEmptyTextString = text =>
  typeof text === 'string' && !text.trim();

export const fontStyleMapper = {
  medium: styles.fontStyle_medium,
  oblique: styles.fontStyle_oblique,
};

export const shouldUppercaseText = (appearance, brand) => {
  const isJordanPage = brand === BRAND_NAMES.JORDAN;
  const isLandingPage = brand === BRAND_NAMES.NIKE;

  const hasDisplayAppearance = appearance.startsWith('display');
  const hasTitleAppearance = appearance.startsWith('title');

  return (
    (isJordanPage && (hasDisplayAppearance || hasTitleAppearance)) ||
    (isLandingPage && hasDisplayAppearance)
  );
};
