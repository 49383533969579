import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Waypoint } from 'react-waypoint';

const DEFAULT_BOTTOM_OFFSET = '-200%';

const CONNECTION_TYPE_OFFSET_MAP = {
  '2g': DEFAULT_BOTTOM_OFFSET,
  '3g': '-150%',
  '4g': '-100%',
  'slow-2g': DEFAULT_BOTTOM_OFFSET,
};

export const getConnectionTypeOffset = () => {
  const { navigator } = window;
  const connection =
    navigator.connection ||
    navigator.mozConnection ||
    navigator.webkitConnection;
  const type = connection?.effectiveType;

  return CONNECTION_TYPE_OFFSET_MAP[type] ?? DEFAULT_BOTTOM_OFFSET;
};

export const AnimatedVisibilitySensor = ({
  onLoad,
  children,
  bottomOffset,
}) => {
  const [wasVisible, setWasVisible] = useState(false);
  const [effectiveBottomOffset, setEffectiveBottomOffset] =
    useState(bottomOffset);

  useEffect(() => {
    const calculatedBottomOffset = getConnectionTypeOffset();
    setEffectiveBottomOffset(calculatedBottomOffset);
  }, []);

  const onVisibilityChange = () => {
    if (!wasVisible) {
      setWasVisible(true);
    }
  };

  const onLoadHandler = () => {
    onLoad?.();
  };

  return (
    <Waypoint onEnter={onVisibilityChange} bottomOffset={effectiveBottomOffset}>
      {children({
        isVisible: wasVisible,
        onLoad: onLoadHandler,
      })}
    </Waypoint>
  );
};

AnimatedVisibilitySensor.propTypes = {
  bottomOffset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.func,
  onLoad: PropTypes.func,
};

AnimatedVisibilitySensor.defaultProps = {
  bottomOffset: '0px',
};

export default AnimatedVisibilitySensor;
